<template>
    <div>
        <kr-panel v-if="componentDoneLoading" :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <h2>Evaluation Analysis</h2>
            </template>
            <template #content>
                <div class="nav-tabsContainer">
                    <div class="links">
                        <ul>
                            <li :class="{ active: tab == 'studentScores' }" @click="tab = 'studentScores'">
                                <a class="nav-link uppercase" data-toggle="tab" href="#studentScores"> My Students’ Scores </a>
                            </li>

                            <li :class="{ active: tab == 'studentAnswers' }" @click="tab = 'studentAnswers'">
                                <a class="nav-link uppercase" data-toggle="tab" href="#studentAnswers"> My Students’ Answers </a>
                            </li>

                            <li
                                v-if="['ended', 'completed'].includes(testObj.status)"
                                :class="{ active: tab == 'aggregatedScores' }"
                                @click="tab = 'aggregatedScores'"
                            >
                                <a class="nav-link uppercase" data-toggle="tab" href="#aggregatedScores"> Aggregated Scores </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <template v-if="tab != 'aggregatedScores'">
                    <label for="chooseACriteria" class="control-label"> Choose a criteria </label>
                    <div class="flex">
                        <div class="marginRight20 marginBottom10">
                            <div class="dropdown new-custom-search-select">
                                <button
                                    id="chooseACriteria"
                                    class="btn btn-dropdown-select padding10"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <div>
                                        <span class="material-icons" aria-hidden="true"> filter_alt </span>
                                        <label> {{ toRoman(selectedCriteriaIdx) }}. {{ testObj.questions[selectedCriteriaIdx][0].question.name }} </label>
                                    </div>

                                    <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-new width100">
                                    <ul role="listbox" class="maxHeight255px overflowAuto">
                                        <li v-for="(group, groupIdx) in testObj.questions" :class="{ paddingTop10: groupIdx == 1 }" class="lh-0-8">
                                            <button
                                                id="criteriaListingDashboard"
                                                @click="
                                                    selectedCriteriaIdx = groupIdx;
                                                    fetchCriteria();
                                                "
                                            >
                                                <span class="material-icons marginRight8" aria-hidden="true">
                                                    <template v-if="group[0].type == 'ratingV2'"> star </template>
                                                    <template v-if="group[0].type == 'openendedV2'"> format_align_left </template>
                                                    <template v-if="group[0].type == 'pointDistribution'"> tune </template>
                                                </span>
                                                <label> {{ toRoman(groupIdx) }}. {{ group[0].question.name }} </label>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="marginBottom10">
                            <button class="btn btn-primary height100" @click.prevent="openQuestionInfoModal(selectedCriteria)">View Question</button>
                        </div>

                        <!--<div class="marginLeft20">
                            <button class="btn btn-primary height100" @click.prevent="fetchCriteria()">View Criteria</button>
                        </div>-->
                    </div>
                </template>
                <div class="two-column-grid-dashboard-evaluation marginTop20">
                    <div class="left-column textAlignCenter">
                        <h3>Teams</h3>
                        <button
                            v-for="(team, teamIdx) in testObj.teams.teamNames"
                            v-tooltip="team.name"
                            class="btn margin0"
                            :class="{ active: selectedTeamIdx == team.id, marginTop10: teamIdx >= 1 }"
                            @click="selectedTeamIdx = team.id"
                        >
                            <span
                                v-if="tab == 'aggregatedScores' && checkPAFSAPA(team)"
                                v-tooltip="'PAF not in the range of 0.75 - 1.5 or SAPA is not 1'"
                                class="marginRight8"
                                tabindex="0"
                                role="button"
                                aria-label="PAF not in the range of 0.75 - 1.5 or SAPA is not 1"
                            >
                                <i class="fa fa-flag important" aria-hidden="true" />
                            </span>

                            {{ team.name }}
                        </button>
                    </div>

                    <div class="right-column">
                        <div v-if="['studentScores', 'aggregatedScores'].includes(tab)" class="right-top-header">
                            <h3>Results</h3>
                        </div>

                        <template v-if="tab == 'studentScores'">
                            <studentScores :test-obj="testObj" :selected-criteria="selectedCriteria" />
                        </template>

                        <template v-if="tab == 'studentAnswers'">
                            <studentAnswers
                                :test-obj="testObj"
                                :selected-criteria="selectedCriteria"
                                :selected-team="testObj.teams.students[selectedTeamIdx]"
                            />
                        </template>

                        <template v-if="tab == 'aggregatedScores'">
                            <aggregatedScores v-if="['ended', 'completed'].includes(testObj.status)" :test-obj="testObj" :selected-team-id="selectedTeamIdx" />
                        </template>
                    </div>
                </div>
            </template>
        </kr-panel>

        <div
            class="modal default-modal evaluationV2AnalysisQuestionInfoModal"
            data-backdrop="false"
            tabindex="-1"
            role="dialog"
            aria-labelledby="evaluationV2AnalysisQuestionInfoModal-title"
        >
            <div
                class="modal-dialog"
                :class="{
                    'maxWidth100 widthAuto paddingLeft20 paddingRight20': ['ratingV2'].includes(selectedCriteria.question.type) && !isMobileView,
                }"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="evaluationV2AnalysisQuestionInfoModal-title" class="modal-title">View Question</h2>
                    </div>
                    <div class="modal-body">
                        <question-display-V2 :question="previewQuestion" />
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import QuestionDisplayV2 from './../../../../../../questions/partials/question-display-new.vue';
import studentScores from './partials/studentScores.vue';
import studentAnswers from './partials/studentAnswers.vue';
import aggregatedScores from './partials/aggregatedScores.vue';

const props = defineProps({
    testObj: {
        type: Object,
        default: () => ({}),
    },
});

const componentDoneLoading = ref(false);
let teamKeys = Object.keys(props.testObj.teams.teamNames);
let questionKeys = Object.keys(props.testObj.questions);
const selectedCriteriaIdx = ref(questionKeys.length > 0 ? questionKeys[0] : null);
const selectedTeamIdx = ref(teamKeys.length > 0 ? teamKeys[0] : null);
const selectedCriteria = ref({});
const toggleHide = (section) => {
    const contentRow = this.$refs[section + '-Content'];
    $(contentRow).toggleClass('hide');
};
const fetchCriteria = () => {
    axios
        .post(`tests/${props.testObj.uuid}/dashboard/peer-evaluation/item-analysis`, {
            activityQuestionId: props.testObj.questions[selectedCriteriaIdx.value][0].id,
            teamId: selectedTeamIdx.value,
        })
        .then((response) => {
            selectedCriteria.value = response.data.data;
            selectedCriteria.value.question = props.testObj.questions[selectedCriteriaIdx.value][0];
            selectedCriteria.value.teamId = selectedTeamIdx.value;
            if (componentDoneLoading.value == false) {
                componentDoneLoading.value = true;
            }
        })
        .catch((errors) => {});
};

const tab = ref('studentScores');

const previewQuestion = ref();
const openQuestionInfoModal = (question) => {
    previewQuestion.value = question.question;
    $('.evaluationV2AnalysisQuestionInfoModal').modal('show');
};

const scores = ref({});
const fetchScores = (team) => {
    axios
        .post(`tests/${props.testObj.uuid}/dashboard/peer-evaluation/aggregate-scores`, {
            teamId: parseInt(team),
        })
        .then((response) => {
            scores.value[parseInt(team)] = response.data.data;
        })
        .catch((errors) => {});
};

const checkPAFSAPA = (team) => {
    for (var i = 0; i < props.testObj.teams.students[team.id].length - 1; i++) {
        if (scores.value[parseInt(team.id)].aggregateScores.totalTeamPoints != 0) {
            const student = props.testObj.teams.students[team.id][i];
            if (
                scores.value[parseInt(team.id)].aggregateScores.totalPerReceiver[student.id].averagePaf > 1.5 ||
                scores.value[parseInt(team.id)].aggregateScores.totalPerReceiver[student.id].averagePaf < 0.75 ||
                scores.value[parseInt(team.id)].aggregateScores.totalPerReceiver[student.id].averageSapa != 1
            ) {
                return true;
            }
        }
    }
    return false;
};

onMounted(() => {
    fetchCriteria();
});

watch(
    () => selectedTeamIdx.value,
    (selectedTeamIdx, prevSelectedTeamIdx) => {
        fetchCriteria();
    }
);

watch(
    () => tab.value,
    (newTab, oldTab) => {
        if (newTab == 'aggregatedScores') {
            Object.keys(props.testObj.teams.teamNames).forEach((team) => {
                fetchScores(team);
            });
        }
    }
);
</script>
