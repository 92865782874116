<template>
    <div class="defaultTopBar" :class="{ isStudent: auth.user().role == 'Student' }">
        <div class="hidden-sm hidden-xs">
            <div class="top_nav_placeholder" @load="updateTopNavHeight"></div>
            <div ref="topNavHeight" class="top_nav desktop">
                <div class="nav_title_div_middle">
                    <nav v-if="breadcrumbList.length > 0" aria-label="Breadcrumb" class="breadcrumb" :class="{ calculateWithFixedTimer: isDashboard }">
                        <ol>
                            <li v-for="(breadcrumb, idx) in breadcrumbList" :key="'breadcrumb-' + idx">
                                <template v-if="breadcrumb.link">
                                    <router-link
                                        :key="idx"
                                        v-tooltip="breadcrumb.name.length > 50 ? breadcrumb.name : ''"
                                        :to="{
                                            name: breadcrumb.link,
                                            params: breadcrumb.params,
                                            query: breadcrumb.query,
                                        }"
                                        class="link"
                                        aria-current="true"
                                    >
                                        {{ truncate(breadcrumb.name) }}
                                    </router-link>
                                </template>
                                <template v-else>
                                    <button
                                        v-if="breadcrumb.name.length > 50"
                                        v-tooltip="breadcrumb.name.length > 50 ? breadcrumb.name : ''"
                                        class="fw-normal padding0"
                                    >
                                        <template v-if="breadcrumb.name == `Home`">
                                            <template v-if="auth.user().roleId > 1">
                                                {{ auth.user().account.organisationName }}
                                            </template>
                                            <template v-else> Cognalearn Pte Ltd </template>
                                        </template>
                                        <template v-else-if="breadcrumb.name == `Activities`">
                                            <h1>
                                                {{ truncate(breadcrumb.name) }}
                                            </h1>
                                        </template>
                                        <template v-else>
                                            {{ truncate(breadcrumb.name) }}
                                        </template>
                                    </button>
                                    <template v-else>
                                        <template v-if="breadcrumb.name == `Home`">
                                            <template v-if="auth.user().roleId > 1">
                                                {{ auth.user().account.organisationName }}
                                            </template>
                                            <template v-else> Cognalearn Pte Ltd </template>
                                        </template>
                                        <template v-else-if="breadcrumb.name == `Activities`">
                                            <h1>
                                                {{ truncate(breadcrumb.name) }}
                                            </h1>
                                        </template>
                                        <template v-else>
                                            {{ truncate(breadcrumb.name) }}
                                        </template>
                                    </template>
                                </template>
                            </li>
                        </ol>
                    </nav>
                    <div
                        v-show="miniTimerVisible"
                        id="d-topbar-fixed-timer"
                        ref="d-topbar-fixed-timer"
                        class="textAlignRight"
                        style="max-width: 440px; width: 100%"
                    />
                </div>

                <div class="nav_title_div_right" style="float: right">
                    <div class="profile_image">
                        <template v-if="auth.user().avatar">
                            <div class="actual-image img-circle" :style="'background-image:url(\'' + auth.user().avatar + '\');'" />
                        </template>
                        <template v-else>
                            <div class="badge_profile img-circle">
                                <span>{{ initials }}</span>
                            </div>
                        </template>
                    </div>
                    <div class="col-xs-12 flexSpaceBetween align-items">
                        <span style="padding-right: 10px">
                            <p>{{ getStudentName(auth.user()) }}</p>
                            <p class="fontBold">
                                {{ getStudentRole(auth.user()) }}
                                <template v-if="auth.user().role == 'Student' && isSafeExamBrowser"> (Using Safe Exam Browser) </template>
                            </p>
                        </span>
                        <div>
                            <button
                                id="dropdownMenuButton"
                                class="btn btn-empty"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                :aria-label="getStudentName(auth.user()) + ', User Settings'"
                            >
                                <i class="fa fa-cog" aria-hidden="true" />
                            </button>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                <ul>
                                    <li v-if="!auth.user().isGeneric">
                                        <router-link v-slot="{ navigate }" :to="{ name: 'profile.view' }" custom>
                                            <button class="btn btn-default" @click="navigate">
                                                <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />View My Profile
                                            </button>
                                        </router-link>
                                    </li>

                                    <li>
                                        <button class="btn btn-danger" @click.prevent="openLogoutConfirmationModal()">
                                            <i class="fas fa-sign-out-alt marginRight8" aria-hidden="true" />Sign Out
                                            <template v-if="auth.isImpersonation()"> (Impersonation) </template>
                                        </button>
                                    </li>

                                    <li class="btn-group fontSize-buttons width100">
                                        <button
                                            class="btn btn-default flexOnly align-items justifyCenter"
                                            :class="{
                                                active: active_el == 'small',
                                            }"
                                            aria-label="Smaller Font"
                                            @click="fontResize('small')"
                                        >
                                            <i class="fas fa-font fs-14px" aria-hidden="true" />
                                        </button>
                                        <button
                                            class="btn btn-default flexOnly align-items justifyCenter"
                                            :class="{
                                                active: active_el == 'normal',
                                            }"
                                            aria-label="Normal Font"
                                            @click="fontResize('normal')"
                                        >
                                            <i class="fas fa-font" aria-hidden="true" />
                                        </button>
                                        <button
                                            class="btn btn-default flexOnly align-items justifyCenter"
                                            :class="{
                                                active: active_el == 'large',
                                            }"
                                            aria-label="Bigger Font"
                                            @click="fontResize('large')"
                                        >
                                            <i class="fas fa-font fs-22px" aria-hidden="true" />
                                        </button>
                                    </li>
                                    <li v-if="auth.user().roleId == 1" @click="$event.stopPropagation()">
                                        <select v-model="language" class="form-control" @change="changeLanguage">
                                            <option value="enUs">US English</option>
                                            <option value="svSe">Swedish</option>
                                            <option value="fil">Filipino</option>
                                            <option value="">No Locale (Display json keys)</option>
                                        </select>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <notification />

            <div v-if="isDashboard" id="dashboard-top-nav" ref="dashboard-top-nav" class="top_nav_100 desktop d-block"></div>

            <div v-if="isDashboard" id="d-topbar-bottom" ref="d-topbar-bottom" class="top_nav_0 desktop paddingTop30"></div>
        </div>
        <div class="top_nav mobile hidden-md hidden-lg">
            <div class="nav_title_div_left">
                <button class="btn" aria-label="Navigation Menu" @click.prevent="toggleMenu()">
                    <i class="fa fa-bars whiteText" aria-hidden="true" />
                </button>

                <sidebar />
            </div>
            <div class="nav_title_div_middle">
                <img src="https://cdn.intedashboard.com/emails/logo/white_intedashboard.png" width="150" alt="Intedashboard Logo" />
            </div>
            <div class="nav_title_div_right">
                <div class="profile_image">
                    <template v-if="auth.user().avatar">
                        <div class="actual-image img-circle" :style="'background-image:url(\'' + auth.user().avatar + '\');'" />
                    </template>
                    <template v-else>
                        <div class="badge_profile img-circle">
                            <span>{{ initials }}</span>
                        </div>
                    </template>
                </div>
                <div>
                    <button
                        id="dropdownMenuButton"
                        class="btn"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        :aria-label="getStudentName(auth.user()) + ', ' + getStudentRole(auth.user()) + ', Settings'"
                    >
                        <i class="fa fa-cog whiteText" aria-hidden="true" />
                    </button>

                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton" tabindex="0">
                        <ul>
                            <li v-if="!auth.user().isGeneric">
                                <router-link v-slot="{ navigate }" :to="{ name: 'profile.view' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />View My Profile
                                    </button>
                                </router-link>
                            </li>

                            <li>
                                <button class="btn btn-danger" @click.prevent="openLogoutConfirmationModal()">
                                    <i class="fas fa-sign-out-alt marginRight8" aria-hidden="true" />Sign Out
                                    <template v-if="auth.isImpersonation()"> (Impersonation) </template>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="logoutConfirmationModal"
            class="modal danger-modal logoutConfirmationModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="logoutConfirmationModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h1 id="logoutConfirmationModal-title" class="modal-title">Sign Out</h1>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <p class="margin0 marginTop40">
                            Are you sure you want to sign out
                            <template v-if="auth.isImpersonation()"> this impersonation </template>
                            ?
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="logout()">Sign Out</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="resetPasswordModalPrompt"
            class="modal danger-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="resetPasswordModalPrompt-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h1 id="resetPasswordModalPrompt-title" class="modal-title">Password Expiring</h1>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <h2 class="margin0 marginTop40">Please Change Your Password</h2>
                        <br />
                        <p class="col-xs-12 col-md-8 col-md-offset-2">
                            <template v-if="auth.credential().passwordExpired">
                                Your password has not been updated for over twelve months.
                                <br />
                                We recommend that you update your password now.
                            </template>
                            <template v-else>
                                You are using a temporary password and it will expire in 24 hours.
                                <br />
                                Please update your password
                            </template>
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Skip and do this later</button>
                        <button
                            class="btn btn-primary"
                            data-dismiss="modal"
                            @click="
                                toggleModalAsShown();
                                $router.push({ name: 'profile.edit' });
                            "
                        >
                            Update now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../../components/auth/auth.js';
import { useAuthUserStore } from '../../../../stores/auth';
export default {
    components: {
        notification: require(`./../notification.vue`).default,
        sidebar: require(`./sidebar.vue`).default,
    },
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            breadcrumbList: [],
            title: '<i class="fa fa-user"></i>&nbsp;Placeholder',
            scrollFunction: null,
            miniTimerVisible: false,
            active_el: 'normal',
            isSafeExamBrowser: false,
            store: useAuthUserStore(),
            language: this.$i18n.locale,
        };
    },
    computed: {
        initials() {
            return (
                (this.auth.user().firstname ? this.auth.user().firstname.charAt(0) : '') +
                (this.auth.user().lastname ? this.auth.user().lastname.charAt(0) : '')
            );
        },
        /*notPaid(){
            //return true;
            if(this.auth.user().role=='Student'){
                if(!['Active (Paid)'].includes(this.auth.user().status)){
                    if(this.auth.user().account.paymentMethod=='Student-Paid'&&!this.auth.user().isGeneric&&this.auth.user().email!=null){
                         return true;
                    }
                }
                return false;
            }
            return false;
        }*/
        isDashboard() {
            return ['activities.tests.dashboard', 'activities.tests.dashboard.peer-evaluation'].includes(this.$route.name);
        },
    },
    watch: {
        $route: {
            handler: function () {
                this.breadcrumbList = this.$route.meta.breadcrumb ? this.$route.meta.breadcrumb : [];
            },
            deep: true,
        },
        fontResize(newValue) {
            localStorage.setItem('textSize', JSON.stringify(newValue));
        },
    },
    created() {
        var that = this;
        this.breadcrumbList = this.$route.meta.breadcrumb ? this.$route.meta.breadcrumb : [];

        Events.listen('topbar_update', (data) => {
            //console.log('event get list '+this.name);
            if (data.title) {
                that.title = data.title;
            }
            if (data.breadcrumb) {
                that.breadcrumbList = data.breadcrumb;
                if (that.breadcrumbList.length > 0) {
                    if (that.breadcrumbList[0].name == 'Home') {
                        /*if(that.auth.user().role=='Superuser'){
                            that.breadcrumbList[0].name='Superusers';
                            that.breadcrumbList[0].link='superusers.index';
                        }
                        else if(['Super Admin','Teacher'].includes(that.auth.user().role)){
                            that.breadcrumbList[0].name='Courses';
                            that.breadcrumbList[0].link='courses.index';
                        }
                        else if(['Student'].includes(that.auth.user().role)){
                            that.breadcrumbList[0].name='Activities';
                            that.breadcrumbList[0].link='tests.index';
                        }*/
                    }
                    for (var i = that.breadcrumbList.length - 1; i > 0; i--) {
                        if (that.breadcrumbList[0].name == that.breadcrumbList[i].name) {
                            that.breadcrumbList.splice(i, 1);
                        }
                    }
                }
            }
        });
        // if ($(window).scrollTop() > 182) {
        //     console.log('182')
        // }
        this.scrollFunction = function () {
            if ($(window).scrollTop() > 130) {
                that.miniTimerVisible = true;
            } else {
                // console.log($(window).scrollTop());
                that.miniTimerVisible = false;
            }
        }.bind(this);

        Vue.nextTick(function () {
            that.scrollFunction();
        });

        window.addEventListener('load', () => {
            this.updateTopNavHeight();

            // console.log($('.top_banner_message_placeholder').height());
            // var topBannerHeight = $('.top_banner_message_placeholder').height();
            // $('.top_nav.desktop').css('top', topBannerHeight + 'px');
        });
    },
    mounted() {
        var that = this;
        let agent = navigator.userAgent;
        if (agent.indexOf('SEB') !== -1) {
            this.isSafeExamBrowser = true;
        } else {
            this.isSafeExamBrowser = false;
        }

        window.addEventListener('scroll', this.scrollFunction);
        if (this.auth.user().origin == 'Email' && this.auth.credential().passwordExpired && !this.auth.credential().password_modal_shown) {
            Vue.nextTick(function () {
                $('#resetPasswordModalPrompt').modal('show');
            });
        } else if (this.auth.user().origin == 'Email' && this.auth.credential().isUsingTemporaryPassword && !this.auth.credential().password_modal_shown) {
            Vue.nextTick(function () {
                $('#resetPasswordModalPrompt').modal('show');
            });
        }
        if (localStorage.getItem('textSize') === null) {
            localStorage.setItem('textSize', 'normal');
        } else {
            this.active_el = localStorage.getItem('textSize');
        }

        this.$nextTick(() => {
            this.updateTopNavHeight();
        });

        this.$router.afterEach(() => {
            setTimeout(() => {
                this.$nextTick(() => {
                    this.updateTopNavHeight();

                    // var topBannerHeight = $(
                    //     '.top_banner_message_placeholder'
                    // ).height();
                    // $('.top_nav.desktop').css('top', topBannerHeight + 'px');
                });
            }, 1000);
        });

        window.addEventListener('resize', this.updateTopNavHeight);
    },
    updated() {
        if (localStorage.getItem('textSize') === 'small') {
            $('html').css('font-size', '14px');
        } else if (localStorage.getItem('textSize') === 'normal') {
            $('html').css('font-size', '18px');
        } else {
            $('html').css('font-size', '22px');
        }

        this.$root.$emit('fontResized', localStorage.getItem('textSize'));
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.scrollFunction);
        window.removeEventListener('resize', this.updateTopNavHeight);
    },
    methods: {
        toggleModalAsShown() {
            this.store.updatePasswordModalShown(true);
        },
        switchAccount() {
            window.location.href = process.env.VUE_APP_AUTH_URL + '/switch';
        },
        openLogoutConfirmationModal() {
            $('#logoutConfirmationModal').modal('show');
        },
        logout() {
            Events.fire('logout', { redirect: { name: 'auth.login' } });
        },
        getStudentName(student) {
            var name = _.get(student, 'displayName', '');
            if (name.length > 60) {
                return name.slice(0, 57) + '...';
            }
            return name;
        },
        getStudentRole(student) {
            var canAddTeacher = _.get(student, 'canAddTeacher', '');
            var role = _.get(student, 'role', '');
            if (role == 'Super Admin') {
                return 'Super Admin';
            } else if (canAddTeacher && role != 'Student') {
                return 'Admin Teacher';
            } else {
                return role;
            }
        },
        fontResize(el) {
            this.active_el = el;
            localStorage.setItem('textSize', el);

            if (localStorage.getItem('textSize') === null) {
                localStorage.setItem('textSize', 'normal');
            } else {
                localStorage.setItem('textSize', el);
            }

            if (el === 'small') {
                $('html').css('font-size', '14px');
            } else if (el === 'normal') {
                $('html').css('font-size', '18px');
            } else {
                $('html').css('font-size', '22px');
            }

            this.$root.$emit('fontResized', el);
        },
        toggleMenu() {
            var $BODY = $('body'),
                $RIGHT_COL = $('.right_col'),
                $SIDEBAR_MENU = $('#sidebar-menu'),
                $NAV_MENU = $('.nav_menu'),
                $LEFT_COL = $('.left_col'),
                $SIDEBAR_FOOTER = $('.sidebar-footer'),
                $FOOTER = $('footer');
            if ($BODY.hasClass('nav-sm')) {
                $SIDEBAR_MENU.find('li.active ul').hide();
                $SIDEBAR_MENU.find('li.active').addClass('active-sm').removeClass('active');
            } else {
                $SIDEBAR_MENU.find('li.active-sm ul').show();
                $SIDEBAR_MENU.find('li.active-sm').addClass('active').removeClass('active-sm');
            }
            $BODY.toggleClass('nav-sm nav-none');
            // reset height
            $RIGHT_COL.css('min-height', $(window).height());
            var bodyHeight = $BODY.outerHeight(),
                footerHeight = $BODY.hasClass('footer_fixed') ? 0 : $FOOTER.height(),
                leftColHeight = $LEFT_COL.eq(1).height() + $SIDEBAR_FOOTER.height(),
                contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;
            // normalize content
            contentHeight -= $NAV_MENU.height() + footerHeight;
            $RIGHT_COL.css('min-height', contentHeight);
        },
        updateTopNavHeight() {
            $('.top_nav_placeholder').height(_.get(this.$refs, 'topNavHeight.offsetHeight', 0) + 'px');
        },
        truncate(str, characters = 50) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
        changeLanguage() {
            this.$i18n.locale = this.language;
        },
    },
};
</script>

<style scoped lang="scss">
.breadcrumb {
    padding: 0px;
    background: none;
    margin: 0;

    ol {
        list-style: none;
        padding: 0;
        margin: 0;

        & > li {
            display: inline-block;
            font-size: 1rem;
            color: #222;

            & + li::before {
                content: '> ';
                color: #222;
                padding: 0px 5px;
            }

            h1 {
                font-size: 1rem;
                font-weight: normal;
                margin: 0;
                display: inline-block;
            }
        }
    }
}

.top_nav.desktop {
    position: fixed;
    top: 0;
    width: calc(100% - 110px);
    z-index: 1049;
    min-height: 56px;
    background-color: #ffffff;
    margin-left: 110px;
    display: flex;
    align-items: center;
}

.top_nav_100.desktop {
    height: 100%;
    background-color: #f7f8fa;
    margin-left: 110px;
    padding: 30px 20px 0;
    display: flex;

    &.d-block {
        display: block;
    }
}

.topbar-alert-placement {
    margin-left: 110px;
    padding: 0 20px;
}

.top_nav_0.desktop {
    height: 100%;
    background-color: #f7f8fa;
    margin-left: 110px;
    padding: 0px 20px;
    display: flex;
}

.top_nav.desktop .nav_title_div_left {
    width: 220px;
    border-right: solid 2px #d8d8d8;
    padding: 0 30px;
    height: 100%;
    font-size: 24px;
    display: flex;
    align-items: center;
}

.top_nav.desktop .nav_title_div_middle {
    /*width:calc(100% - 465px);*/
    flex: 1;
    padding: 10px;
    height: 100%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top_nav.desktop .nav_title_div_right {
    /*width:295px;*/
    border-left: solid 2px #d8d8d8;
    padding: 10px;
    height: auto;
    font-size: 1rem;
    display: flex;
    align-items: center;
}

.top_nav.mobile .nav_title_div_right {
    width: 100px;
    padding: 0 25px;
    height: 100%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    line-height: 1;
}
.top_nav.mobile {
    background-color: #273142;
    padding: 10px 0;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 998;
    width: 100%;
}

#menu_toggle {
    color: #fff;
    height: 20px;
    display: flex;
    align-items: center;
}

.top_nav.mobile .nav_title_div_left {
    padding: 0 28px;
}

.top_nav.mobile .nav_title_div_middle {
    width: calc(100% - 160px);
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top_nav.desktop .profile_image {
    width: 36px;
    height: 36px;
    border-radius: 30px;
    margin-right: 10px;
}

.top_nav.mobile .profile_image {
    width: 24px;
    height: 24px;
    border-radius: 30px;
    margin-right: 10px;
}

.badge_profile {
    height: 36px;
    width: 36px;
    color: white;
    font-size: 16px;
    font-weight: 900;
    background: #222;
    display: block;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0.8;
}
.top_nav.desktop .actual-image {
    background-color: #989b9c;
    height: 36px;
    width: 36px;

    background-position: center;
    background-size: cover;
}

.top_nav.mobile .actual-image {
    background-color: #989b9c;
    height: 24px;
    width: 24px;

    background-position: center;
    background-size: cover;
}

.top_nav.mobile .dropdown.setting {
    color: #fff;
    height: 14px;
}

@media (max-width: 991px) {
    .dropdown.setting #dropdownMenuButton {
        padding: 0 !important;
        color: #fff;
    }
    .badge_profile {
        width: 24px;
        height: 24px;
        line-height: 2;
        font-size: 12px;
        border: none;
    }
}

body.test .defaultTopBar {
    display: none;
}

.defaultTopBar {
    width: 100%;
    z-index: 100;
    min-height: 72px;
}

#logoutConfirmationModal .modal-body .row:last-child {
    margin-top: 30px;
}

#logoutConfirmationModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}

#logoutConfirmationModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

.isStudent {
    .top_nav.mobile {
        background: #0071be;
    }
}

.calculateWithFixedTimer {
    max-width: calc(100% - 440px);
}
</style>
