import {
    countriesAndCities,
    countriesAndCitiesUnformatted,
    countries,
    tzStrings,
    languages,
    fieldsOfDiscipline,
    influencerLevels,
    academicDisciplines,
} from './variables.js';
import { profanity as profanityList } from '../components/profanity/list.js';
var Filter = require('bad-words');
var regexPlaceholderFunction = (match) => {
    //let censored = match.replace(/(?<!^).(?!$)/g, "*");
    return '<span class="statusTag censorBackground">' + match + '</span>';
};
var profanityFilter = new Filter({
    replaceRegex: /[A-Za-z0-9]+/,
    placeHolder: regexPlaceholderFunction,
});
profanityFilter.addWords(...profanityList);

var Analyzer = require('natural').SentimentAnalyzer;
var stemmer = require('natural').PorterStemmer;
var analyzer = new Analyzer('English', stemmer, 'afinn');
export const globalMixins = {
    data() {
        return {
            echoChannels: [],
            kr_var: {
                countriesAndCities: countriesAndCities,
                countriesAndCitiesUnformatted: countriesAndCitiesUnformatted,
                countries: countries,
                tzStrings: tzStrings,
                languages: languages,
                fieldsOfDiscipline: fieldsOfDiscipline,
                influencerLevels: influencerLevels,
                academicDisciplines: academicDisciplines,
            },
            isMobileView: window.innerWidth < 992,
            isBelow768px: window.innerWidth < 768,
            isBelow472px: window.innerWidth < 472,
        };
    },
    computed: {
        isFirefox() {
            const isFirefox = /Firefox/i.test(navigator.userAgent);

            if (isFirefox) {
                return true;
            }

            return false;
        },
    },
    mounted() {
        window.addEventListener('resize', this.checkWindowWidth);
    },
    unmounted() {
        this.removeEchoListeners();
        window.removeEventListener('resize', this.checkWindowWidth);
    },
    methods: {
        removeEchoListeners() {
            if (!window.Echo) {
                return;
            }
            for (var i = 0; i < this.echoChannels.length; i++) {
                let channel = this.echoChannels[i].channel;
                let event = this.echoChannels[i].event;
                let handler = this.echoChannels[i].handler;
                channel.stopListening(event, handler);
            }
            this.echoChannels = [];
        },
        checkIfElementHasOverflow(wrapperID, contentID) {
            var contentHeight = $('#' + contentID).height();
            var containerHeight = $('#' + wrapperID).height();
            if (contentHeight > containerHeight) {
                return true;
            }
            return false;
        },
        mergeArrayOfObjectsWithKey(arr1, arr2, key) {
            let a = _.cloneDeep(arr1);
            let b = _.cloneDeep(arr2);
            var merged = _.merge(_.keyBy(a, key), _.keyBy(b, key));
            var values = _.values(merged);
            return values;
            /*var u = _.unionBy(arr2, arr1,key);
      console.log(u)
      var u= _({})
      .merge(
        _(arr1).groupBy(key).value(),
        _(arr2).groupBy(key).value()
      )
      .values()
      .flatten()
      .value();
      return u;*/
        },
        convertToReadableDate(iso_date, to_format = 'h:mm A', options = {}) {
            var ret = {};
            ret.date = '';
            ret.timezone = '';
            ret.dateObj = null;
            var from_timezone = _.get(options, 'from_timezone', 'utc');
            var to_timezone = _.get(options, 'to_timezone', 'local');
            var locale = _.get(options, 'locale', 'YYYY-MM-DDThh:mm:ssZ');
            if (iso_date) {
                var from = null;
                var to = null;
                if (from_timezone == 'utc') {
                    if (moment.utc(iso_date, locale).isValid()) {
                        from = moment.utc(iso_date, locale);
                        if (to_timezone == 'local') {
                            to = from.local();
                        }
                    }
                }
                if (from_timezone == 'local') {
                    if (moment(iso_date, locale).isValid()) {
                        from = moment(iso_date, locale);
                        if (to_timezone == 'utc') {
                            to = from.utc();
                        }
                    }
                }
                ret.date = to.format(to_format);
                ret.current_timezone = 'UTC ' + (to.utcOffset() >= 0 ? '+' : '-') + to.utcOffset() / 60;
                ret.dateObj = to;
                return ret;
            } else {
                return ret;
            }
        },
        setMomentOffset(serverTime) {
            if (serverTime) {
                var offset = new Date(serverTime).getTime() * 1000 - Date.now();
                moment.now = function () {
                    return offset + Date.now();
                };
            }
        },
        round(number, precision = 1) {
            if (number == null) {
                return 0;
            }

            if (number % 1 == 0) {
                return number;
            }

            return _.round(number, precision).toFixed(precision);
        },
        natSort(arr, attribute, caseInsensitive) {
            for (var z = 0, t; (t = arr[z]); z++) {
                arr[z].sortArray = new Array();
                var x = 0,
                    y = -1,
                    n = 0,
                    i,
                    j;

                while ((i = (j = t[attribute].charAt(x++)).charCodeAt(0))) {
                    var m = i == 46 || (i >= 48 && i <= 57);
                    if (m !== n) {
                        arr[z].sortArray[++y] = '';
                        n = m;
                    }
                    arr[z].sortArray[y] += j;
                }
            }
            arr.sort(function (a, b) {
                for (var x = 0, aa, bb; (aa = a.sortArray[x]) && (bb = b.sortArray[x]); x++) {
                    if (caseInsensitive) {
                        aa = aa.toLowerCase();
                        bb = bb.toLowerCase();
                    }
                    if (aa !== bb) {
                        var c = Number(aa),
                            d = Number(bb);
                        if (c == aa && d == bb) {
                            return c - d;
                        } else {
                            return aa > bb ? 1 : -1;
                        }
                    }
                }
                return a.sortArray.length - b.sortArray.length;
            });

            for (var z = 0; z < arr.length; z++) {
                // Here we're deleting the unused "sortArray" instead of joining the string parts
                delete arr[z]['sortArray'];
            }
        },
        /*Course Write Privileges*/
        getPrivilege(user_uuid, course = {}) {
            if (course.teachers) {
                let temp = _.find(course.teachers, function (o) {
                    return o.uuid == user_uuid;
                });
                if (temp == null) {
                    temp = {};
                }
                return temp.accountType;
            } else {
                if (_.isEmpty(course)) {
                    course = user_uuid;
                }
                return course.user.accountType;
            }
        },
        hasWritePrivilege(course) {
            if (this.auth.isImpersonation()) {
                return -1;
            }
            var that = this;
            let type = this.getPrivilege(this.auth.user().uuid, course);
            if (type == 'Owner') {
                return 1;
            }
            if (type == 'Full Access') {
                return 2;
            }
            if (type == 'Read Only') {
                return -1;
            }
            if (this.auth.user().roleId == 2) {
                return true;
            }
            return false;
        },
        /*End Course Write Privileges*/
        getFileExtension(filename) {
            if (!filename) {
                return;
            }
            const extension = filename.split('.').pop();
            if (extension) {
                return extension.toLowerCase();
            }
        },
        isImage(file) {
            let allowedExtensions = ['jpg', 'jpeg', 'gif', 'png'];
            if (typeof file === 'string' || file instanceof String) {
                let extension = this.getFileExtension(file);
                return allowedExtensions.includes(extension);
            } else if (typeof file === 'object' && file !== null) {
                let extension = this.getFileExtension(file.filename);
                return allowedExtensions.includes(extension);
            } else {
                return false;
            }
        },
        isPdf(file) {
            let allowedExtensions = ['pdf'];
            if (typeof file === 'string' || file instanceof String) {
                let extension = this.getFileExtension(file);
                return allowedExtensions.includes(extension);
            } else if (typeof file === 'object' && file !== null) {
                let extension = this.getFileExtension(file.filename);
                return allowedExtensions.includes(extension);
            } else {
                return false;
            }
        },
        filterProfanity(string) {
            return string;
            /*
            if (string != '-') {
                if (string) {
                    return profanityFilter.clean(string);
                } else {
                    return string;
                }
            } else {
                return string;
            }*/
        },
        getSentiment(string) {
            let tokens = string ? string.split(' ') : [];
            if (tokens?.length != 0 && string != '-') {
                var score = analyzer.getSentiment(tokens);
                let sentiment = '',
                    emoji = '',
                    type = '';
                if (score > 0.5) {
                    sentiment = 'Strongly Positive';
                    emoji = 'fa-face-smile-beam';
                    type = 'success';
                    score = '+' + parseFloat(score).toFixed(2);
                } else if (score > 0) {
                    sentiment = 'Positive';
                    emoji = 'fa-face-smile';
                    type = 'light-success';
                    score = '+' + parseFloat(score).toFixed(2);
                } else if (score === 0) {
                    sentiment = 'Neutral';
                    emoji = 'fa-face-meh';
                    type = 'yellow';
                    score = 0;
                } else if (score > -0.5) {
                    sentiment = 'Negative';
                    emoji = 'fa-face-frown';
                    type = 'warning';
                    score = parseFloat(score).toFixed(2);
                } else {
                    sentiment = 'Strongly Negative';
                    emoji = 'fa-face-angry';
                    type = 'danger';
                    score = parseFloat(score).toFixed(2);
                }

                return ` 
                        <i class="fa-solid ${emoji} color-emoji-${type} fs-28px d-block marginBottom10 emojiAddStroke" role="img" aria-label="${sentiment} and Score ${score}"></i>
                        <span class="tableStatusTag tableStatusTag-emoji-${type}" aria-hidden="true">
                            <b>${sentiment} | Score: ${score}</b>
                        <span>
                    `;
            } else {
                return '';
            }
        },
        closeAllModal() {
            $('.modal').modal('hide');
        },
        toRoman(num, result = '') {
            const map = {
                M: 1000,
                CM: 900,
                D: 500,
                CD: 400,
                C: 100,
                XC: 90,
                L: 50,
                XL: 40,
                X: 10,
                IX: 9,
                V: 5,
                IV: 4,
                I: 1,
            };

            for (const key in map) {
                if (num >= map[key]) {
                    if (num !== 0) {
                        return this.toRoman(num - map[key], result + key);
                    }
                }
            }
            return result;
        },
        globalInitials(name) {
            return name.charAt(0);
        },
        openTestSidebar() {
            document.getElementById('mySidenav').style.display = 'block';
            $('#mySidenav .sidebar-stepper').attr('style', 'display:block');
        },
        closeTestSidebar() {
            document.getElementById('mySidenav').style.display = 'none';
            $('#mySidenav .sidebar-stepper').attr('style', 'display:none');
        },
        checkWindowWidth() {
            this.isMobileView = window.innerWidth < 992;
            this.isBelow768px = window.innerWidth < 768;
            this.isBelow472px = window.innerWidth < 472;
        },
        escapeRegExp(text) {
            if (!text || typeof text != 'string') {
                return '';
            }
            return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
        },
        toOrdinal(n) {
            const ordinals = [
                'zeroth',
                'first',
                'second',
                'third',
                'fourth',
                'fifth',
                'sixth',
                'seventh',
                'eighth',
                'ninth',
                'tenth',
                'eleventh',
                'twelfth',
                'thirteenth',
                'fourteenth',
                'fifteenth',
                'sixteenth',
                'seventeenth',
                'eighteenth',
                'nineteenth',
                'twentieth',
            ];

            if (n >= 0 && n <= 20) {
                return ordinals[n];
            }
            return n + (['th', 'st', 'nd', 'rd'][(n % 100 >> 3) ^ 1 && n % 10] || 'th');
        },
        capitalize(text) {
            return text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
        },
    },
};
