<template>
    <div v-if="component_done_loading" id="profile-view" class="col-xs-12 right-container">
        <template v-if="auth.user().roleId == 2">
            <div class="links">
                <ul>
                    <li class="active">
                        <a class="nav-link" data-toggle="tab" href="#teacher"> Profile </a>
                    </li>
                    <li>
                        <a class="nav-link" data-toggle="tab" href="#courses"> Courses </a>
                    </li>
                </ul>
            </div>

            <div v-if="auth.user().roleId < 3" class="tab-content">
                <div id="teacher" class="tab-pane fade in active">
                    <kr-panel :with-footer="false">
                        <template #title>
                            <div class="col-xs-12 col-sm-6">
                                <h2>My Profile</h2>
                            </div>
                            <div class="col-xs-12 col-sm-6" :class="{ textAlignRight: !isBelow768px, marginTop20: isBelow768px }">
                                <router-link
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'profile.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" :disabled="!canEdit ? true : undefined" @click="navigate">
                                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                                    </button>
                                </router-link>
                            </div>
                        </template>
                        <template #content>
                            <div class="row flex col-xs-12">
                                <div class="profileImg">
                                    <div>
                                        <template v-if="user.avatar">
                                            <div class="actual-image" :style="'background-image:url(' + user.avatar + ');'"></div>
                                        </template>
                                        <template v-else>
                                            <div class="widthAuto textAlignCenter">
                                                <div
                                                    class="badge_profile"
                                                    style="width: 80px; line-height: 80px; height: 80px; background: #2a3f54; color: white"
                                                >
                                                    <span>
                                                        {{ initials }}
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-7">
                                    <div class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Pronoun</label>
                                                <p class="form-control-static">
                                                    {{ user.pronoun ? user.pronoun : '-' }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Full Name</label>
                                                <p class="form-control-static">
                                                    {{ user.displayName }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Email</label>
                                                <p class="form-control-static wordBreakAll">
                                                    {{ user.email }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Status</label>
                                                <p class="form-control-static align-items">
                                                    <template v-if="!user.isSuspended">
                                                        <template v-if="user.dateActivated">
                                                            <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true"></i>Active
                                                        </template>
                                                        <template v-else-if="!user.dateActivated">
                                                            <i class="fa fa-circle readOnlyCircle marginRight8" aria-hidden="true"></i>Invite Sent
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true"></i>Suspended
                                                    </template>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Activated On</label>
                                                <p class="form-control-static">
                                                    <template v-if="user.dateActivated">
                                                        {{ convertToReadableDate(user.dateActivated, 'DD MMM YYYY, hh:mm a').date }}
                                                        ({{ convertToReadableDate(user.dateActivated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                    </template>
                                                    <template v-else> - </template>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Created By</label>
                                                <p class="form-control-static">
                                                    {{ user.createdBy }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Created On</label>
                                                <p class="form-control-static">
                                                    <template v-if="user.dateCreated">
                                                        {{ convertToReadableDate(user.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                                        ({{ convertToReadableDate(user.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                    </template>
                                                    <template v-else> - </template>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Account Last Login</label>
                                                <p class="form-control-static">
                                                    <template v-if="user.dateLastLogin">
                                                        {{ convertToReadableDate(user.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                                        ({{ convertToReadableDate(user.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                    </template>
                                                    <template v-else> - </template>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Account Last Modified</label>
                                                <p class="form-control-static">
                                                    <template v-if="user.lastModified">
                                                        {{ convertToReadableDate(user.lastModified, 'DD MMM YYYY, hh:mm a').date }}
                                                        ({{ convertToReadableDate(user.lastModified, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                    </template>
                                                    <template v-else> - </template>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="user.isSuspended" class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Suspended On</label>
                                                <p class="form-control-static">
                                                    {{ convertToReadableDate(user.dateSuspended, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(user.dateSuspended, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label"> Highlight Color </label>

                                                <div class="flexOnly align-items">
                                                    <p>
                                                        {{ colorPicked ? colorPicked : '#000000' }}
                                                    </p>
                                                    <input type="color" :value="colorPicked" class="marginLeft10" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </kr-panel>
                </div>

                <div id="courses" class="tab-pane fade in">
                    <div class="row col-xs-12">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th colspan="12">All Courses ({{ user.courses.length }} {{ user.courses.length >= 2 ? 'Courses' : 'Course' }})</th>
                                    </tr>
                                    <tr v-if="user.courses.length != 0">
                                        <th>Course</th>
                                        <th>Code</th>
                                        <th class="width180px minWidth180px maxWidth180px">Course Access Level</th>
                                        <th class="width180px minWidth180px maxWidth180px">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="user.courses.length == 0">
                                        <td>{{ user.displayName }} is not associated with any courses yet</td>
                                    </tr>
                                    <tr v-for="course in user.courses" v-else>
                                        <td>
                                            <router-link
                                                v-tooltip="{
                                                    content: 'View Course',
                                                }"
                                                tag="a"
                                                :to="{
                                                    name: 'courses.view',
                                                    params: { id: course.uuid },
                                                }"
                                                class="link"
                                            >
                                                {{ course.name }}
                                            </router-link>
                                        </td>
                                        <td>
                                            <router-link
                                                v-if="course.code"
                                                v-tooltip="{
                                                    content: 'View Course',
                                                }"
                                                tag="a"
                                                :to="{
                                                    name: 'courses.view',
                                                    params: { id: course.uuid },
                                                }"
                                                class="link"
                                            >
                                                {{ course.code }}
                                            </router-link>
                                            <template v-else>
                                                <p>-</p>
                                            </template>
                                        </td>
                                        <td>
                                            <template v-if="course.accountType == 'Owner'">
                                                <i class="fa fa-circle ownerCircle marginRight8" aria-hidden="true"></i>Owner
                                            </template>
                                            <template v-if="course.accountType == 'Full Access'">
                                                <i class="fa fa-circle fullAccessCircle marginRight8" aria-hidden="true"></i>Full Access
                                            </template>
                                            <template v-if="course.accountType == 'Read Only'">
                                                <i class="fa fa-circle readOnlyCircle marginRight8" aria-hidden="true"></i>Read Only
                                            </template>
                                        </td>
                                        <td>
                                            <router-link
                                                v-slot="{ navigate }"
                                                :to="{
                                                    name: 'courses.view',
                                                    params: { id: course.uuid },
                                                }"
                                                custom
                                            >
                                                <button class="btn btn-primary" @click="navigate">
                                                    <i class="fas fa-eye marginRight8" aria-hidden="true"></i>View Course
                                                </button>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <div v-else-if="[1, 3].includes(auth.user().roleId)" class="row">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-12 col-sm-6">
                        <h2>My Profile</h2>
                    </div>
                    <div class="col-xs-12 col-sm-6" :class="{ textAlignRight: !isBelow768px }">
                        <router-link
                            v-slot="{ navigate }"
                            :to="{
                                name: 'profile.edit',
                                params: $route.params.id,
                            }"
                            custom
                        >
                            <button class="btn btn-default" :disabled="!canEdit ? true : undefined" @click="navigate">
                                <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                            </button>
                        </router-link>
                    </div>
                </template>
                <template #content>
                    <div class="row flex col-xs-12">
                        <div class="profileImg">
                            <div>
                                <template v-if="user.avatar">
                                    <div class="actual-image" :style="'background-image:url(' + user.avatar + ');'"></div>
                                </template>
                                <template v-else>
                                    <div class="widthAuto textAlignCenter">
                                        <div class="badge_profile" style="width: 80px; line-height: 80px; height: 80px; background: #2a3f54; color: white">
                                            <span> {{ initials }} </span>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-8">
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">Full Name</label>
                                        <p class="form-control-static">
                                            {{ user.displayName }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">Email</label>
                                        <p class="form-control-static wordBreakAll">
                                            {{ user.email }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">Status</label>
                                        <p class="form-control-static align-items">
                                            <template v-if="!user.isSuspended">
                                                <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true"></i>Active
                                            </template>
                                            <template v-else> <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true"></i>Suspended </template>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">Activated On</label>
                                        <p class="form-control-static">
                                            <template v-if="user.dateActivated">
                                                {{ convertToReadableDate(user.dateActivated, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(user.dateActivated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">Created By</label>
                                        <p class="form-control-static">
                                            {{ user.createdBy }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">Created On</label>
                                        <p class="form-control-static">
                                            <template v-if="user.dateCreated">
                                                {{ convertToReadableDate(user.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(user.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">Account Last Login</label>
                                        <p class="form-control-static">
                                            <template v-if="user.dateLastLogin">
                                                {{ convertToReadableDate(user.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(user.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">Account Last Modified</label>
                                        <p class="form-control-static">
                                            <template v-if="user.lastModified">
                                                {{ convertToReadableDate(user.lastModified, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(user.lastModified, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="user.isSuspended" class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">Suspended On</label>
                                        <p class="form-control-static">
                                            {{ convertToReadableDate(user.dateSuspended, 'DD MMM YYYY, hh:mm a').date }}
                                            ({{ convertToReadableDate(user.dateSuspended, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> Highlight Color </label>

                                        <div class="flexOnly align-items">
                                            <p>
                                                {{ colorPicked ? colorPicked : '#000000' }}
                                            </p>
                                            <input type="color" :value="colorPicked" class="marginLeft10" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>

        <template v-else>
            <div v-if="user.paymentDetails" class="links">
                <ul>
                    <li class="active">
                        <a class="nav-link" data-toggle="tab" href="#student">Profile</a>
                    </li>
                </ul>
            </div>

            <div :class="{ 'tab-content': user.paymentDetails ? true : false }">
                <div
                    id="student"
                    :class="{
                        'tab-pane fade in active': user.paymentDetails ? true : false,
                    }"
                >
                    <kr-panel :with-footer="false">
                        <template #title>
                            <div class="col-xs-12 col-sm-6">
                                <h2>My Profile</h2>
                            </div>
                            <div class="col-xs-12 col-sm-6" :class="{ textAlignRight: !isBelow768px }">
                                <router-link v-slot="{ navigate }" :to="{ name: 'profile.edit' }" custom>
                                    <button class="btn btn-default" :disabled="!canEdit ? true : undefined" @click="navigate">
                                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                                    </button>
                                </router-link>
                            </div>
                        </template>
                        <template #content>
                            <template v-if="auth.user().role == 'Student'">
                                <div class="col-xs-12 col-md-6">
                                    <div class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Full Name</label>
                                                <p class="form-control-static">
                                                    {{ user.displayName }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Email</label>
                                                <p class="form-control-static">
                                                    {{ user.email ? user.email : '-' }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Status</label>
                                                <p class="form-control-static align-items">
                                                    <i
                                                        class="fa fa-circle marginRight8"
                                                        :class="{
                                                            suspendCircle: ['Lapsed', 'Expired', 'Suspended'].includes(user.status),
                                                            readOnlyCircle: ['Invite Sent', 'Not Yet Invited'].includes(user.status),
                                                            activeCircle: ['Active', 'Active (Not Yet Paid)', 'Active (Paid)'].includes(user.status),
                                                        }"
                                                    >
                                                    </i>
                                                    {{ user.status }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Activated On</label>
                                                <p class="form-control-static">
                                                    <template v-if="user.dateActivated">
                                                        {{ convertToReadableDate(user.dateActivated, 'DD MMM YYYY, hh:mm a').date }}
                                                        ({{ convertToReadableDate(user.dateActivated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                    </template>
                                                    <template v-else> - </template>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Created By</label>
                                                <p class="form-control-static">
                                                    {{ user.createdBy }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Created On</label>
                                                <p class="form-control-static">
                                                    <template v-if="user.dateCreated">
                                                        {{ convertToReadableDate(user.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                                        ({{ convertToReadableDate(user.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                    </template>
                                                    <template v-else> - </template>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Account Last Login</label>
                                                <p class="form-control-static">
                                                    <template v-if="user.dateLastLogin">
                                                        {{ convertToReadableDate(user.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                                        ({{ convertToReadableDate(user.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                    </template>
                                                    <template v-else> - </template>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Account Last Modified</label>
                                                <p class="form-control-static">
                                                    <template v-if="user.lastModified">
                                                        {{ convertToReadableDate(user.lastModified, 'DD MMM YYYY, hh:mm a').date }}
                                                        ({{ convertToReadableDate(user.lastModified, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                    </template>
                                                    <template v-else> - </template>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="user.paymentDetails" class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Payment Status</label>
                                                <p class="form-control-static">
                                                    <template v-if="user.status != 'Active (Paid)'">
                                                        <span style="color: #dc3545"> Not yet Paid </span>
                                                    </template>
                                                    <template v-else>
                                                        <span style="color: #28a745">
                                                            Paid on
                                                            {{ convertToReadableDate(user.paymentDetails.paidDate, 'DD MMM YYYY, hh:mm a').date }}
                                                            ({{ convertToReadableDate(user.paymentDetails.paidDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                        </span>
                                                    </template>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Payment Method</label>
                                                <p class="form-control-static">
                                                    <template v-if="user.paymentDetails.paymentMode">
                                                        <template v-if="user.paymentDetails.paymentMode == 'paypal' && user.amount != 0">
                                                            Credit Card
                                                        </template>
                                                        <template v-else-if="user.paymentDetails.paymentMode == 'paypal' && user.amount == 0">
                                                            Activated by Superuser
                                                        </template>
                                                        <template v-else-if="user.paymentDetails.paymentMode == 'access'">
                                                            Access Code
                                                            {{ user.paymentDetails.accessCode }}
                                                        </template>
                                                        <template v-else>
                                                            {{ user.paymentDetails.paymentMode }}
                                                        </template>
                                                    </template>
                                                    <template v-else> - </template>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="user.isSuspended" class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Suspended On</label>
                                                <p class="form-control-static">
                                                    {{ convertToReadableDate(user.dateSuspended, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(user.dateSuspended, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Origin</label>
                                                <p class="form-control-static">
                                                    {{ user.origin }}
                                                </p>
                                            </div>
                                        </div>
                                        <div v-if="user.paymentDetails" class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label">Account Expiry Date</label>
                                                <p class="form-control-static">
                                                    <template v-if="user.paymentDetails.endDate">
                                                        {{ convertToReadableDate(user.paymentDetails.endDate, 'DD MMM YYYY, hh:mm a').date }}
                                                        ({{ convertToReadableDate(user.paymentDetails.endDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                    </template>
                                                    <template v-else> - </template>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 flex">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="form-group">
                                                <label class="control-label"> Highlight Color </label>

                                                <div class="flexOnly align-items">
                                                    <p>
                                                        {{ colorPicked ? colorPicked : '#000000' }}
                                                    </p>
                                                    <input type="color" :value="colorPicked" class="marginLeft10" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="row flex col-xs-12">
                                    <div class="profileImg">
                                        <div>
                                            <template v-if="user.avatar">
                                                <div class="actual-image" :style="'background-image:url(' + user.avatar + ');'"></div>
                                            </template>
                                            <template v-else>
                                                <div class="widthAuto textAlignCenter">
                                                    <div
                                                        class="badge_profile"
                                                        style="width: 80px; line-height: 80px; height: 80px; background: #2a3f54; color: white"
                                                    >
                                                        <span>
                                                            {{ initials }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="col-xs-12 flex">
                                            <div class="col-xs-12 col-md-6">
                                                <label class="control-label">First Name</label>
                                                <p id="firstname" class="form-control-static">
                                                    {{ user.firstname }}
                                                </p>
                                            </div>
                                            <div class="col-xs-12 col-md-6">
                                                <label class="control-label">Last Name</label>
                                                <p id="lastname" class="form-control-static">
                                                    {{ user.lastname }}
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <label class="control-label">Email</label>
                                            <p id="email" style="margin-bottom: 0" class="form-control-static">
                                                {{ user.email ? user.email : '-' }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </kr-panel>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import KrForm from './../../../components/forms/form';
import KrAuth from './../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            user: {},
            query_fields: { dateFrom: '', dateTo: '' },
            colorPicked: null,
        };
    },
    computed: {
        initials() {
            return (this.user.firstname ? this.user.firstname.charAt(0) : '') + (this.user.lastname ? this.user.lastname.charAt(0) : '');
        },
        canEdit() {
            if (this.auth.isImpersonation()) {
                return false;
            }
            return true;
        },
    },
    created() {
        /*if(['Super Admin','Teacher'].includes(this.user.role)){
            this.$router.push({name:'teachers.view',params:{id:this.user.uuid}})
            return;
          }*/
        Events.fire('topbar_update', {
            breadcrumb: [{ name: 'Home' }, { name: 'My Profile' }],
        });
        this.fetchData();
        this.colorPicked = window.localStorage.getItem('highlightColorLocal');
    },
    methods: {
        search() {
            Events.fire('invoice_listing_refresh');
        },
        downloadInvoice(id) {
            /* var that = this;
          axios({
            url: `/receipts/${id}`,
            method: 'GET',
            responseType: 'blob', // important
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download','(receipt)'+moment().format('YYYY[_]MM[_]DD')+'.pdf');
            document.body.appendChild(link);
            link.click(); 
            link.remove();
          });*/
            let routeData = this.$router.resolve({
                name: 'profile.invoice',
                params: { id: id },
            });
            window.open(routeData.href, '_blank');
        },
        initStartDatepicker() {
            var that = this;
            let options = {
                maxDate: that.query_fields.dateTo,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateFrom != '') {
                options.startDate = moment.utc(that.query_fields.dateFrom).format('YYYY-MM-DD');
            }
            $('input[name="start_log_daterange"]').daterangepicker(options);
            $('input[name="start_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateFrom', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="start_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
            $('input[name="start_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateFrom', '');
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
        },
        initEndDatepicker() {
            var that = this;
            let options = {
                minDate: that.query_fields.dateFrom,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateTo != '') {
                options.startDate = moment.utc(that.query_fields.dateTo).format('YYYY-MM-DD');
            }
            $('input[name="end_log_daterange"]').daterangepicker(options);
            $('input[name="end_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateTo', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="end_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
            $('input[name="end_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateTo', '');
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
        },
        fetchData() {
            var that = this;
            axios.get('/profile').then(function (response) {
                that.user = response.data.data;
                that.component_done_loading = true;
            });
        },
    },
};
</script>
