<template>
    <div class="newPagination">
        <div class="inlineFlex">
            <template v-if="!canChangeQuestion(previous_question_group_idx, previous_question_idx)">
                <a v-if="pagination.length > 1" class="disabled" aria-label="'Previous Question Disabled'">
                    <i class="fas fa-arrow-left-long" aria-hidden="true" />
                </a>
            </template>
            <template v-else>
                <a
                    v-if="pagination.length > 1"
                    href=""
                    class="link borderRadius8"
                    aria-label="'Previous Question Disabled'"
                    @click.prevent="
                        !canChangeQuestion(previous_question_group_idx, previous_question_idx)
                            ? ''
                            : moveToDifferentQuestion(previous_question_group_idx, previous_question_idx, 'scrollToQns')
                    "
                >
                    <i class="fas fa-arrow-left-long" aria-hidden="true" />
                </a>
            </template>

            <div class="flexOnly align-items marginLeft10">
                <ul class="flexOnly">
                    <li
                        v-for="(question, idx) in pagination"
                        :key="'page-' + idx"
                        :class="{
                            active: question.question_group_idx == current_question_group_idx && question.question_idx == question.question_idx,
                            first: question.question_idx == 0,
                            last: idx == pagination.length - 1 || pagination[idx + 1].question_group_idx != question.question_group_idx,
                        }"
                    >
                        <template v-if="question.isDot">
                            <a>...</a>
                        </template>
                        <template v-else>
                            <template v-if="canChangeQuestion(question.question_group_idx, question.question_idx)">
                                <a
                                    href=""
                                    class="link"
                                    :aria-label="'page ' + question.label"
                                    @click.prevent="moveToDifferentQuestion(question.question_group_idx, question.question_idx, 'scrollToQns')"
                                    @keyup.space.prevent="moveToDifferentQuestion(question.question_group_idx, question.question_idx, 'scrollToQns')"
                                >
                                    {{ question.label }}
                                </a>
                            </template>
                            <template v-else>
                                <a class="disabled" :aria-label="question.label">{{ question.label }}</a>
                            </template>
                        </template>
                    </li>
                </ul>
            </div>

            <template v-if="!canChangeQuestion(next_question_group_idx, next_question_idx)">
                <a v-if="pagination.length > 1" class="disabled" aria-label="'Next Page Disabled'">
                    <i class="fas fa-arrow-right-long" aria-hidden="true" />
                </a>
            </template>
            <template v-else>
                <a
                    v-if="pagination.length > 1"
                    href=""
                    class="link borderRadius8"
                    aria-label="'Next Question'"
                    @click.prevent="
                        !canChangeQuestion(next_question_group_idx, next_question_idx)
                            ? ''
                            : moveToDifferentQuestion(next_question_group_idx, next_question_idx, 'scrollToQns')
                    "
                >
                    <i class="fas fa-arrow-right-long" aria-hidden="true" />
                </a>
            </template>
        </div>
    </div>
</template>

<script>
import { testPaginationMixins } from './../../../../../mixins/test';
export default {
    mixins: [testPaginationMixins],
};
</script>
