<template>
    <div>
        <div class="inlineFlex">
            <!-- <button class="btn btn-default " @click.prevent="moveToDifferentQuestion(0,0)" :disabled="(!canChangeQuestion(0,0)) ? true:undefined">
				First
			</button>
			<button class="btn btn-default " @click.prevent="moveToDifferentQuestion(previous_question_group_idx,previous_question_idx)" :disabled="(!canChangeQuestion(previous_question_group_idx,previous_question_idx)) ? true:undefined"></i>
				<i class="fa fa-caret-left"></i>&nbsp;Previous
			</button>	 -->
            <button
                class="btn btn-outline-primary"
                :disabled="!canChangeQuestion(previous_question_group_idx, previous_question_idx) ? true : undefined"
                :aria-label="canChangeQuestion(previous_question_group_idx, previous_question_idx) ? 'Back' : 'Back Button Disabled'"
                @click.prevent="moveToDifferentQuestion(previous_question_group_idx, previous_question_idx)"
            >
                <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Previous Page
            </button>
            <div class="flexOnly align-items marginLeft10">
                <ul class="flexOnly pagination">
                    <template v-for="(question, idx) in pagination">
                        <li
                            :class="{
                                active: question.question_group_idx == current_question_group_idx && question.question_idx == question.question_idx,
                                first: question.question_idx == 0,
                                last: idx == pagination.length - 1 || pagination[idx + 1].question_group_idx != question.question_group_idx,
                            }"
                        >
                            <template v-if="question.isDot"><a>...</a></template>
                            <template v-else>
                                <template v-if="canChangeQuestion(question.question_group_idx, question.question_idx)">
                                    <a
                                        :aria-label="question.label"
                                        @click.prevent="moveToDifferentQuestion(question.question_group_idx, question.question_idx)"
                                        >{{ question.label }}</a
                                    >
                                </template>
                                <template v-else>
                                    <a class="disabled" :aria-label="question.label">{{ question.label }}</a>
                                </template>
                            </template>
                        </li>
                    </template>
                </ul>
            </div>
            <button
                class="btn btn-outline-primary marginLeft10"
                :disabled="!canChangeQuestion(next_question_group_idx, next_question_idx) ? true : undefined"
                :aria-label="canChangeQuestion(next_question_group_idx, next_question_idx) ? 'Forward' : 'Forward Button Disabled'"
                @click.prevent="moveToDifferentQuestion(next_question_group_idx, next_question_idx)"
            >
                Next Page<i class="fa fa-caret-right marginLeft8" aria-hidden="true"></i>
            </button>
            <!-- <button class="btn btn-default " @click.prevent="moveToDifferentQuestion(next_question_group_idx,next_question_idx)" :disabled="(!canChangeQuestion(next_question_group_idx,next_question_idx)) ? true:undefined">
				Next&nbsp;<i class="fa fa-caret-right"></i>
			</button>
			<button class="btn btn-default " @click.prevent="moveToDifferentQuestion(testObj.questions.length-1,testObj.questions[testObj.questions.length-1].length -1)" :disabled="(!canChangeQuestion(testObj.questions.length-1,testObj.questions[testObj.questions.length-1].length -1)) ? true:undefined">
				Last
			</button> -->
        </div>
        <!-- <div class="col-xs-12 hidden-md hidden-lg flexSpaceBetween align-items paginator">
			<button class="btn btn-default " @click.prevent="moveToDifferentQuestion(0,0)" :disabled="(!canChangeQuestion(0,0)) ? true:undefined">
				<i class="fa fa-backward"></i>
			</button>
			<button class="btn btn-default "  @click.prevent="moveToDifferentQuestion(previous_question_group_idx,previous_question_idx)" :disabled="(!canChangeQuestion(previous_question_group_idx,previous_question_idx)) ? true:undefined"></i>
				<i class="fa fa-caret-left"></i>
			</button>	
			<div class="btn padding0">
				<ul class="flexOnly pagination text-listing-pagination justifyCenter">
					<template v-for="(question,idx) in pagination">
						<li :class="{'active':question.question_group_idx==current_question_group_idx&&question.question_idx==question.question_idx}">
							<template v-if="question.isDot"><a>...</a></template>
							<template v-else>
								<template v-if="canChangeQuestion(question.question_group_idx,question.question_idx)">
									<a @click.prevent="moveToDifferentQuestion(question.question_group_idx,question.question_idx)">{{question.label}}</a>
								</template>	
								<template v-else>
									<a class="disabled">{{question.label}}</a>
								</template>	
							</template>
						</li>
					</template>	
		        </ul>	
		    </div>
			<button class="btn btn-default " @click.prevent="moveToDifferentQuestion(next_question_group_idx,next_question_idx)" :disabled="(!canChangeQuestion(next_question_group_idx,next_question_idx)) ? true:undefined">
				<i class="fa fa-caret-right"></i>
			</button>
			<button class="btn btn-default " @click.prevent="moveToDifferentQuestion(testObj.questions.length-1,testObj.questions[testObj.questions.length-1].length -1)" :disabled="(!canChangeQuestion(testObj.questions.length-1,testObj.questions[testObj.questions.length-1].length -1)) ? true:undefined">
				<i class="fa fa-forward"></i>
			</button>
		</div> -->
    </div>
</template>

<script>
import { testPaginationMixins } from './../../../../../../mixins/test';
export default {
    mixins: [testPaginationMixins],
    methods: {
        //override from mixin check if we can change to different question
        canChangeQuestion(question_group_idx, question_idx) {
            return false;
        },
    },
};
</script>

<style>
.paginator button,
.paginator .buttons,
.paginator .btn,
.paginator router-link {
    font-weight: normal;
}
</style>
<style scoped>
a.disabled {
    opacity: 0.3;
}
</style>
