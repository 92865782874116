<template>
    <div
        class="statusBar no-print"
        :class="{
            needClarification: isTestCompleted && testObj.type == 'trat' && canClarify,
            isReleased: isTestCompleted && testObj.type == 'trat' && canClarify && testObj.clarificationStatus == 'released',
        }"
    >
        <div class="panel hidden-xs hidden-sm">
            <div class="panel-heading flexSpaceBetween align-items">
                <div class="flex align-items">
                    <h2 class="fs-16px">
                        <template v-if="sideBarClosedValue">Qns Status</template>
                        <template v-else>Question Status</template>
                    </h2>
                    <button
                        class="btn btn-outline-default"
                        :aria-expanded="sideBarClosedValue ? 'false' : 'true'"
                        aria-label="Question Status"
                        @click.prevent="handleButtonClick"
                    >
                        <i id="toggleIcon" class="fas fa-angles-right" aria-hidden="true" />
                    </button>
                </div>

                <div class="flexRight">
                    <router-link
                        v-if="canClarify && !sideBarClosedValue"
                        v-slot="{ navigate }"
                        v-tooltip="'View All Clarifications'"
                        :to="{
                            name: 'tests.clarification',
                            params: { id: testObj.uuid },
                        }"
                        custom
                    >
                        <button class="btn btn-outline-primary" @click="navigate">
                            <i class="fas fa-eye" aria-label="View All Clarifications" />
                        </button>
                    </router-link>

                    <router-link
                        v-if="canDiscuss && !sideBarClosedValue"
                        v-slot="{ navigate }"
                        v-tooltip="'View Discussions'"
                        :to="{
                            name: 'tests.discussion',
                            params: { id: testObj.uuid },
                        }"
                        custom
                    >
                        <button class="btn btn-outline-primary menuText" @click="navigate">
                            <i class="fas fa-eye" aria-label="View Discussions" />
                        </button>
                    </router-link>
                </div>
            </div>

            <div class="panel-body test-sideBar-panel-body" :class="{ 'panel-body-closed': sideBarClosedValue }">
                <div v-if="!sideBarClosedValue" class="dropdown menuText marginBottom20 width100">
                    <button
                        id="viewDropdownMenuButton"
                        class="btn width100 flexSpaceBetween alignBaseline capitalize"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        {{ sort }}
                        <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                    </button>
                    <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                        <ul>
                            <li>
                                <a
                                    :class="{
                                        'active disabled': sort == 'all',
                                    }"
                                    href="#"
                                    @click="
                                        sort = 'all';
                                        getNumberOfQns();
                                    "
                                >
                                    All
                                </a>
                            </li>
                            <li>
                                <a
                                    :class="{
                                        'active disabled': sort == 'answered',
                                    }"
                                    href="#"
                                    @click="
                                        sort = 'answered';
                                        getNumberOfQns();
                                    "
                                >
                                    Answered
                                </a>

                                <ul>
                                    <li v-if="testObj.type == 'trat'">
                                        <i class="fa-solid fa-turn-up fa-rotate-90" aria-hidden="true" />
                                        <a
                                            :class="{
                                                'active disabled': sort == 'wrong answer',
                                            }"
                                            href="#"
                                            @click="
                                                sort = 'wrong answer';
                                                getNumberOfQns();
                                            "
                                        >
                                            Wrong Answer
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a
                                    :class="{
                                        'active disabled': sort == 'not answered',
                                    }"
                                    href="#"
                                    @click="
                                        sort = 'not answered';
                                        getNumberOfQns();
                                    "
                                >
                                    Not Answered
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <template v-else>
                    <div class="marginBottom20">
                        <template v-if="sort == 'all'">
                            <div class="tableStatusTag whiteSpaceNowrap default">All</div>
                        </template>
                        <template v-if="sort == 'not answered'">
                            <div class="tableStatusTag whiteSpaceNowrap danger">Not Answered</div>
                        </template>
                        <template v-if="sort == 'answered'">
                            <div class="tableStatusTag whiteSpaceNowrap answered">Answered</div>
                        </template>
                        <template v-if="sort == 'wrong answer'">
                            <div class="tableStatusTag whiteSpaceNowrap danger">Wrong Answer</div>
                        </template>
                    </div>
                </template>

                <ul>
                    <template v-if="getNumbers == 0">
                        <template v-if="sort == 'not answered'">
                            <p>You have <b>Answered</b> all the questions.</p>
                        </template>
                        <template v-if="sort == 'answered'">
                            <p>You have <b>Not Answered</b> any question.</p>
                        </template>
                        <template v-if="sort == 'wrong answer'">
                            <p>You have <b>Not Get Wrong Answer</b> for any question.</p>
                        </template>
                    </template>
                    <template v-for="(questionGroup, question_group_idx) in testObj.questions">
                        <template v-for="(question, question_idx) in questionGroup">
                            <li
                                v-if="canDisplayQuestion(question)"
                                :key="testObj.type + '-' + getQuestionNo(question_group_idx, question_idx)"
                                :class="{
                                    active: question_group_idx == current_question_group_idx && question_idx == current_question_idx,
                                    haveAnswered: getQuestionStatus(question_group_idx, question_idx) == 1 && sideBarClosedValue,
                                    disabled: !canChangeQuestion(question_group_idx, question_idx) && !isTeamLeader && testObj.type == 'trat',
                                }"
                            >
                                <button
                                    class="leftContent"
                                    :class="{
                                        'flexSpaceBetween align-items': !sideBarClosedValue,
                                        disabled: !canChangeQuestion(question_group_idx, question_idx),
                                    }"
                                    :disabled="!canChangeQuestion(question_group_idx, question_idx) ? true : undefined"
                                    @click="
                                        !canChangeQuestion(question_group_idx, question_idx)
                                            ? ''
                                            : moveToDifferentQuestion(question_group_idx, question_idx, 'scrollToQns')
                                    "
                                >
                                    <span>
                                        <i class="fa-solid fa-clipboard-list marginRight8" aria-hidden="true" />
                                        <span class="visible-hidden">Question {{ getQuestionNo(question_group_idx, question_idx) }}</span>
                                        <span aria-hidden="true">
                                            <b>Q{{ getQuestionNo(question_group_idx, question_idx) }}. </b>
                                        </span>
                                    </span>

                                    <div
                                        v-if="
                                            !(isTestCompleted && testObj.type == 'trat' && canClarify && testObj.clarificationStatus == 'released') &&
                                            !sideBarClosedValue
                                        "
                                        class="tableStatusTag whiteSpaceNowrap menuText testSidebar-status"
                                        :class="{
                                            danger:
                                                getQuestionStatus(question_group_idx, question_idx) == -1 ||
                                                getQuestionStatus(question_group_idx, question_idx) == 0,
                                            answered: getQuestionStatus(question_group_idx, question_idx) == 1,
                                        }"
                                    >
                                        <p>
                                            <template v-if="getQuestionStatus(question_group_idx, question_idx) == -1"> Wrong Answer </template>
                                            <template v-if="getQuestionStatus(question_group_idx, question_idx) == 0"> Not Answered </template>
                                            <template v-if="getQuestionStatus(question_group_idx, question_idx) == 1"> Answered </template>
                                        </p>
                                    </div>
                                </button>

                                <div v-if="isTestCompleted && testObj.type == 'trat' && canClarify" class="middleContent">
                                    <template v-if="!isClarificationAnswered(question_group_idx, question_idx) && !sideBarClosedValue">
                                        <p class="menuText">Clarification Added</p>
                                    </template>

                                    <template v-else-if="!['paused', 'stopped', 'released'].includes(testObj.clarificationStatus) && !sideBarClosedValue">
                                        <a
                                            class="link menuText"
                                            href="#"
                                            :class="{
                                                disabled: !canChangeQuestion(question_group_idx, question_idx),
                                            }"
                                            aria-label="click here to add clarification"
                                            @click="moveToDifferentQuestion(question_group_idx, question_idx, 'scrollToQns')"
                                        >
                                            Need Clarification
                                        </a>
                                    </template>
                                </div>

                                <div
                                    v-if="isTestCompleted && testObj.type == 'trat' && canClarify && testObj.clarificationStatus == 'released'"
                                    class="rightContent"
                                >
                                    <template v-if="isQuestionClarificationAssignedToMe(question_group_idx, question_idx) && !sideBarClosedValue">
                                        <p class="menuText">Clarification Assigned to us</p>
                                    </template>
                                    <template v-else-if="!sideBarClosedValue">
                                        <i class="fa-solid fa-xmark greyText menuText" aria-label="No Clarification Assigned to us" aria-hidden="true" />
                                    </template>
                                </div>
                            </li>
                            <span
                                v-if="
                                    canDisplayQuestion(question) &&
                                    question_idx == questionGroup.length - 1 &&
                                    question_group_idx != testObj.questions.length - 1
                                "
                                class="divider"
                            />
                        </template>
                    </template>
                </ul>
            </div>
        </div>

        <div id="testSidebarModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="testSidebarModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h4 id="testSidebarModal-title" class="modal-title flexSpaceBetween align-items">
                            <template v-if="canClarify"> Clarifications & Assignments </template>
                            <template v-else-if="canDiscuss"> Question Discussions </template>
                            <template v-else> Questions Status </template>

                            <div class="marginRight10">
                                <router-link
                                    v-if="canClarify"
                                    v-slot="{ navigate }"
                                    v-tooltip="'View All Clarifications'"
                                    :to="{
                                        name: 'tests.clarification',
                                        params: { id: testObj.uuid },
                                    }"
                                    custom
                                >
                                    <button class="btn btn-outline-primary" @click="navigate">
                                        <i class="fas fa-eye" aria-label="View All Clarifications" />
                                    </button>
                                </router-link>
                                <router-link
                                    v-if="canDiscuss"
                                    v-slot="{ navigate }"
                                    v-tooltip="'View Discussions'"
                                    :to="{
                                        name: 'tests.discussion',
                                        params: { id: testObj.uuid },
                                    }"
                                    custom
                                >
                                    <button class="btn btn-outline-primary" @click="navigate">
                                        <i class="fas fa-eye" aria-label="View Discussions" />
                                    </button>
                                </router-link>
                            </div>
                        </h4>
                    </div>
                    <div class="modal-body test-sideBar">
                        <div class="dropdown menuText marginBottom20 width100">
                            <button
                                id="viewDropdownMenuButton"
                                class="btn width100 flexSpaceBetween alignBaseline capitalize"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {{ sort }}
                                <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                            </button>
                            <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                <ul>
                                    <li :class="{ active: sort == 'all' }">
                                        <a
                                            :class="{ disabled: sort == 'all' }"
                                            href="#"
                                            @click="
                                                sort = 'all';
                                                getNumberOfQns();
                                            "
                                        >
                                            All
                                        </a>
                                    </li>
                                    <li :class="{ active: sort == 'answered' }">
                                        <a
                                            :class="{
                                                disabled: sort == 'answered',
                                            }"
                                            href="#"
                                            @click="
                                                sort = 'answered';
                                                getNumberOfQns();
                                            "
                                        >
                                            Answered
                                        </a>
                                    </li>
                                    <li
                                        :class="{
                                            active: sort == 'not answered',
                                        }"
                                    >
                                        <a
                                            :class="{
                                                disabled: sort == 'not answered',
                                            }"
                                            href="#"
                                            @click="
                                                sort = 'not answered';
                                                getNumberOfQns();
                                            "
                                        >
                                            Not Answered
                                        </a>
                                    </li>
                                    <li
                                        v-if="testObj.type == 'trat'"
                                        :class="{
                                            active: sort == 'wrong answer',
                                        }"
                                    >
                                        <a
                                            :class="{
                                                disabled: sort == 'wrong answer',
                                            }"
                                            href="#"
                                            @click="
                                                sort = 'wrong answer';
                                                getNumberOfQns();
                                            "
                                        >
                                            Wrong Answer
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <ul>
                            <template v-if="getNumbers == 0">
                                <template v-if="sort == 'not answered'">
                                    <p>You have <b>Answered</b> all the questions.</p>
                                </template>
                                <template v-if="sort == 'answered'">
                                    <p>You have <b>Not Answered</b> any question.</p>
                                </template>
                                <template v-if="sort == 'wrong answer'">
                                    <p>You have <b>Not Get Wrong Answer</b> for any question.</p>
                                </template>
                            </template>
                            <template v-for="(questionGroup, question_group_idx) in testObj.questions">
                                <template v-for="(question, question_idx) in questionGroup">
                                    <li
                                        v-if="canDisplayQuestion(question)"
                                        :key="testObj.type + '-' + getQuestionNo(question_group_idx, question_idx)"
                                        :class="{
                                            active: question_group_idx == current_question_group_idx && question_idx == current_question_idx,
                                            haveAnswered: getQuestionStatus(question_group_idx, question_idx) == 1 && sideBarClosedValue,
                                            disabled: !canChangeQuestion(question_group_idx, question_idx) && !isTeamLeader && testObj.type == 'trat',
                                        }"
                                    >
                                        <a
                                            class="leftContent"
                                            href="#"
                                            :class="{
                                                'flexSpaceBetween align-items': !sideBarClosedValue,
                                                disabled: !canChangeQuestion(question_group_idx, question_idx),
                                            }"
                                            @click="
                                                !canChangeQuestion(question_group_idx, question_idx)
                                                    ? ''
                                                    : moveToDifferentQuestion(question_group_idx, question_idx, 'scrollToQns')
                                            "
                                        >
                                            <span>
                                                <i class="fa-solid fa-clipboard-list marginRight8" aria-hidden="true" />

                                                <span class="visible-hidden">Question {{ getQuestionNo(question_group_idx, question_idx) }}</span>
                                                <span aria-hidden="true">
                                                    <b>Q{{ getQuestionNo(question_group_idx, question_idx) }}. </b>
                                                </span>
                                            </span>

                                            <div
                                                v-if="
                                                    !(isTestCompleted && testObj.type == 'trat' && canClarify && testObj.clarificationStatus == 'released') &&
                                                    !sideBarClosedValue
                                                "
                                                class="tableStatusTag whiteSpaceNowrap menuText testSidebar-status"
                                                :class="{
                                                    danger:
                                                        getQuestionStatus(question_group_idx, question_idx) == -1 ||
                                                        getQuestionStatus(question_group_idx, question_idx) == 0,
                                                    answered: getQuestionStatus(question_group_idx, question_idx) == 1,
                                                }"
                                            >
                                                <p>
                                                    <template v-if="getQuestionStatus(question_group_idx, question_idx) == -1"> Wrong Answer </template>
                                                    <template v-if="getQuestionStatus(question_group_idx, question_idx) == 0"> Not Answered </template>
                                                    <template v-if="getQuestionStatus(question_group_idx, question_idx) == 1"> Answered </template>
                                                </p>
                                            </div>
                                        </a>

                                        <div v-if="isTestCompleted && testObj.type == 'trat' && canClarify" class="middleContent">
                                            <template v-if="!isClarificationAnswered(question_group_idx, question_idx) && !sideBarClosedValue">
                                                <p class="menuText">Clarification Added</p>
                                            </template>

                                            <template
                                                v-else-if="!['paused', 'stopped', 'released'].includes(testObj.clarificationStatus) && !sideBarClosedValue"
                                            >
                                                <a
                                                    class="link menuText"
                                                    :class="{
                                                        disabled: !canChangeQuestion(question_group_idx, question_idx),
                                                    }"
                                                    href="#"
                                                    aria-label="click here to add clarification"
                                                    @click="moveToDifferentQuestion(question_group_idx, question_idx, 'scrollToQns')"
                                                >
                                                    Need Clarification
                                                </a>
                                            </template>
                                        </div>

                                        <div
                                            v-if="isTestCompleted && testObj.type == 'trat' && canClarify && testObj.clarificationStatus == 'released'"
                                            class="rightContent"
                                        >
                                            <template v-if="isQuestionClarificationAssignedToMe(question_group_idx, question_idx) && !sideBarClosedValue">
                                                <p class="menuText">Clarification Assigned to us</p>
                                            </template>
                                            <template v-else-if="!sideBarClosedValue">
                                                <i
                                                    class="fa-solid fa-xmark greyText menuText"
                                                    aria-label="No Clarification Assigned to us"
                                                    aria-hidden="true"
                                                />
                                            </template>
                                        </div>
                                    </li>
                                    <span
                                        v-if="
                                            canDisplayQuestion(question) &&
                                            question_idx == questionGroup.length - 1 &&
                                            question_group_idx != testObj.questions.length - 1
                                        "
                                        class="divider"
                                    />
                                </template>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { testMixins } from './../../../../../mixins/test';
export default {
    mixins: [testMixins],
    props: ['sideBarClosedValue'],
    data() {
        return {
            sort: 'all',
            getNumbers: 0,
        };
    },
    computed: {
        canClarify() {
            if (this.testObj.type == 'trat' && this.testObj.settings.allowTeamClarifications) {
                return true;
            }
            return false;
        },
        canDiscuss() {
            if (this.testObj.type == 'application' && this.isTestCompleted) {
                return true;
            }
            return false;
        },
    },
    created() {
        this.getNumberOfQns();
    },
    mounted() {
        this.getNumberOfQns();
    },
    beforeUnmount() {
        Events.off('open-test-sidebar-modal');
    },
    methods: {
        canDisplayQuestion(question) {
            let status = this.getQuestionStatus(question.group - 1, question.order - 1);
            if ((status == 0 && this.sort == 'answered') || (status != 0 && this.sort == 'not answered') || (status != -1 && this.sort == 'wrong answer')) {
                return false;
            }
            return true;
        },
        isQuestionClarificationAssignedToMe(question_group_idx, question_idx) {
            let isAssigned = _.get(this.testObj.questions, question_group_idx + '.' + question_idx + '.isAssignedToMe', '');
            if (isAssigned) {
                return true;
            }
            return false;
        },
        isClarificationAnswered(question_group_idx, question_idx) {
            let clarification = _.get(this.testObj.questions, question_group_idx + '.' + question_idx + '.studentAnswer.clarifications', '');
            if (!clarification) {
                return true;
            }
            return false;
        },
        getNumberOfQns() {
            var that = this;

            Vue.nextTick(function () {
                that.getNumbers = $('.statusBar li:visible').length;
            });
        },
        handleButtonClick() {
            this.$emit('button-clicked');
        },
    },
};
</script>
