<template>
    <div v-if="component_done_loading" :id="id">
        <template v-if="pageType == 'form'">
            <div v-if="!addQuestionBankView" class="marginBottom20">
                <button class="btn btn-success" :disabled="total_questions_count == 0" @click.prevent="saveManageQuestions()">
                    Go to Optional Settings
                    <i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
                </button>
                <button class="btn btn-default" aria-label="Save and Exit" @click.prevent="saveAndExit">
                    <i class="fa fa-floppy-o marginRight8" aria-hidden="true" />Save and Exit
                </button>
            </div>
        </template>

        <div v-if="pageType != 'confirmation'" class="pageTitle">
            <h1>Basic Settings</h1>
            <p class="col-md-6">
                This page allows you to create an activity by choosing the module it belongs to, selecting the activity type, and setting its name and
                description. If you have pre-made templates in the Library, you can also import an activity from there. Updates will appear in the panel below.
            </p>
        </div>

        <div v-if="addQuestionBankView" class="buttonGroupInline marginBottom30">
            <button class="btn btn-default" aria-label="Back to Other Methods" @click.prevent="addQuestionBankView = false">Back to Other Methods</button>

            <button
                class="btn btn-success"
                :disabled="selected_questions.length == 0 ? true : undefined"
                :aria-label="'Add Selected Questions (' + selected_questions.length + ')'"
                @click.prevent="saveSelectedQuestions"
            >
                Add Selected Questions ({{ selected_questions.length }})
            </button>
        </div>
        <div v-if="addQuestionBankView" class="alert alert-warning marginBottom20">
            <i class="fas fa-info-circle marginRight8" aria-hidden="true" />Please select question(s) for this activity from the question bank.
        </div>
        <div v-if="addQuestionBankView" class="flex questionIndex">
            <div class="col-xs-12 col-md-3">
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-12 col-sm-6">
                            <h2>Filter By</h2>
                        </div>

                        <div class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                            <button
                                class="btn btn-default margin0 flexInherit"
                                @click.prevent="
                                    query_fields.q = '';
                                    query_fields.type = allowedQuestionTypes;
                                    query_fields.topics = '';
                                    query_fields.difficultyLevel = '';
                                    query_fields.creatorUuid = '';
                                    search();
                                "
                            >
                                Clear All
                            </button>
                        </div>
                    </template>
                    <template #content>
                        <div class="questionFilterColumn">
                            <div class="form-group form-search has-feedback has-feedback-left">
                                <input v-model="query_fields.q" type="text" class="form-control" placeholder="Search..." aria-label="Search" />
                                <i class="fa fa-search form-control-feedback" aria-hidden="true" />
                            </div>

                            <div class="form-group">
                                <label class="control-label"> Type </label>
                                <div class="dropdown new-custom-search-select d-block">
                                    <button
                                        id="viewDropdownMenuButton"
                                        class="btn"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <template v-if="query_fields.type == allowedQuestionTypes && filters.types.length > 1"> Select question type </template>
                                        <template v-else-if="query_fields.type == 'ratingV2'"> Rating </template>
                                        <template v-else-if="query_fields.type == 'openendedV2'"> Openended </template>
                                        <template v-else-if="query_fields.type == 'pointDistribution'"> Point Distribution </template>
                                        <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-new width100" aria-labelledby="viewDropdownMenuButton">
                                        <ul role="listbox" class="maxHeight255px overflowAuto">
                                            <li
                                                v-if="filters.types.length > 1"
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.type = allowedQuestionTypes;
                                                    search();
                                                "
                                            >
                                                <button
                                                    @click="
                                                        query_fields.type = allowedQuestionTypes;
                                                        search();
                                                    "
                                                >
                                                    Select question type
                                                </button>
                                            </li>
                                            <li
                                                v-for="(type, idx) in filters.types"
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.type = type;
                                                    search();
                                                "
                                            >
                                                <button
                                                    @click="
                                                        query_fields.type = type;
                                                        search();
                                                    "
                                                >
                                                    <template v-if="type == 'ratingV2'"> Rating </template>
                                                    <template v-else-if="type == 'openendedV2'"> Openended </template>
                                                    <template v-else-if="type == 'pointDistribution'"> Point Distribution </template>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="flex flexSpaceBetween marginBottom10 align-items">
                                    <label class="control-label marginBottom0 paddingTop5"> Creator </label>
                                    <div class="checkboxField">
                                        <label for="showOnlyMine-Manage" class="marginRight8"> Show only mine </label>
                                        <input
                                            id="showOnlyMine-Manage"
                                            v-model="query_fields.creatorUuid"
                                            class="marginRight0"
                                            type="checkbox"
                                            :true-value="auth.user().uuid"
                                            false-value=""
                                            @change="search()"
                                        />
                                    </div>
                                </div>
                                <div class="dropdown new-custom-search-select d-block">
                                    <button
                                        id="viewDropdownMenuButton"
                                        class="btn"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <template v-if="query_fields.creatorUuid">
                                            {{ creatorName }}
                                        </template>
                                        <template v-else> Select creator </template>
                                        <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-new width100" aria-labelledby="viewDropdownMenuButton">
                                        <ul role="listbox" class="maxHeight255px overflowAuto">
                                            <li
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.creatorUuid = '';
                                                    search();
                                                "
                                            >
                                                <button
                                                    @click="
                                                        query_fields.creatorUuid = '';
                                                        search();
                                                    "
                                                >
                                                    Select creator
                                                </button>
                                            </li>
                                            <li
                                                v-for="(creator, idx) in filters.creators"
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.creatorUuid = creator.uuid;
                                                    search();
                                                "
                                            >
                                                <button
                                                    @click="
                                                        query_fields.creatorUuid = creator.uuid;
                                                        search();
                                                    "
                                                >
                                                    {{ creator.name }}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="control-label"> Topic </label>
                                <div class="dropdown new-custom-search-select d-block">
                                    <button
                                        id="viewDropdownMenuButton"
                                        class="btn"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <template v-if="query_fields.topics">
                                            {{ query_fields.topics }}
                                        </template>
                                        <template v-else> Select topic </template>
                                        <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-new width100" aria-labelledby="viewDropdownMenuButton">
                                        <ul role="listbox" class="maxHeight255px overflowAuto">
                                            <li
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.topics = '';
                                                    search();
                                                "
                                            >
                                                <button
                                                    @click="
                                                        query_fields.topics = '';
                                                        search();
                                                    "
                                                >
                                                    Select topic
                                                </button>
                                            </li>
                                            <li
                                                v-for="(topic, idx) in filters.topics"
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.topics = topic;
                                                    search();
                                                "
                                            >
                                                <button
                                                    @click="
                                                        query_fields.topics = topic;
                                                        search();
                                                    "
                                                >
                                                    {{ topic }}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="control-label"> Difficulty </label>
                                <div class="dropdown new-custom-search-select d-block">
                                    <button
                                        id="viewDropdownMenuButton"
                                        class="btn"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <template v-if="query_fields.difficultyLevel">
                                            {{ query_fields.difficultyLevel }}
                                        </template>
                                        <template v-else> Select difficulty level </template>
                                        <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-new width100" aria-labelledby="viewDropdownMenuButton">
                                        <ul role="listbox" class="maxHeight255px overflowAuto">
                                            <li
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.difficultyLevel = '';
                                                    search();
                                                "
                                            >
                                                <button
                                                    @click="
                                                        query_fields.difficultyLevel = '';
                                                        search();
                                                    "
                                                >
                                                    Select difficulty level
                                                </button>
                                            </li>
                                            <li
                                                v-for="(difficultyLevel, idx) in filters.difficultyLevels"
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.difficultyLevel = difficultyLevel;
                                                    search();
                                                "
                                            >
                                                <button
                                                    @click="
                                                        query_fields.difficultyLevel = difficultyLevel;
                                                        search();
                                                    "
                                                >
                                                    {{ difficultyLevel }}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>
            </div>
            <div v-if="addQuestionBankView" class="questionIndexPanel col-xs-12 col-md-9 cardsCon">
                <kr-panel :with-footer="false">
                    <template #title>
                        <h2>
                            <template v-if="!viewSelectedInQuestionBank">Question Bank ({{ questionSearchData.meta.paginator.total }})</template>
                            <template v-else>Selected Questions ({{ selected_questions.length }})</template>
                        </h2>
                    </template>
                    <template #content>
                        <div class="flexSpaceBetween marginBottom30 align-items paddingLeft20">
                            <div v-if="!viewSelectedInQuestionBank" class="checkboxField flex align-items">
                                <input id="selectAll" type="checkbox" @click="selectAllQuestions()" />
                                <label for="selectAll"> Select All </label>
                            </div>
                            <div>
                                <button
                                    v-if="!viewSelectedInQuestionBank"
                                    class="btn btn-primary"
                                    :disabled="selected_questions.length == 0 ? true : undefined"
                                    @click.prevent="viewSelectedInQuestionBank = true"
                                >
                                    Only Show Selected ({{ selected_questions.length }})
                                </button>
                                <button v-else class="btn btn-default" aria-label="Back to Question Bank" @click.prevent="viewSelectedInQuestionBank = false">
                                    Back to Question Bank
                                </button>
                            </div>
                        </div>
                        <kr-search
                            name="questions_listing"
                            :url="{ url: '/questions/bank' }"
                            :options="{
                                per_page: 20,
                                page: pagination.page,
                                query_fields: query_fields,
                                thead_title: false,
                                search_field: false,
                                newServerMode: {
                                    includes: ['q', 'isSuspended', 'sort', 'order', 'creatorUuid', 'type', 'topics', 'difficultyLevel'],
                                },
                            }"
                            @retrieve-data="getQuestionData"
                        >
                            <template #listing-top />
                            <template #content="props">
                                <div v-if="!viewSelectedInQuestionBank" class="flex">
                                    <template v-if="props.model.length == 0">
                                        <div class="paddingLeft20">
                                            <p>No questions match your search / filter</p>
                                        </div>
                                    </template>

                                    <template v-for="question in props.model" v-else>
                                        <div class="cardCons-question-bank">
                                            <div class="card">
                                                <div class="card-header flex">
                                                    <div>
                                                        <i class="fa fa-list-ul marginRight8" />
                                                        <template v-if="question.type == 'ratingV2'"> Rating (Peer Evaluation Only) </template>
                                                        <template v-if="question.type == 'openendedV2'"> Openended (Peer Evaluation Only) </template>
                                                        <template v-if="question.type == 'pointDistribution'"> Point Distribution </template>
                                                    </div>
                                                </div>
                                                <div class="card-body" @click.prevent="openQuestionInfoModal(question)">
                                                    <div :id="'questionStem_' + question.uuid" class="question-area col-xs-11 col-md-10">
                                                        <template v-if="question.type == 'pointDistribution'">
                                                            <label class="control-label fs-14px"> Type </label>
                                                            <p class="capitalize marginBottom10">{{ question.settings.pointDistributionStyle }} Style</p>
                                                        </template>

                                                        <label class="control-label fs-14px"> Criteria </label>
                                                        <p class="marginBottom10">
                                                            <template v-if="fontResized == 'small'">
                                                                <kr-math :input="truncate(question.name, 160)" :safe="truncate(false, 160)" />
                                                            </template>

                                                            <template v-else-if="fontResized == 'normal'">
                                                                <kr-math :input="truncate(question.name, 80)" :safe="truncate(false, 80)" />
                                                            </template>

                                                            <template v-else-if="fontResized == 'large'">
                                                                <kr-math :input="truncate(question.name, 60)" :safe="truncate(false, 60)" />
                                                            </template>
                                                        </p>

                                                        <template v-if="['ratingV2', 'openendedV2'].includes(question.type)">
                                                            <label class="control-label fs-14px">
                                                                Question Stem(s) ({{ Object.keys(question.questionStems).length }})
                                                            </label>
                                                            <div
                                                                v-for="(question, idx) in question.questionStems"
                                                                class="inlineFlex bg-light-blue padding5 width100 lh-1"
                                                                :class="{ marginTop10: idx != 1 }"
                                                            >
                                                                <p class="flexOnly questionStem">
                                                                    {{ idx }}.
                                                                    {{ question.stem }}
                                                                </p>

                                                                <template v-if="question.isRequired">
                                                                    <span class="visible-hidden">Required</span>
                                                                    <span class="important" aria-hidden="true">*</span>
                                                                </template>
                                                            </div>
                                                        </template>

                                                        <template v-else>
                                                            <label class="control-label fs-14px"> Requirements </label>
                                                            <p v-if="question.settings.shouldDistributePointsWithoutRepeatingValues" class="important">
                                                                Must distribute points without repeating values
                                                            </p>
                                                            <p v-if="question.settings.shouldDistributeAllPoints" class="important">
                                                                Must distribute <b>ALL</b> points
                                                            </p>
                                                            <p v-if="question.settings.shouldGiveOneTeammateAboveTenPoints" class="important">
                                                                Must give at least one teammate a point above
                                                                {{ question.settings.pointsAllocatedPerMember }}
                                                            </p>
                                                            <p v-if="question.settings.shouldGiveOneTeammateBelowTenPoints" class="important">
                                                                Must give at least one teammate a point below
                                                                {{ question.settings.pointsAllocatedPerMember }}
                                                            </p>
                                                            <p v-if="question.settings.allowToGiveThemselvesPoints" class="important">Give points to self</p>
                                                        </template>
                                                    </div>
                                                    <div class="moreLink col-xs-1 col-md-2">
                                                        <button aria-label="show more of " :aria-describedby="'questionStem_' + question.uuid">
                                                            <div class="custom-arrow-icon">
                                                                <div class="custom-arrow-icon-top"></div>
                                                                <div class="custom-arrow-icon-bottom"></div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="card-footer">
                                                    <button class="btn primary-text" @click.prevent="openShowMoreDetailModal(question)">Details</button>
                                                </div>

                                                <div class="cardBtns">
                                                    <button
                                                        v-if="isQuestionSelected(question)"
                                                        v-tooltip="{
                                                            content: 'Remove Selection',
                                                        }"
                                                        class="btn btn-danger"
                                                        aria-label="Delete"
                                                        @click.prevent="deleteFromSelectedQuestions(question)"
                                                    >
                                                        <i class="fa fa-times" aria-hidden="true" />
                                                    </button>
                                                    <button v-if="isQuestionSaved(question)" class="btn btn-danger" disabled aria-label="delete">
                                                        <i class="fa fa-times" aria-hidden="true" />
                                                    </button>
                                                    <button
                                                        v-if="!isQuestionSelected(question) && !isQuestionSaved(question)"
                                                        v-tooltip="{
                                                            content: 'Add Selection',
                                                        }"
                                                        class="btn btn-default"
                                                        aria-label="Add"
                                                        @click.prevent="addToSelectedQuestions(question)"
                                                    >
                                                        <i class="fa fa-plus" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <div v-else class="flex">
                                    <template v-for="question in selected_questions">
                                        <div class="cardCons-question-bank">
                                            <div class="card">
                                                <div class="card-header flex">
                                                    <div>
                                                        <i class="fa fa-list-ul marginRight8" />
                                                        <template v-if="question.type == 'ratingV2'"> Rating (Peer Evaluation Only) </template>
                                                        <template v-if="question.type == 'openendedV2'"> Open-ended (Peer Evaluation Only) </template>
                                                        <template v-if="question.type == 'pointDistribution'"> Point Distribution </template>
                                                    </div>
                                                </div>
                                                <div class="card-body" @click.prevent="openQuestionInfoModal(question)">
                                                    <div :id="'questionStem_' + question.uuid" class="question-area col-xs-11 col-md-10">
                                                        <template v-if="question.type == 'pointDistribution'">
                                                            <label class="control-label fs-14px"> Type </label>
                                                            <p class="capitalize marginBottom10">{{ question.settings.pointDistributionStyle }} Style</p>
                                                        </template>

                                                        <label class="control-label fs-14px"> Criteria </label>
                                                        <p class="marginBottom10">
                                                            <template v-if="fontResized == 'small'">
                                                                <kr-math :input="truncate(question.name, 160)" :safe="truncate(false, 160)" />
                                                            </template>

                                                            <template v-else-if="fontResized == 'normal'">
                                                                <kr-math :input="truncate(question.name, 80)" :safe="truncate(false, 80)" />
                                                            </template>

                                                            <template v-else-if="fontResized == 'large'">
                                                                <kr-math :input="truncate(question.name, 60)" :safe="truncate(false, 60)" />
                                                            </template>
                                                        </p>

                                                        <template v-if="['ratingV2', 'openendedV2'].includes(question.type)">
                                                            <label class="control-label fs-14px">
                                                                Question Stem(s) ({{ Object.keys(question.questionStems).length }})
                                                            </label>
                                                            <div
                                                                v-for="(question, idx) in question.questionStems"
                                                                class="inlineFlex bg-light-blue padding5 width100 lh-1"
                                                                :class="{ marginTop10: idx != 1 }"
                                                            >
                                                                <p class="flexOnly questionStem">
                                                                    {{ idx }}.
                                                                    {{ question.stem }}
                                                                </p>

                                                                <template v-if="question.isRequired">
                                                                    <span class="visible-hidden">Required</span>
                                                                    <span class="important" aria-hidden="true">*</span>
                                                                </template>
                                                            </div>
                                                        </template>

                                                        <template v-else>
                                                            <label class="control-label fs-14px"> Requirements </label>
                                                            <p v-if="question.settings.shouldDistributePointsWithoutRepeatingValues" class="important">
                                                                Must distribute points without repeating values
                                                            </p>
                                                            <p v-if="question.settings.shouldDistributeAllPoints" class="important">
                                                                Must distribute <b>ALL</b> points
                                                            </p>
                                                            <p v-if="question.settings.shouldGiveOneTeammateAboveTenPoints" class="important">
                                                                Must give at least one teammate a point above
                                                                {{ question.settings.pointsAllocatedPerMember }}
                                                            </p>
                                                            <p v-if="question.settings.shouldGiveOneTeammateBelowTenPoints" class="important">
                                                                Must give at least one teammate a point below
                                                                {{ question.settings.pointsAllocatedPerMember }}
                                                            </p>
                                                            <p v-if="question.settings.allowToGiveThemselvesPoints" class="important">Give points to self</p>
                                                        </template>
                                                    </div>
                                                    <div class="moreLink col-xs-1 col-md-2">
                                                        <button aria-label="show more of " :aria-describedby="'questionStem_' + question.uuid">
                                                            <div class="custom-arrow-icon">
                                                                <div class="custom-arrow-icon-top"></div>
                                                                <div class="custom-arrow-icon-bottom"></div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="card-footer">
                                                    <button class="btn primary-text" @click.prevent="openShowMoreDetailModal(question)">Details</button>
                                                </div>

                                                <div class="cardBtns">
                                                    <button
                                                        v-if="isQuestionSelected(question)"
                                                        v-tooltip="{
                                                            content: 'Remove Selection',
                                                        }"
                                                        class="btn btn-danger"
                                                        aria-label="Delete"
                                                        @click.prevent="deleteFromSelectedQuestions(question)"
                                                    >
                                                        <i class="fa fa-times" aria-hidden="true" />
                                                    </button>
                                                    <button v-if="isQuestionSaved(question)" class="btn btn-danger" disabled aria-label="Delete">
                                                        <i class="fa fa-times" aria-hidden="true" />
                                                    </button>
                                                    <button
                                                        v-if="!isQuestionSelected(question) && !isQuestionSaved(question)"
                                                        class="btn btn-default"
                                                        aria-label="Add"
                                                        @click.prevent="addToSelectedQuestions(question)"
                                                    >
                                                        <i class="fa fa-plus" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template v-if="!viewSelectedInQuestionBank" #pagination="props">
                                <div class="marginTop30 s flex align-items paddingLeft20 paddingBottom20">
                                    <div class="col-xs-12">
                                        <div class="displayNo">Displaying {{ props.model.from }} of {{ props.model.to }} Question/s</div>
                                    </div>
                                    <div class="col-xs-12 buttonGroupInline">
                                        <div v-if="props.model.meta.paginator.current_page > 2" class="width36px marginRight20">
                                            <button
                                                class="btn btn-default padding16px width100"
                                                :disabled="props.model.meta.paginator.current_page == 1 ? true : undefined"
                                                aria-label="Back"
                                                @click.prevent="pagination.page = 1"
                                            >
                                                <i class="fas fa-backward" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div v-if="props.model.meta.paginator.current_page > 1" class="width36px marginRight20">
                                            <button
                                                class="btn btn-default padding16px width100"
                                                :disabled="props.model.meta.paginator.current_page - 1 < 1 ? true : undefined"
                                                aria-label="Back"
                                                @click.prevent="pagination.page = props.model.meta.paginator.current_page - 1"
                                            >
                                                <i class="fa fa-caret-left" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <ul v-if="props.model.meta.paginator.current_page == 1" class="pagination text-listing-pagination marginLeft0">
                                            <template v-for="page in props.model.pagination">
                                                <li
                                                    v-if="page != '...'"
                                                    :class="{
                                                        active: page == props.model.meta.paginator.current_page,
                                                    }"
                                                >
                                                    <a :aria-label="page" @click.prevent="pagination.page = page">{{ page }}</a>
                                                </li>
                                                <li v-else>
                                                    <a :aria-label="page" @click.prevent="">{{ page }}</a>
                                                </li>
                                            </template>
                                        </ul>
                                        <ul v-if="props.model.meta.paginator.current_page > 1" class="pagination text-listing-pagination">
                                            <template v-for="page in props.model.pagination">
                                                <li
                                                    v-if="page != '...'"
                                                    :class="{
                                                        active: page == props.model.meta.paginator.current_page,
                                                    }"
                                                >
                                                    <a :aria-label="page" @click.prevent="pagination.page = page">{{ page }}</a>
                                                </li>
                                                <li v-else>
                                                    <a :aria-label="page" @click.prevent="">{{ page }}</a>
                                                </li>
                                            </template>
                                        </ul>
                                        <div v-if="props.model.meta.paginator.current_page > 1" class="width36px marginLeft20">
                                            <button
                                                class="btn btn-default padding16px width100"
                                                :disabled="
                                                    props.model.meta.paginator.current_page + 1 > props.model.meta.paginator.total_pages ? true : undefined
                                                "
                                                aria-label="Forward"
                                                @click.prevent="pagination.page = props.model.meta.paginator.current_page + 1"
                                            >
                                                <i class="fa fa-caret-right" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div v-if="props.model.meta.paginator.current_page > 2" class="width36px marginLeft20">
                                            <button
                                                class="btn btn-default padding16px width100"
                                                :disabled="props.model.meta.paginator.current_page == props.model.meta.paginator.total_pages ? true : undefined"
                                                aria-label="Forward"
                                                @click.prevent="pagination.page = props.model.meta.paginator.total_pages"
                                            >
                                                <i class="fas fa-forward" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>
        </div>
        <div v-if="addQuestionBankView" class="buttonGroupInline marginTop30">
            <button class="btn btn-default" aria-label="Back to Other Methods" @click.prevent="addQuestionBankView = false">Back to Other Methods</button>

            <button
                class="btn btn-success"
                :disabled="selected_questions.length == 0 ? true : undefined"
                :aria-label="'Add Selected Questions (' + selected_questions.length + ')'"
                @click.prevent="saveSelectedQuestions"
            >
                Add Selected Questions ({{ selected_questions.length }})
            </button>
        </div>
        <kr-panel v-if="!addQuestionBankView" :with-footer="false" :class="{ marginTop20: pageType != 'form' && manage }">
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>Questions For This Activity ({{ total_questions_count }})</h2>
                </div>
                <div v-if="!manage" class="col-xs-6 buttonGroup">
                    <div class="switchToggle flexOnly align-items">
                        <label for="detailedViewToggle-manage-new-A" class="control-label margin0 width105px"> Detailed View </label>
                        <label class="form-switch marginLeft8">
                            <input id="detailedViewToggle-manage-new-A" v-model="search_options.isDetailed" type="checkbox" />
                            <span role="switch" aria-checked="true" class="slider round" />
                        </label>
                    </div>
                    <div class="hidden-xs hidden-sm">
                        <router-link
                            v-if="['irat', 'trat', 'iratntrat', 'application'].includes(activity.type)"
                            :to="{
                                name: 'teachers.tests.preview.rat',
                                params: { id: activity.uuid },
                            }"
                            tag="a"
                            class="btn btn-primary"
                            style="line-height: 25px"
                            target="_blank"
                            aria-label="Preview as Student"
                        >
                            Preview as Student
                        </router-link>
                        <router-link
                            v-if="['evaluation', 'michaelsenEvaluation'].includes(activity.type)"
                            :to="{
                                name: 'teachers.tests.preview.evaluation',
                                params: { id: activity.uuid },
                            }"
                            tag="a"
                            class="btn btn-primary"
                            style="line-height: 25px"
                            target="_blank"
                            aria-label="Preview as Student"
                        >
                            Preview as Student
                        </router-link>
                    </div>
                </div>
                <div v-else-if="pageType != 'form' && manage" class="buttonGroup">
                    <div v-if="false" class="switchToggle flexOnly align-items marginRight20">
                        <label for="detailedViewToggle-manage-new-B" class="control-label margin0 width105px"> Detailed View </label>
                        <label class="form-switch marginLeft8">
                            <input id="detailedViewToggle-manage-new-B" v-model="search_options.isDetailed" type="checkbox" />
                            <span role="switch" aria-checked="true" class="slider round" />
                        </label>
                    </div>
                    <div class="hidden-xs hidden-sm">
                        <button
                            v-if="false"
                            class="btn btn-success"
                            :disabled="!canUpdate ? true : undefined"
                            aria-label="Update"
                            @click.prevent="saveManageQuestions()"
                        >
                            <i class="fa fa-check marginRight8" aria-hidden="true" />Update
                        </button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button
                            v-if="false"
                            class="btn btn-success"
                            :disabled="!canUpdate ? true : undefined"
                            aria-label="Update"
                            @click.prevent="saveManageQuestions()"
                        >
                            <i class="fa fa-check" aria-hidden="true" />
                        </button>
                    </div>
                </div>
                <div v-else class="buttonGroup">
                    <div v-if="false" class="switchToggle flexOnly align-items marginRight20">
                        <label for="detailedViewToggle-manage-new-C" class="control-label margin0 width105px"> Detailed View </label>
                        <label class="form-switch marginLeft8">
                            <input id="detailedViewToggle-manage-new-C" v-model="search_options.isDetailed" type="checkbox" />
                            <span role="switch" aria-checked="true" class="slider round" />
                        </label>
                    </div>
                </div>
            </template>
            <template #content>
                <template v-if="questionGroups.length == 0">
                    <div class="dropdown">
                        <button class="btn padding0 btn-link br-0 fw-normal" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Create questions here
                        </button>
                        <div class="dropdown-menu dropdown-menu-new dropdown-menu-max-content hidden-xs hidden-sm" aria-labelledby="dropdownMenuButton">
                            <div class="flex padding8">
                                <div class="border-right-table-column-outline padding8">
                                    <label class="control-label">Add From Library</label>
                                    <ul>
                                        <li class="padding0">
                                            <button
                                                class="btn justifyLeft fw-normal"
                                                @click.prevent="
                                                    addQuestionBankView = true;
                                                    viewSelectedInQuestionBank = false;
                                                "
                                            >
                                                <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> list_alt </span>
                                                Question(s) Bank
                                            </button>
                                        </li>
                                        <!-- <li class="padding0">
                                            <button
                                                class="btn justifyLeft fw-normal"
                                                @click.prevent="
                                                    addQuestionBankView = true;
                                                    viewSelectedInQuestionBank = false;
                                                "
                                            >
                                                <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> quiz </span>
                                                Add Question(s)
                                            </button>
                                        </li> -->
                                    </ul>
                                </div>
                                <div class="padding8">
                                    <label class="control-label">Create Questions</label>
                                    <ul>
                                        <li class="padding0">
                                            <button class="btn justifyLeft fw-normal" @click.prevent="createQuestionForm('openendedV2')">
                                                <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> format_align_left </span>
                                                Open-ended
                                            </button>
                                        </li>
                                        <li v-if="activityObj.evaluationSettings.target != 'self'" class="padding0">
                                            <button class="btn justifyLeft fw-normal" @click.prevent="createQuestionForm('pointDistribution')">
                                                <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> tune </span>
                                                Point Distribution
                                            </button>
                                        </li>
                                        <li class="padding0">
                                            <button class="btn justifyLeft fw-normal" @click.prevent="createQuestionForm('ratingV2')">
                                                <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> star </span>
                                                Rating
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="dropdown-menu dropdown-menu-new hidden-md hidden-lg" aria-labelledby="dropdownMenuButton">
                            <div>
                                <div class="padding8">
                                    <label class="control-label">Add From Library</label>
                                    <ul>
                                        <li class="padding0">
                                            <button
                                                class="btn justifyLeft fw-normal"
                                                @click.prevent="
                                                    addQuestionBankView = true;
                                                    viewSelectedInQuestionBank = false;
                                                "
                                            >
                                                <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> list_alt </span>
                                                Question(s) Bank
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="padding8">
                                    <label class="control-label">Create Questions</label>
                                    <ul>
                                        <li class="padding0">
                                            <button class="btn justifyLeft fw-normal" @click.prevent="createQuestionForm('openendedV2')">
                                                <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> format_align_left </span>
                                                Open-ended
                                            </button>
                                        </li>
                                        <li v-if="activityObj.evaluationSettings.target != 'self'" class="padding0">
                                            <button class="btn justifyLeft fw-normal" @click.prevent="createQuestionForm('pointDistribution')">
                                                <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> tune </span>
                                                Point Distribution
                                            </button>
                                        </li>
                                        <li class="padding0">
                                            <button class="btn justifyLeft fw-normal" @click.prevent="createQuestionForm('ratingV2')">
                                                <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> star </span>
                                                Rating
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="two-column-grid-activity-setup">
                        <div class="padding0">
                            <div class="dropdown marginBottom20 hidden-sm hidden-md hidden-lg">
                                <button class="btn btn-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-plus marginRight8" aria-label="Add" /> Question
                                </button>
                                <div class="dropdown-menu dropdown-menu-new dropdown-menu-max-content" aria-labelledby="dropdownMenuButton">
                                    <div>
                                        <div class="padding8">
                                            <label class="control-label">Add From Library</label>
                                            <ul>
                                                <li class="padding0">
                                                    <button
                                                        class="btn justifyLeft fw-normal"
                                                        @click.prevent="
                                                            addQuestionBankView = true;
                                                            viewSelectedInQuestionBank = false;
                                                        "
                                                    >
                                                        <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> list_alt </span>
                                                        Question(s) Bank
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="padding8">
                                            <label class="control-label">Create Questions</label>
                                            <ul>
                                                <li class="padding0">
                                                    <button class="btn justifyLeft fw-normal" @click.prevent="createQuestionForm('openendedV2')">
                                                        <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> format_align_left </span>
                                                        Open-ended
                                                    </button>
                                                </li>
                                                <li v-if="activityObj.evaluationSettings.target != 'self'" class="padding0">
                                                    <button class="btn justifyLeft fw-normal" @click.prevent="createQuestionForm('pointDistribution')">
                                                        <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> tune </span>
                                                        Point Distribution
                                                    </button>
                                                </li>
                                                <li class="padding0">
                                                    <button class="btn justifyLeft fw-normal" @click.prevent="createQuestionForm('ratingV2')">
                                                        <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> star </span>
                                                        Rating
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <sidebar class="manage-question-sidebar hidden-xs">
                                <kr-panel>
                                    <template #title>
                                        <div class="width100 flexSpaceBetween align-items">
                                            <h3>Table of Contents</h3>
                                            <div class="dropdown">
                                                <button
                                                    class="btn btn-primary hidden-xs hidden-sm"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i class="fa fa-plus marginRight8" aria-label="Add" /> Question
                                                </button>
                                                <button
                                                    class="btn btn-primary hidden-md hidden-lg"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i class="fa fa-plus" aria-label="Add" />
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-new dropdown-menu-max-content" aria-labelledby="dropdownMenuButton">
                                                    <div class="flex padding8">
                                                        <div class="border-right-table-column-outline padding8">
                                                            <label class="control-label">Add From Library</label>
                                                            <ul>
                                                                <li class="padding0">
                                                                    <button
                                                                        class="btn justifyLeft fw-normal"
                                                                        @click.prevent="
                                                                            addQuestionBankView = true;
                                                                            viewSelectedInQuestionBank = false;
                                                                        "
                                                                    >
                                                                        <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> list_alt </span>
                                                                        Question(s) Bank
                                                                    </button>
                                                                </li>
                                                                <!-- <li class="padding0">
                                                                    <button
                                                                        class="btn justifyLeft fw-normal"
                                                                        @click.prevent="
                                                                            addQuestionBankView = true;
                                                                            viewSelectedInQuestionBank = false;
                                                                        "
                                                                    >
                                                                        <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> quiz </span>
                                                                        Add Question(s)
                                                                    </button>
                                                                </li> -->
                                                            </ul>
                                                        </div>
                                                        <div class="padding8">
                                                            <label class="control-label">Create Questions</label>
                                                            <ul>
                                                                <li class="padding0">
                                                                    <button
                                                                        class="btn justifyLeft fw-normal"
                                                                        @click.prevent="createQuestionForm('openendedV2')"
                                                                    >
                                                                        <span class="material-icons marginRight8 fontBlack" aria-hidden="true">
                                                                            format_align_left
                                                                        </span>
                                                                        Open-ended
                                                                    </button>
                                                                </li>
                                                                <li v-if="activityObj.evaluationSettings.target != 'self'" class="padding0">
                                                                    <button
                                                                        class="btn justifyLeft fw-normal"
                                                                        @click.prevent="createQuestionForm('pointDistribution')"
                                                                    >
                                                                        <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> tune </span>
                                                                        Point Distribution
                                                                    </button>
                                                                </li>
                                                                <li class="padding0">
                                                                    <button class="btn justifyLeft fw-normal" @click.prevent="createQuestionForm('ratingV2')">
                                                                        <span class="material-icons marginRight8 fontBlack" aria-hidden="true"> star </span>
                                                                        Rating
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template #content>
                                        <div class="sidebar-sub-header">
                                            <div class="checkboxField flexOnly alignFlexStart justifyCenter">
                                                <input
                                                    id="selectAllSidebarQuestions"
                                                    v-model="selectAllSidebarQuestions"
                                                    type="checkbox"
                                                    @change="toggleSidebarQuestions"
                                                />
                                                <label for="selectAllSidebarQuestions"> Select All </label>
                                            </div>
                                            <div>
                                                <button
                                                    class="btn color-danger"
                                                    aria-label="Remove Questions"
                                                    :disabled="selectedSidebarQuestions.length == 0"
                                                    @click.prevent="openDeleteSelectedSidebarQnsModal(selectedSidebarQuestions)"
                                                >
                                                    <i class="fa fa-trash-o" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>

                                        <div class="sidebar-content" @scroll="handleScroll">
                                            <div v-for="(question, idx) in activity.questions" class="card-new" :class="{ marginTop20: idx != 0 }">
                                                <div class="card-new-header" :class="{ border0: question[0].type == 'pointDistribution' }">
                                                    <div class="dropdown" @click="toggleDropdown">
                                                        <button
                                                            class="btn padding0 marginRight8 dropdown-toggle"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            aria-label="Actions"
                                                        >
                                                            <i class="fa-solid fa-grip-vertical" aria-hidden="true"></i>
                                                        </button>

                                                        <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                                            <ul>
                                                                <li>
                                                                    <a
                                                                        class="primary-state"
                                                                        href="#"
                                                                        @click.prevent="editQuestion(question[0], question[0].type)"
                                                                    >
                                                                        <span> <i class="fa fa-edit marginRight8" aria-hidden="true" /> Edit Question </span>
                                                                    </a>
                                                                </li>
                                                                <hr />
                                                                <li>
                                                                    <a class="danger-state" href="#" @click.prevent="openDeleteQnsModal(idx, question[0])">
                                                                        <span> <i class="fa fa-trash marginRight8" aria-hidden="true" /> Delete Question </span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div class="checkboxField">
                                                        <input
                                                            :id="'Criteria_' + question[0].uuid"
                                                            v-model="selectedSidebarQuestions"
                                                            type="checkbox"
                                                            :name="question[0].uuid"
                                                            class="checkbox-field"
                                                            :value="question[0].uuid"
                                                        />
                                                        <label :for="'Criteria_' + question[0].uuid">
                                                            {{ toRoman(idx + 1) }}.
                                                            {{ question[0].name }}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div v-for="(questionStem, question_idx) in question[0].questionStems" class="card-new-content">
                                                    <span class="material-icons marginRight8" aria-hidden="true">
                                                        <template v-if="question[0].type == 'ratingV2'"> star </template>
                                                        <template v-if="question[0].type == 'openendedV2'"> format_align_left </template>
                                                        <template v-if="question[0].type == 'pointDistribution'"> tune </template>
                                                    </span>
                                                    <label class="fw-normal">
                                                        <template v-if="['pointDistribution'].includes(question[0].type)">
                                                            {{ question_idx }}.
                                                            <template v-if="question[0].settings.pointDistributionStyle == 'michaelsen'"> Michaelsen </template>
                                                            <template v-else> Fink </template>
                                                            Question
                                                        </template>
                                                        <template v-else> {{ question_idx }}. {{ questionStem.stem }} </template>
                                                        <template v-if="questionStem.isRequired">
                                                            <span class="visible-hidden">Required</span>
                                                            <span class="important" aria-hidden="true">*</span>
                                                        </template>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </kr-panel>
                            </sidebar>
                        </div>

                        <div class="paddingTop0 paddingRight0">
                            <div v-for="(question, idx) in activity.questions" class="accordion-item-primary" :class="{ marginTop20: idx != 0 }">
                                <div :id="question[0].uuid" class="accordion-header">
                                    <div class="flexOnly">
                                        <div class="dropdown">
                                            <button
                                                class="btn padding0 marginRight8"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                aria-label="Actions"
                                            >
                                                <i class="fa-solid fa-grip-vertical" aria-hidden="true"></i>
                                            </button>

                                            <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                                <ul>
                                                    <li>
                                                        <a class="primary-state" href="#" @click.prevent="editQuestion(question[0], question[0].type)">
                                                            <span> <i class="fa fa-edit marginRight8" aria-hidden="true" /> Edit Question </span>
                                                        </a>
                                                    </li>
                                                    <hr />
                                                    <li>
                                                        <a class="danger-state" href="#" @click.prevent="openDeleteQnsModal(idx, question[0])">
                                                            <span> <i class="fa fa-trash marginRight8" aria-hidden="true" /> Delete Question </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div>
                                            <h3 class="margin0 whiteText fs-18px paddingTop5">
                                                <b> {{ toRoman(idx + 1) }}. {{ question[0].name }} </b>
                                            </h3>
                                        </div>
                                    </div>

                                    <p v-if="question[0].description" class="accordion-header-description">
                                        <kr-math :input="question[0].description" :safe="!question[0].questionIsHTML" />
                                    </p>
                                </div>
                                <div :id="'collapse-' + question[0].uuid" class="collapse show accordion-content" :aria-labelledby="question[0].uuid">
                                    <div>
                                        <template v-if="question[0].type != 'pointDistribution'">
                                            <div
                                                v-for="(questionStem, question_idx) in question[0].questionStems"
                                                class="question-stem-card-container"
                                                :class="{ marginTop20: question_idx > 1 }"
                                            >
                                                <div class="question-stem-card-header">
                                                    <span class="material-icons marginRight8" aria-hidden="true">
                                                        <template v-if="question[0].type == 'ratingV2'"> star </template>
                                                        <template v-if="question[0].type == 'openendedV2'"> format_align_left </template>
                                                    </span>
                                                    <label class="fw-normal">
                                                        {{ question_idx }}. {{ questionStem.stem }}
                                                        <template v-if="questionStem.isRequired">
                                                            <span class="visible-hidden">Required</span>
                                                            <span class="important" aria-hidden="true">*</span>
                                                        </template>
                                                    </label>
                                                </div>
                                                <div v-if="question[0].type == 'ratingV2'" class="question-stem-card-content">
                                                    <div class="rating-flex-layout">
                                                        <template v-for="(rating, ratingIdx) in question[0].ratingSettings">
                                                            <div class="newRadioField rating-scale-wrapper">
                                                                <div class="radioField">
                                                                    <input id="radio-label" type="radio" name="ratingV2" disabled />
                                                                </div>
                                                                <label for="radio-label" class="rating-label"> {{ rating.weight }} </label>
                                                                <label> {{ rating.label }} </label>
                                                                <p>
                                                                    {{ rating.rubric }}
                                                                </p>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="question-stem-card-container">
                                                <div class="question-stem-card-header">
                                                    <span class="material-icons marginRight8" aria-hidden="true"> tune </span>
                                                    <label class="fw-normal">
                                                        1.
                                                        <template v-if="question[0].settings.pointDistributionStyle == 'michaelsen'"> Michaelsen </template>
                                                        <template v-else> Fink </template>
                                                        Question
                                                        <template v-if="question[0].isRequired">
                                                            <span class="visible-hidden">Required</span>
                                                            <span class="important" aria-hidden="true">*</span>
                                                        </template>
                                                    </label>
                                                </div>
                                                <div class="question-stem-card-content">
                                                    <div>
                                                        <label>Requirements</label>
                                                        <p v-if="question[0].settings.shouldDistributePointsWithoutRepeatingValues" class="important">
                                                            Must distribute points without repeating values
                                                        </p>
                                                        <p v-if="question[0].settings.shouldDistributeAllPoints" class="important">
                                                            Must distribute <b>ALL</b> points
                                                        </p>
                                                        <p v-if="question[0].settings.shouldGiveOneTeammateAboveTenPoints" class="important">
                                                            Must give at least one teammate a point above {{ question[0].settings.pointsAllocatedPerMember }}
                                                        </p>
                                                        <p v-if="question[0].settings.shouldGiveOneTeammateBelowTenPoints" class="important">
                                                            Must give at least one teammate a point below {{ question[0].settings.pointsAllocatedPerMember }}
                                                        </p>
                                                        <p v-if="question[0].settings.allowToGiveThemselvesPoints" class="important">Give points to self</p>
                                                    </div>
                                                    <div class="col-xs-12 col-md-6">
                                                        <div v-for="details in defaultSliderData" class="marginTop20">
                                                            <div class="flexSpaceBetween">
                                                                <div class="flexOnly align-items">
                                                                    <div class="profile_image">
                                                                        <div class="badge_profile img-circle">
                                                                            <span>
                                                                                {{ details.initial }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <p class="marginLeft10">
                                                                        {{ details.name }}
                                                                    </p>
                                                                </div>

                                                                <b class="color-purple fs-20px">
                                                                    {{ details.point }}
                                                                </b>
                                                            </div>

                                                            <cl-slider
                                                                v-model.number="details.point"
                                                                :show-number-input="false"
                                                                :is-range-only="false"
                                                                :set-max="'50'"
                                                                :is-disabled="true"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </kr-panel>
        <template v-if="pageType == 'form'">
            <div v-if="!addQuestionBankView" class="marginTop10">
                <button class="btn btn-success" :disabled="total_questions_count == 0" @click.prevent="saveManageQuestions()">
                    Go to Optional Settings
                    <i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
                </button>
                <button class="btn btn-default" aria-label="Save and Exit" @click.prevent="saveAndExit">
                    <i class="fa fa-floppy-o marginRight8" aria-hidden="true" />Save and Exit
                </button>
            </div>
        </template>
        <template v-if="pageType == 'form' || (pageType == 'confirmation' && manage)">
            <question-cru-new
                ref="peerForm"
                :options="{ target: target, courseUuid: activity.course.uuid }"
                @updated="syncUpdatedQuestion"
                @created="syncCreatedQuestion"
            />
        </template>

        <div class="modal danger-modal deletePageModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="deletePageModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="deletePageModal-title" class="modal-title">Delete Page</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" />

                        <h2 class="margin0 marginTop40">You are about to delete</h2>
                        <h2 class="marginBottom20 fw-normal">Page {{ questionGroupToDeleteIdx }}</h2>

                        <div>Do you want to proceed?</div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="deleteQuestionGroup()">
                            <i class="fa fa-trash marginRight8" aria-hidden="true" />Yes, Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal danger-modal deleteQnsModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="deleteQnsModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="deleteQnsModal-title" class="modal-title">Remove Question</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" />

                        <h2 class="margin0 marginTop40">You are about to remove</h2>
                        <br />

                        <div class="bg-grey padding15 textAlignLeft">
                            <div class="inlineFlex align-items">
                                <i class="fa fa-list-ul marginRight8" aria-hidden="true" />
                                <template v-if="previewQuestion.type == 'openendedV2'"> Open-ended </template>
                                <template v-else-if="previewQuestion.type == 'pointDistribution'"> Point Distribution </template>
                                <template v-else-if="previewQuestion.type == 'ratingV2'"> Rating </template>
                            </div>
                            <br />
                            <question-display-new :question="previewQuestion" />
                        </div>

                        <br />
                        <p>
                            <b> Do you want to proceed? </b>
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="deleteQuestion(questionGroupIdx, questionIdx)">
                            <i class="fa fa-trash marginRight8" aria-hidden="true" />Yes, Remove
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal danger-modal deleteSelectedSidebarQnsModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="deleteQnsModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>
                        <h2 id="deleteQnsModal-title" class="modal-title">Remove Questions</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt="" />

                        <h2 class="margin0 marginTop40">You are about to remove</h2>
                        <br />

                        <div v-for="(question, index) in arrayOfSelectedQuestions" class="bg-grey padding15 textAlignLeft" :class="{ marginTop20: index != 0 }">
                            <div class="inlineFlex align-items">
                                <i class="fa fa-list-ul marginRight8" aria-hidden="true" />
                                <template v-if="question.type == 'openendedV2'"> Open-ended </template>
                                <template v-else-if="question.type == 'pointDistribution'"> Point Distribution </template>
                                <template v-else-if="question.type == 'ratingV2'"> Rating </template>
                            </div>

                            <br />

                            <div>
                                <label id="criteriaName" class="control-label marginTop20"> Criteria </label>
                                <p aria-labelledby="criteriaName">
                                    {{ question.name }}
                                </p>

                                <template v-if="question.description">
                                    <label id="criteriaDescription" class="control-label marginTop20"> Description </label>
                                    <p>
                                        <kr-math :input="question.description" :safe="!question.questionIsHTML" class="whiteSpaceBreakSpacesText" />
                                    </p>
                                </template>
                            </div>
                        </div>

                        <br />
                        <p>
                            <b> Do you want to proceed? </b>
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="deleteSidebarSelectedQuestions()">
                            <i class="fa fa-trash marginRight8" aria-hidden="true" />Yes, Remove
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal default-modal questionInfoModal" tabindex="-1" role="dialog" aria-labelledby="questionInfoModal-title">
            <div
                class="modal-dialog"
                :class="{
                    'maxWidth100 widthAuto paddingLeft20 paddingRight20': ['ratingV2'].includes(previewQuestion.type) && !isMobileView,
                }"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="questionInfoModal-title" class="modal-title">View Question</h2>
                    </div>

                    <div class="modal-body height550px">
                        <question-display v-if="!['openendedV2', 'ratingV2', 'pointDistribution'].includes(previewQuestion.type)" :question="previewQuestion" />
                        <question-display-new v-else :question="previewQuestion" />
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="copyQuestionModal"
            class="modal default-modal copyQuestionModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="copyQuestionModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="copyQuestionModal-title" class="modal-title">Copy Question</h2>
                    </div>
                    <div class="modal-body">
                        <h2 class="margin0 marginTop20 textAlignCenter">You are making a copy of this question</h2>
                        <br />

                        <div class="bg-grey padding15">
                            <div class="inlineFlex align-items">
                                <i class="fa fa-list-ul marginRight8" aria-hidden="true" />
                                <template v-if="previewQuestion.type == 'openendedV2'"> Open-ended </template>
                                <template v-else-if="previewQuestion.type == 'pointDistribution'"> Point Distribution </template>
                                <template v-else-if="previewQuestion.type == 'ratingV2'"> Rating </template>
                            </div>
                            <br />
                            <label class="control-label marginTop20"> Question Stem </label>
                            <question-display :question="previewQuestion" />
                        </div>

                        <br />
                        <p class="textAlignCenter">
                            <b>Continue?</b>
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click="makeACopy(previewQuestion.uuid)">Make a Copy</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="showMoreDetailModal"
            class="modal default-modal showMoreDetailModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="showMoreDetailModal-title"
        >
            <div class="modal-dialog minWidth350px width350px maxWidth350px">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="showMoreDetailModal-title" class="modal-title">Details</h2>
                    </div>
                    <div class="modal-body">
                        <div class="marginBottom20">
                            <label class="control-label"> Created By </label>
                            <p class="questionBankModalText">
                                {{ previewQuestion.createBy }}
                            </p>
                        </div>

                        <div class="marginBottom20">
                            <label class="control-label"> Difficulty Level </label>
                            <p class="questionBankModalText">Level {{ previewQuestion.difficultyLevel }}</p>
                        </div>

                        <div>
                            <label class="control-label"> Topics </label>

                            <template v-if="topicsLength == 0">
                                <p class="questionBankModalText">No Topic</p>
                            </template>
                            <ul v-else>
                                <li v-for="topic in previewQuestion.topics" class="questionBankModalText">
                                    {{ topic }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../../../components/forms/form';
import KrAuth from '../../../../../../components/auth/auth';
export default {
    props: ['activityObj', 'options'],
    data() {
        var auth = new KrAuth();
        var type = 'ratingV2,openendedV2,pointDistribution';
        let add_methods = ['qbank', 'form', 'copyandpaste', 'upload'];
        return {
            component_done_loading: false,
            auth: auth,
            manage: true,
            activity: null,
            addQuestionBankView: false,
            viewSelectedInQuestionBank: false,
            debounced_search: null,
            questionSearchData: {
                meta: {
                    paginator: { total: 0 },
                },
            },
            pagination: { page: 1 },
            query_fields: {
                q: '',
                creatorUuid: '',
                difficultyLevel: '',
                topics: '',
                type: type,
            },
            //selected questions from question bank
            selected_questions: [],
            //deleted questions
            deleted_questions: [],
            //all questions on activity
            questionGroups: [],
            currentDraggedItem: null,
            //for view question
            previewQuestion: {},
            pageType: _.get(this.options, 'pageType', 'form'),
            allowedQuestionTypes: type,
            oldQuestionGroups: [],
            questionGroupToDeleteIdx: null,
            search_options: { isDetailed: true },
            add_methods: add_methods,
            target: _.get(this.options, 'target', null),
            id: _.get(this.options, 'target', Math.random().toString(13).replace('0.', '')),
            stopScroll: true,
            selectedAll: false,
            filters: {
                types: [],
                creators: [],
                topics: [],
                difficultyLevels: [],
            },
            topicsLength: 0,
            fontResized: localStorage.getItem('textSize'),
            selectAllSidebarQuestions: false,
            selectedSidebarQuestions: [],
            arrayOfSelectedQuestions: [],
        };
    },
    computed: {
        total_questions_count() {
            let count = 0;
            for (var i = 0; i < this.questionGroups.length; i++) {
                let qGroup = this.questionGroups[i];
                count += qGroup.length;
            }
            return count;
        },
        canUpdate() {
            if (!_.isEqual(this.questionGroups, this.oldQuestionGroups) && this.total_questions_count != 0) {
                return true;
            }
            return false;
        },
        goToNextPage() {
            if (['evaluation', 'michaelsenEvaluation'].includes(this.activityObj.type)) {
                if (this.target == 'peer') {
                    if (this.activity.evaluationSettings.evaluateTeam) {
                        return 'Go to Team Questions';
                    } else if (this.activity.evaluationSettings.evaluateInstructor) {
                        return 'Go to Instructor Questions';
                    } else {
                        return 'Go to Optional Settings';
                    }
                } else if (this.target == 'team') {
                    if (this.activity.evaluationSettings.evaluateInstructor) {
                        return 'Go to Instructor Questions';
                    } else {
                        return 'Go to Optional Settings';
                    }
                } else if (this.target == 'instructor') {
                    return 'Go to Optional Settings';
                }
            }

            if (!(this.activityObj.type == 'application' && !this.activity.others.isApplicationGraded)) {
                return 'Go to Scoring';
            }

            return 'Go to Optional Settings';
        },
        creatorName() {
            if (this.query_fields.creatorUuid) {
                const filter = this.filters.creators.filter((creator) => creator.uuid == this.query_fields.creatorUuid);
                return filter[0].name;
            }
            return false;
        },
    },
    created() {
        var that = this;
        //this.activityObj = false;

        if (!this.activityObj) {
            this.fetchActivity().then(function (response) {
                that.activity = response.data.data;
                that.$emit('activity-updated', that.activity);
                that.init(true);
                this.getFilters();
                that.component_done_loading = true;
            });
        } else {
            this.activity = this.activityObj;
            that.init(true);
            this.getFilters();
            this.component_done_loading = true;
        }

        this.$root.$on('fontResized', (newTextSize) => {
            this.fontResized = newTextSize;
        });
    },
    methods: {
        toggledRandomize(event) {
            if (event.target.checked) {
                this.query_fields.sort = 'random';
                this.query_fields.order = 'asc';
            } else {
                this.query_fields.sort = 'id';
                this.query_fields.order = 'desc';
            }
            this.search();
        },
        getFilters() {
            var that = this;
            axios
                .get('/questions/bank/filters?type=peerEvaluation')
                .then(function (response) {
                    that.filters = response.data;
                    // if (that.filters.types.length > 0) {
                    //     that.query_fields.type = that.filters.types[0];
                    // }
                    if (
                        _.findIndex(that.filters.creators, function (o) {
                            return o.uuid == that.auth.user().uuid;
                        }) == -1
                    ) {
                        that.filters.creators.push({
                            uuid: that.auth.user().uuid,
                            name: that.auth.user().name,
                        });
                    }
                })
                .catch(function (errors) {});
        },
        isPreviewStudentEnabledForEvaluation() {
            if (this.target == 'peer') {
                if (this.total_questions_count == 0) {
                    return false;
                } else {
                    return true;
                }
            }
            if (this.target == 'team') {
                if (
                    this.total_questions_count == 0 &&
                    !(_.get(this.activityObj, 'evaluationSettings.evaluateTeammates') || _.get(this.activityObj, 'evaluationSettings.evaluateThemselves'))
                ) {
                    return false;
                } else {
                    return true;
                }
            }
            if (this.target == 'instructor') {
                if (
                    this.total_questions_count == 0 &&
                    !(
                        _.get(this.activityObj, 'evaluationSettings.evaluateTeammates') ||
                        _.get(this.activityObj, 'evaluationSettings.evaluateThemselves') ||
                        _.get(this.activityObj, 'evaluationSettings.evaluateTeam')
                    )
                ) {
                    return false;
                } else {
                    return true;
                }
            }

            return true;
        },
        dragFunction(e) {
            var that = this;
            var scroll = function (step) {
                var scrollY = $(window).scrollTop();
                $(window).scrollTop(scrollY + step);
                if (!that.stopScroll) {
                    setTimeout(function () {
                        scroll(step);
                    }, 20);
                }
            };
            this.stopScroll = true;
            if (e.clientY < 150) {
                this.stopScroll = false;
                scroll(-1);
            }

            if (e.clientY > $(window).height() - 150) {
                this.stopScroll = false;
                scroll(1);
            }
        },
        downloadQuestions() {
            var that = this;
            let d = moment().format('YYYY[_]MM[_]DD');
            var url = '/activities/' + this.activityObj.uuid + '/questions/activity-questions/export';
            if (this.target) {
                url += '?type=' + this.target;
            }

            axios({
                url: url,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '(' + that.activityObj.type + ')' + that.activityObj.name + '_Questions_' + d + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        openDeletePageModal(idx) {
            this.questionGroupToDeleteIdx = idx;
            $('#' + this.id + ' .deletePageModal').modal('show');
        },
        openDeleteQnsModal(idx, question) {
            this.questionGroupIdx = idx;
            this.previewQuestion = question;
            $('.deleteQnsModal').modal('show');
        },
        openDeleteSelectedSidebarQnsModal(array) {
            array.forEach((value) => {
                const index = this.activity.questions.findIndex((obj) => obj[0].uuid === value);

                this.arrayOfSelectedQuestions.push(this.activity.questions[index][0]);
            });
            $('.deleteSelectedSidebarQnsModal').modal('show');
        },
        saveAndExit() {
            this.saveManageQuestions(true);
        },
        search() {
            Events.fire('questions_listing_refresh');
        },
        selectAllQuestions() {
            let questions = _.get(this.questionSearchData, 'data.data.data', []);

            this.selectedAll = !this.selectedAll;

            if (this.selectedAll) {
                for (var i = 0; i < questions.length; i++) {
                    this.addToSelectedQuestions(questions[i]);
                }
            } else {
                for (var i = 0; i < questions.length; i++) {
                    this.deleteFromSelectedQuestions(questions[i]);
                }
            }
        },
        syncUpdatedQuestion(data) {
            let question = data;
            for (var i = 0; i < this.questionGroups.length; i++) {
                let qGroup = this.questionGroups[i];
                for (var j = 0; j < qGroup.length; j++) {
                    if (question.uuid == qGroup[j].uuid) {
                        this.questionGroups[i][j] = question;
                        this.questionGroups[i][j].group = i + 1;
                        this.questionGroups[i][j]._tempGroup = i + 1;
                        this.questionGroups[i][j].order = j + 1;
                        this.questionGroups.splice();
                        this.openQuestionInfoModal(this.questionGroups[i][j]);
                        return;
                    }
                }
            }
        },
        syncCreatedQuestion(data) {
            var that = this;
            this.fetchActivity().then(function (response) {
                that.activity = response.data.data;
                that.$emit('activity-updated', that.activity);
                that.init();
                that.saveManageQuestions(false, false);
            });
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question;
            $('#' + this.id + ' .questionInfoModal').modal('show');
        },
        moveQuestionOrder(oldQGroupIdx, newQGroupIdx, old_question_idx, new_question_idx) {
            let old_question = this.questionGroups[oldQGroupIdx][old_question_idx];
            if (oldQGroupIdx == newQGroupIdx) {
                //console.log('same route!from:'+old_question_idx+'to:'+new_question_idx);
                if (old_question_idx == new_question_idx) {
                    return;
                }
                //question on same page
                this.array_move(this.questionGroups[newQGroupIdx], old_question_idx, new_question_idx);
                for (var i = 0; i < this.questionGroups[newQGroupIdx].length; i++) {
                    this.questionGroups[newQGroupIdx][i].order = i + 1;
                }
            } else {
                this.questionGroups[oldQGroupIdx].splice(old_question_idx, 1);
                this.questionGroups[newQGroupIdx].splice(new_question_idx, 0, old_question);
                for (var i = 0; i < this.questionGroups[oldQGroupIdx].length; i++) {
                    this.questionGroups[oldQGroupIdx][i]._tempGroup = oldQGroupIdx + 1;
                    this.questionGroups[oldQGroupIdx][i].group = oldQGroupIdx + 1;
                    this.questionGroups[oldQGroupIdx][i].order = i + 1;
                }
                for (var i = 0; i < this.questionGroups[newQGroupIdx].length; i++) {
                    this.questionGroups[newQGroupIdx][i]._tempGroup = newQGroupIdx + 1;
                    this.questionGroups[newQGroupIdx][i].group = newQGroupIdx + 1;
                    this.questionGroups[newQGroupIdx][i].order = i + 1;
                }
            }
            this.questionGroups[oldQGroupIdx].splice();
        },
        array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing
        },

        leaveDragFromQuestion(event) {
            var target = $(event.target).closest('.managementQns')[0];
            if ($(this.currentDraggedItem).hasClass('managementQns')) {
                $(target).removeClass('selected-bottom');
                $(target).removeClass('selected-top');
            }
        },
        dropToQuestion(event, qGroupIdx, question_idx) {
            if (event.preventDefault) {
                event.preventDefault();
            }
            if (event.stopPropagation) {
                event.stopPropagation();
            }
            //alert(JSON.stringify(event));
            var data = JSON.parse(event.dataTransfer.getData('text'));
            if (data.from == 'questionGroupQuestion') {
                var target = $(event.target).closest('.managementQns')[0];
                let bias = 0;
                if ($(target).hasClass('selected-bottom') && data.question.group - 1 != qGroupIdx) {
                    bias = 1;
                }
                $(target).removeClass('selected-bottom');
                $(target).removeClass('selected-top');
                this.moveQuestionOrder(data.question.group - 1, qGroupIdx, data.question.order - 1, question_idx + bias);
            }
            //clear draggedItem
            this.saveManageQuestions(false, false);
            this.currentDraggedItem = null;
        },
        allowDropToQuestion(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
            //window.requestAnimationFrame(function(){
            //qgroup to qgroup sorting.
            if ($(this.currentDraggedItem).hasClass('managementQns')) {
                var target = $(event.target).closest('.managementQns')[0];
                if (!target.isSameNode(this.currentDraggedItem)) {
                    var bounding = target.getBoundingClientRect();
                    var offset = bounding.y + bounding.height / 2;
                    if (event.clientY - offset > 0) {
                        $(target).removeClass('selected-top');
                        $(target).addClass('selected-bottom');
                    } else {
                        $(target).removeClass('selected-bottom');
                        $(target).addClass('selected-top');
                    }
                }
            }

            var that = this;
            var scroll = function (step) {
                var scrollY = $(window).scrollTop();
                $(window).scrollTop(scrollY + step);
                if (!that.stopScroll) {
                    setTimeout(function () {
                        scroll(step);
                    }, 20);
                }
            };
            this.stopScroll = true;
            if (event.clientY < 150) {
                this.stopScroll = false;
                scroll(-1);
            }

            if (event.clientY > $(window).height() - 150) {
                this.stopScroll = false;
                scroll(1);
            }
        },
        dragQuestion(event, model, options) {
            //console.log(event);
            event.dataTransfer.setData('text', JSON.stringify({ question: model, from: options.from }));
            event.dataTransfer.dropEffect = 'move';
            this.currentDraggedItem = $(event.target).closest('.managementQns')[0];
        },
        //Dragging question group to question group
        dropToQuestionGroup(event, qGroupIdx) {
            var that = this;
            if (event.preventDefault) {
                event.preventDefault();
            }
            if (event.stopPropagation) {
                event.stopPropagation();
            }
            //alert(JSON.stringify(event));
            var data = JSON.parse(event.dataTransfer.getData('text'));
            if (data.from == 'questionGroupQuestion') {
                if (this.questionGroups[qGroupIdx].length == 0) {
                    this.dropToQuestion(event, qGroupIdx, 0);
                }
            }
            if (data.from == 'questionGroup') {
                var target = $(event.target).closest('.questionList')[0];
                $(target).removeClass('selected-bottom');
                $(target).removeClass('selected-top');
                this.questionGroups = this.array_move(this.questionGroups, data.qGroupIdx, qGroupIdx);

                /*for(var i = 0 ; i< this.questionGroups[data.qGroupIdx].length;i++){
						this.questionGroups[data.qGroupIdx][i].group = data.qGroupIdx + 1;
					}
					for(var i = 0 ; i< this.questionGroups[qGroupIdx].length;i++){
						this.questionGroups[qGroupIdx][i].group = qGroupIdx + 1;
					}*/
                for (var i = 0; i < this.questionGroups.length; i++) {
                    for (var j = 0; j < this.questionGroups[i].length; j++) {
                        this.questionGroups[i][j].group = i + 1;
                        this.questionGroups[i][j]._tempGroup = i + 1;
                    }
                }
                this.questionGroups.splice();
                this.saveManageQuestions(false, false);
            }
            //clear draggedItem
            this.currentDraggedItem = null;
        },
        leaveDragFromQuestionGroup(event) {
            var target = $(event.target).closest('.questionList')[0];
            if ($(this.currentDraggedItem).hasClass('questionList')) {
                $(target).removeClass('selected-bottom');
                $(target).removeClass('selected-top');
            }
        },
        allowDropToQuestionGroup(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
            //window.requestAnimationFrame(function(){
            //qgroup to qgroup sorting.
            if ($(this.currentDraggedItem).hasClass('questionList')) {
                var target = $(event.target).closest('.questionList')[0];
                if (!target.isSameNode(this.currentDraggedItem)) {
                    var bounding = target.getBoundingClientRect();
                    var offset = bounding.y + bounding.height / 2;
                    if (event.clientY - offset > 0) {
                        $(target).removeClass('selected-top');
                        $(target).addClass('selected-bottom');
                    } else {
                        $(target).removeClass('selected-bottom');
                        $(target).addClass('selected-top');
                    }
                }
            }

            var that = this;
            var scroll = function (step) {
                var scrollY = $(window).scrollTop();
                $(window).scrollTop(scrollY + step);
                if (!that.stopScroll) {
                    setTimeout(function () {
                        scroll(step);
                    }, 20);
                }
            };
            this.stopScroll = true;
            if (event.clientY < 150) {
                this.stopScroll = false;
                scroll(-1);
            }

            if (event.clientY > $(window).height() - 150) {
                this.stopScroll = false;
                scroll(1);
            }
        },
        dragQuestionGroup(event, qGroupIdx) {
            if (this.currentDraggedItem != null) {
                return;
            }
            event.dataTransfer.setData('text', JSON.stringify({ qGroupIdx: qGroupIdx, from: 'questionGroup' }));
            event.dataTransfer.dropEffect = 'move';
            this.currentDraggedItem = event.target;
        },
        fetchActivity() {
            return axios.get('/activities/' + this.activity.uuid);
        },
        setAllQuestionsAsOnePage() {
            let questions = [];
            for (var i = 0; i < this.questionGroups.length; i++) {
                let qGroup = this.questionGroups[i];
                questions = questions.concat(qGroup);
            }
            this.questionGroups = [];
            this.addQuestionGroup(questions, false);
            for (var i = 0; i < this.questionGroups[0].length; i++) {
                this.questionGroups[0][i].order = i + 1;
                this.questionGroups[0][i]._tempGroup = 1;
                this.questionGroups[0][i].group = 1;
            }
            this.saveManageQuestions(false, false);
            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'All questions are now in one page',
            });
        },
        addQuestionGroup(questions = [], notification = true) {
            let length = 0;
            if (!(questions instanceof Array)) {
                questions = [questions];
            }
            length = this.questionGroups.push(questions);
            if (notification) {
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'a new page has been added',
                });
            }
            return length;
        },
        spliceQuestionGroup(question_group_idx) {
            this.questionGroups.splice(question_group_idx, 1);
            for (var i = question_group_idx; i < this.questionGroups.length; i++) {
                for (var j = 0; j < this.questionGroups[i].length; j++) {
                    this.questionGroups[i][j].group = i + 1;
                    this.questionGroups[i][j]._tempGroup = i + 1;
                }
            }
        },
        deleteSidebarSelectedQuestions() {
            $('.' + this.id + ' .deleteSelectedSidebarQnsModal').modal('hide');
            var that = this;
            for (var i = 0; i < this.selectedSidebarQuestions.length; i++) {
                let questionGroupIdx = _.findIndex(this.questionGroups, function (o) {
                    return o[0].uuid == that.selectedSidebarQuestions[i];
                });
                if (questionGroupIdx != -1) {
                    this.deleteQuestion(questionGroupIdx, 0, false);
                    this.spliceQuestionGroup(questionGroupIdx);
                }
            }
            this.processDeletions();
            this.selectedSidebarQuestions = [];
        },
        deleteQuestionGroup() {
            $('#' + this.id + ' .deletePageModal').modal('hide');
            let question_group_idx = this.questionGroupToDeleteIdx;
            for (var i = this.questionGroups[question_group_idx].length - 1; i >= 0; i--) {
                this.deleteQuestion(question_group_idx, i, false);
            }
            this.spliceQuestionGroup(question_group_idx);
            this.processDeletions();
        },
        deleteQuestion(qGroupIdx, questionIdx, apiCall = true) {
            $('.deleteQnsModal').modal('hide');
            this.questionGroups[qGroupIdx][0].order = null;
            this.questionGroups[qGroupIdx][0].group = null;
            this.deleted_questions.push(this.questionGroups[qGroupIdx][0]);

            this.questionGroups[qGroupIdx].splice(0, 1);

            for (var i = 0; i < this.questionGroups[qGroupIdx].length; i++) {
                this.questionGroups[qGroupIdx][i].order = i + 1;
            }
            if (apiCall) {
                this.processDeletions();
            }
        },
        processDeletions() {
            var that = this;
            let toDelete = [];

            for (var i = 0; i < this.deleted_questions.length; i++) {
                toDelete.push(this.deleted_questions[i].uuid);
            }
            var ret = { uuids: toDelete };
            if (this.target) {
                ret.target = this.target;
            }
            /*Splice Question groups again to be sure*/
            for (var i = this.questionGroups.length - 1; i >= 0; i--) {
                if (this.questionGroups[i].length == 0) {
                    this.spliceQuestionGroup(i);
                }
            }
            let newOrder = [];
            for (var i = 0; i < this.questionGroups.length; i++) {
                for (var j = 0; j < this.questionGroups[i].length; j++) {
                    newOrder.push({
                        uuid: this.questionGroups[i][j].uuid,
                        group: i + 1,
                        order: j + 1,
                    });
                }
            }
            axios.post(`/activities/${this.activity.uuid}/questions/bulk/delete`, ret).then(function (response) {
                that.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Question/s has been removed from the page.',
                });
                that.selectedSidebarQuestions = [];
                axios
                    .put(`activities/${that.activity.uuid}/questions/bulk/update`, {
                        data: newOrder,
                    })
                    .then(function (response) {})
                    .catch(function (errors) {});
            });
        },
        saveManageQuestions(exitOnSave = false, goToNextStep = true) {
            var that = this;
            let toUpdate = [];

            /*Delete question groups without questions*/
            for (var i = this.questionGroups.length - 1; i >= 0; i--) {
                if (this.questionGroups[i] == 0) {
                    for (var j = i; j < this.questionGroups.length; j++) {
                        for (var k = 0; k < this.questionGroups[j].length; k++) {
                            this.questionGroups[j][k]._tempGroup = j;
                        }
                    }
                    this.questionGroups.splice(i, 1);
                }
            }
            for (var i = 0; i < this.questionGroups.length; i++) {
                let qGroup = this.questionGroups[i];
                for (var j = 0; j < qGroup.length; j++) {
                    let question = qGroup[j];
                    toUpdate.push({
                        uuid: question.uuid,
                        group: i + 1,
                        order: j + 1,
                    });
                }
            }
            var ret = { data: toUpdate };
            if (this.target) {
                ret.target = this.target;
            }
            if (toUpdate.length == 0) {
                if ((exitOnSave === true && this.activity.type == 'michaelsenEvaluation') || this.activity.type != 'michaelsenEvaluation') {
                    that.$emit('save-and-exit');
                } else {
                    if (that.pageType == 'form' && goToNextStep) {
                        that.$emit('next-step');
                    }
                }
                return;
            }
            axios.put('/activities/' + this.activity.uuid + '/questions/bulk/update', ret).then(function (response) {
                that.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Activity questions have sucessfully been updated',
                });
                that.fetchActivity().then(function (response) {
                    that.activity = response.data.data;
                    that.$emit('activity-updated', that.activity);
                    that.init(true);
                    if (exitOnSave === true) {
                        that.$emit('save-and-exit');
                    } else {
                        if (that.pageType == 'form' && goToNextStep) {
                            that.$emit('next-step');
                        }
                        //that.manage = false;
                    }
                });
            });
        },
        saveSelectedQuestions() {
            var that = this;
            var data = [];
            let currentqGroupCount = this.questionGroups.length;
            let currentZeroGroupCount = _.get(this.questionGroups, '0', []).length + 1;
            for (var i = 0; i < this.selected_questions.length; i++) {
                if (!['evaluation', 'michaelsenEvaluation'].includes(this.activityObj.type)) {
                    data.push({
                        uuid: this.selected_questions[i].uuid,
                        group: ++currentqGroupCount,
                        order: 1,
                    });
                } else {
                    data.push({
                        uuid: this.selected_questions[i].uuid,
                        group: 1,
                        order: currentZeroGroupCount + i,
                    });
                }
            }
            var ret = { data: data };
            if (this.target) {
                ret.target = this.target;
            }
            axios.post('/activities/' + this.activity.uuid + '/questions/bulk/select', ret).then(function (response) {
                /*let newly_selected_questions = response.data;
					for(var i = 0;i<newly_selected_questions.length;i++){
						this.addQuestionGroup(newly_selected_questions[i]);
					}*/
                that.fetchActivity().then(function (response) {
                    that.activity = response.data.data;
                    that.$emit('activity-updated', that.activity);
                    that.init();
                });
                that.selected_questions = [];
                that.addQuestionBankView = false;
                that.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Questions have been successfully added',
                });
            });
            //Events.fire('added_questions',questions);

            /*default each question on individual questionGroups.
				this.distributeRemainingQuestions('individual',initial_length);
				this.filterTotalQuestions();
				Vue.nextTick(function(){
					Events.fire('add-questions-panel-toggle',false);
					Events.fire('manage-question-panel-toggle',true);
				});*/
        },
        //for bank selection methods
        addToSelectedQuestions(question) {
            let idx = _.findIndex(this.selected_questions, function (o) {
                return o.uuid == question.uuid;
            });
            if (idx == -1 && !this.isQuestionSelected(question) && !this.isQuestionSaved(question)) {
                this.selected_questions.push(question);
            }
        },
        deleteFromSelectedQuestions(question) {
            let idx = _.findIndex(this.selected_questions, function (o) {
                return o.uuid == question.uuid;
            });
            if (idx == -1) {
                this.selected_questions.push(question);
            }
            this.selected_questions.splice(idx, 1);
        },
        //for question bank tagging
        isQuestionSelected(question) {
            let idx = _.findIndex(this.selected_questions, function (o) {
                return o.uuid == question.uuid;
            });
            if (idx == -1) {
                return false;
            }
            return true;
        },
        isQuestionSaved(question) {
            let idx = -1;
            for (var i = 0; i < this.questionGroups.length; i++) {
                let qGroup = this.questionGroups[i];
                idx = _.findIndex(qGroup, function (o) {
                    return o.referenceQuestionUuid == question.uuid;
                });
                if (idx != -1) {
                    return true;
                }
            }
            return false;
        },
        //for question bank callback
        getQuestionData(data) {
            this.questionSearchData = data;
        },
        editQuestion(question, type) {
            this.$refs.peerForm.edit(question, {
                displayAddToQuestionBankField: false,
                url: '/activities/' + this.activity.uuid + '/peer-evaluation/questions/' + question.uuid,
                selectedType: type,
                target: this.activity.evaluationSettings.target,
            });
        },
        createQuestionForm(type) {
            this.$refs.peerForm.create({
                displayAddToQuestionBankField: false,
                url: '/activities/' + this.activity.uuid + '/peer-evaluation/questions/',
                selectedType: type,
                target: this.activity.evaluationSettings.target,
            });
        },
        init(syncOldQuestionGroups = false) {
            this.questionGroups = [];
            if (!['evaluation', 'michaelsenEvaluation'].includes(this.activity.type)) {
                this.questionGroups = _.get(this.activity, 'questions', []);
            } else {
                //evaluation
                if (this.target == 'peer') {
                    this.questionGroups = _.get(this.activity, 'peerQuestions', []);
                } else if (this.target == 'team') {
                    this.questionGroups = _.get(this.activity, 'teamQuestions', []);
                } else {
                    this.questionGroups = _.get(this.activity, 'instructorQuestions', []);
                }
            }
            for (var i = 0; i < this.questionGroups.length; i++) {
                let qGroup = this.questionGroups[i];
                for (var j = 0; j < qGroup.length; j++) {
                    qGroup[j]._tempGroup = i + 1;
                }
            }
            if (syncOldQuestionGroups) {
                this.oldQuestionGroups = _.cloneDeep(this.questionGroups);
            }
        },
        truncate(str, characters = 10) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
        copyQuestion(question) {
            this.previewQuestion = question;
            $('.copyQuestionModal').modal('show');
        },
        makeACopy(id) {
            var that = this;
            axios
                .post(`/activities/${this.activity.uuid}/questions/${id}/duplicate`)
                .then(function (response) {
                    $('html, body').animate({ scrollTop: document.body.scrollHeight }, 'slow');

                    that.fetchActivity().then(function (response) {
                        var questions = response.data.data.questions;
                        questions[questions.length - 1][0]._isCopied = true;

                        that.activity = response.data.data;

                        // console.log(that.activity);
                        that.$emit('activity-updated', that.activity);
                        that.init();
                    });

                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question has been successfully copied',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to copy question at this moment, please try again later',
                    });
                });

            $('.copyQuestionModal').modal('hide');
        },
        openShowMoreDetailModal(question) {
            this.previewQuestion = question;
            this.topicsLength = question.topics.length;
            $('.showMoreDetailModal').modal('show');
        },
        toggleSidebarQuestions() {
            this.selectedSidebarQuestions = [];
            if (this.selectAllSidebarQuestions) {
                for (var i = 0; i < this.activity.questions.length; i++) {
                    this.selectedSidebarQuestions.push(this.activity.questions[i][0].uuid);
                }
            }
        },
        toggleDropdown(event) {
            const dropdown = event.currentTarget.closest('.dropdown');

            if (!dropdown.classList.contains('open')) {
                const btnDropDown = dropdown.querySelector('.dropdown-toggle');
                dropdown.style.position = 'static';
                const scrollTop = $('.sidebar-content').scrollTop();
                const listHolder = dropdown.querySelector('.dropdown-menu');
                listHolder.style.top = btnDropDown.offsetTop + btnDropDown.offsetHeight - scrollTop + 'px';
                listHolder.style.left = btnDropDown.offsetLeft + 'px';
                listHolder.dataset.open = true;
            }
        },
        handleScroll(event) {
            const sidebarContet = event.currentTarget;
            const listHolder = sidebarContet.querySelectorAll('.dropdown-menu');

            var sidebarTopCutOffPoint = $('.sidebar-content').offset().top - 24;
            var heightForTop = $('.sidebar-content').height() + $('.sidebar-content').offset().top;

            listHolder.forEach((item) => {
                if (item.dataset.open) {
                    const dropdown = $('.dropdown.open');
                    const dropdownOffsetTop = dropdown.offset().top;
                    const btnDropDown = item.parentElement.querySelector('.dropdown-toggle');
                    const scrollTop = sidebarContet.scrollTop;

                    if (dropdownOffsetTop >= sidebarTopCutOffPoint && dropdownOffsetTop <= heightForTop) {
                        item.parentElement.style.position = 'static';
                        item.style.top = btnDropDown.offsetTop + btnDropDown.offsetHeight - scrollTop + 'px';
                        item.style.left = btnDropDown.offsetLeft + 'px';
                    } else {
                        item.parentElement.style.position = 'relative';
                        item.style.top = btnDropDown.offsetTop + 22 + 'px';
                        item.style.left = btnDropDown.offsetLeft + 'px';
                    }
                }
            });
        },
    },
    components: {
        'question-display': require(`./../../../../questions/partials/question-display.vue`).default,
        'question-display-new': require(`./../../../../questions/partials/question-display-new.vue`).default,
        'question-cru-new': require(`./../../../../questions/cru-new.vue`).default,
    },
    watch: {
        options: {
            handler: function () {
                let target = _.get(this, 'options.target');
                if (this.target != target) {
                    this.target = target;
                    this.init(true);
                }
            },
            deep: true,
        },
        selectedSidebarQuestions: {
            handler: function () {
                if (this.activity.questions.length == this.selectedSidebarQuestions.length && this.activity.questions.length != 0) {
                    this.selectAllSidebarQuestions = true;
                } else {
                    this.selectAllSidebarQuestions = false;
                }
            },
        },
    },
};
</script>

<script setup>
import { ref } from 'vue';
const defaultSliderData = ref([
    {
        name: 'Teammate 1',
        initial: 'T1',
        point: 10,
    },
    {
        name: 'Teammate 2',
        initial: 'T2',
        point: 5,
    },
    {
        name: 'Teammate 3',
        initial: 'T3',
        point: 6,
    },
    {
        name: 'Teammate 4',
        initial: 'T4',
        point: 12,
    },
    {
        name: 'Teammate 5',
        initial: 'T5',
        point: 17,
    },
]);
</script>
