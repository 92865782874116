<template>
    <!--PUT HTML HERE-->
    <div v-if="component_done_loading">
        <div class="col-xs-12 right-container">
            <div class="pageTitle">
                <h1>Gradebook</h1>
                <p class="col-md-6">Students can view their grades for a specific activity once their instructor has made them available.</p>
            </div>

            <template v-if="grades.length > 0">
                <div style="background: #f7f8fa">
                    <div v-for="course in grades" class="table-responsive" style="margin-bottom: 20px">
                        <table class="table">
                            <thead :id="course.courseUuid" tabindex="-1">
                                <tr>
                                    <th colspan="12">
                                        <div class="flexSpaceBetween">
                                            <div>
                                                <h2 class="fs-26px">
                                                    {{ course.courseName }}
                                                    (<span
                                                        v-text="
                                                            course.modules.length <= 1 ? course.modules.length + ' module' : course.modules.length + ' modules'
                                                        "
                                                    />)
                                                </h2>
                                            </div>
                                            <div class="textAlignRight">
                                                <button
                                                    v-if="course._display !== false"
                                                    class="btn minWidth5em alignRight marginLeft0"
                                                    :aria-label="'Show less of ' + course.courseName + ' table'"
                                                    @click="
                                                        course._display = false;
                                                        grades.splice();
                                                    "
                                                >
                                                    Less
                                                    <i class="fa fa-caret-up marginLeft8" aria-hidden="true" />
                                                </button>
                                                <button
                                                    v-else
                                                    class="btn minWidth5em alignRight marginLeft0"
                                                    :aria-label="'Show more of ' + course.courseName + ' table'"
                                                    @click="
                                                        course._display = true;
                                                        grades.splice();
                                                    "
                                                >
                                                    More
                                                    <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="course._display !== false">
                                <table class="table" style="box-shadow: none; border: 0px">
                                    <tbody style="display: block; padding: 15px 20px">
                                        <template v-for="module in course.modules">
                                            <table class="table" style="margin-bottom: 10px">
                                                <thead :id="module.moduleUuid" tabindex="-1">
                                                    <tr>
                                                        <th colspan="12">
                                                            <div class="flexSpaceBetween">
                                                                <div>
                                                                    <h3 class="fs-24px">
                                                                        {{ module.moduleName }}
                                                                        (<span
                                                                            v-text="
                                                                                module.activities.length <= 1
                                                                                    ? module.activities.length + ' activity'
                                                                                    : module.activities.length + ' activities'
                                                                            "
                                                                        />)
                                                                    </h3>
                                                                </div>
                                                                <div class="textAlignRight">
                                                                    <button
                                                                        v-if="module._display !== false"
                                                                        class="btn minWidth5em alignRight marginLeft0"
                                                                        :aria-label="'Show less of ' + module.moduleName + ' table'"
                                                                        @click="
                                                                            module._display = false;
                                                                            grades.splice();
                                                                        "
                                                                    >
                                                                        Less
                                                                        <i class="fa fa-caret-up marginLeft8" aria-hidden="true" />
                                                                    </button>
                                                                    <button
                                                                        v-else
                                                                        class="btn minWidth5em alignRight marginLeft0"
                                                                        :aria-label="'Show more of ' + module.moduleName + ' table'"
                                                                        @click="
                                                                            module._display = true;
                                                                            grades.splice();
                                                                        "
                                                                    >
                                                                        More
                                                                        <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="module._display !== false">
                                                    <tr>
                                                        <td>
                                                            <div class="table-responsive">
                                                                <table class="table subTable border-collapse" tabindex="0">
                                                                    <caption class="visible-hidden">
                                                                        {{
                                                                            module.moduleName
                                                                        }}
                                                                        activity table
                                                                    </caption>

                                                                    <thead>
                                                                        <tr class="activityHeaders">
                                                                            <th>Name</th>
                                                                            <th>Type</th>
                                                                            <th>Date</th>
                                                                            <th>Grade</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <template v-for="activity in module.activities">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    {{ activity.testName }}
                                                                                </td>
                                                                                <td>
                                                                                    <template v-if="activity.type == 'prework'"> Prework </template>
                                                                                    <template v-if="activity.type == 'irat'"> IRAT </template>
                                                                                    <template v-if="activity.type == 'trat'"> TRAT </template>
                                                                                    <template v-if="activity.type == 'iratntrat'"> IRAT & TRAT </template>
                                                                                    <template v-if="activity.type == 'application'">
                                                                                        Application Case
                                                                                    </template>
                                                                                    <template v-if="activity.type == 'evaluation'">
                                                                                        360 Degree Evaluation
                                                                                    </template>
                                                                                    <template v-if="activity.type == 'michaelsenEvaluation'">
                                                                                        Peer Evaluation: Michaelsen’s Method
                                                                                    </template>
                                                                                    <template v-if="activity.type == 'peerEvaluationV2'">
                                                                                        Peer Evaluation
                                                                                    </template>
                                                                                </td>
                                                                                <td>
                                                                                    {{ convertToReadableDate(activity.testTimeStarted, 'DD MMM YYYY').date }}
                                                                                </td>
                                                                                <td>
                                                                                    {{ activity.grade }}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </template>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </template>
                                    </tbody>
                                </table>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
            <div v-else>
                <p v-if="responseStatus == 200">No grades available</p>
                <p v-else>We are still processing your grades. Pls refresh in a few minutes.</p>
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            auth: auth,
            grades: [],
            responseStatus: -1,
        };
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-chalkboard-teacher"></i>&nbsp;Gradebook',
            breadcrumb: [{ name: 'Home' }, { name: 'Gradebook', link: 'grades.index' }],
        });
        this.fetchGrades();
    },
    methods: {
        fetchGrades() {
            var that = this;
            axios.get('student/grades').then(function (response) {
                that.responseStatus = response.status;
                if (that.responseStatus != 202) {
                    that.grades = _.map(_.groupBy(response.data.data, 'courseUuid'), function (items, courseUuid) {
                        return {
                            courseUuid: courseUuid,
                            courseName: items[0].courseName,
                            modules: items,
                        };
                    });
                    for (var i = 0; i < that.grades.length; i++) {
                        that.grades[i].modules = _.map(_.groupBy(that.grades[i].modules, 'moduleUuid'), function (items, moduleUuid) {
                            return {
                                moduleUuid: moduleUuid,
                                moduleName: items[0].moduleName,
                                activities: items,
                            };
                        });
                    }
                }
                that.component_done_loading = true;
            });
        },
    },
};
</script>
<style scoped>
.subContent {
    padding: 0;
    border: 0;
    background: none;
}
.subTable {
    margin-top: 20px;
}
.subTable:first-child {
    margin-top: 0;
}

.subTable th {
    position: relative;
}

.subTable th button {
    right: 20px;
}

.table .subContentTable .table {
    margin-bottom: 20px;
}

.subContentTable:last-child .table {
    margin-bottom: 0;
}

.table .subContentTable tbody .table {
    margin-bottom: 0;
}
</style>
