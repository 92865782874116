report.vue
<template>
    <div v-if="component_done_loading" class="test">
        <test-topbar :test-obj="test"></test-topbar>
        <div class="col-xs-12 right-container" :class="{ flex: test.iratAndTratScores }">
            <div v-if="test.iratAndTratScores" class="col-xs-12 col-md-4 marginBottom20">
                <div :class="{ 'position-fixed col-md-4': !isMobileView }">
                    <h2>Graph Report</h2>
                    <kr-bar-chart :chartdata="data" :options="options"></kr-bar-chart>
                </div>
            </div>

            <div :class="{ 'col-xs-12 col-md-8 paddingLeft20 maxWidth888px': test.iratAndTratScores }">
                <h2>Overview Report</h2>
                <div :class="{ flexSpaceBetween: !isMobileView }">
                    <div class="reportFlex" :class="{ marginBottom20: isMobileView }">
                        <div class="gradeReport">
                            <div class="control-label">
                                <h3 class="margin0 fs-18px">Total Score</h3>
                            </div>
                            <div class="gradientReport">
                                <div>
                                    {{ test.userOrTeamScore }}<span>/{{ test.possibleTotalScore }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="gradeReport">
                            <div class="control-label">
                                <h3 class="margin0 fs-18px">Overall</h3>
                            </div>
                            <div class="gradientReport">
                                <div>{{ round(test.overall) }}%</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <legend-guide v-if="test.allowStudentsToViewAnswer" />
                    </div>
                </div>

                <div v-if="test.allowStudentsToViewAnswer" class="border1pxGrey marginTop30 padding0 width100">
                    <div class="report-table-header">
                        <h3 class="margin0 fs-18px">Questions ({{ test.questions.length }})</h3>
                    </div>
                    <div class="table-responsive">
                        <table class="table borderNone">
                            <thead>
                                <tr>
                                    <th>Question</th>
                                    <th>Score</th>
                                    <th>% Correct</th>
                                    <th class="hidden-xs hidden-sm">Team Answer Choices In Sequence</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(question, idx) in test.questions">
                                    <tr>
                                        <td>
                                            <button
                                                class="btn gradient btn-default marginBottom10"
                                                :aria-label="`Question ${idx + 1}`"
                                                @click.prevent="openQuestionInfoModal(question)"
                                            >
                                                Question {{ idx + 1 }}<i class="fa fa-info-circle marginLeft8" aria-hidden="true"></i>
                                            </button>
                                            <kr-math class="marginTop10" :input="question.question" :safe="!question.questionIsHTML"></kr-math>
                                        </td>
                                        <td>
                                            {{ question.answer.point }}
                                        </td>
                                        <td>
                                            <div
                                                class="statusTag whiteSpaceNowrap"
                                                :class="{
                                                    tagNewCorrect: optionCorrect(question),
                                                    tagCorrect: question.answer.percent == 100,
                                                    tagPrimary: question.answer.percent >= 50 && question.answer.percent < 100,
                                                    tagWarning: question.answer.percent < 50 && question.answer.percent > 0,
                                                    tagIncorrect: question.answer.percent == 0,
                                                }"
                                            >
                                                {{ round(question.answer.percent) }}%
                                            </div>
                                        </td>
                                        <td class="hidden-xs hidden-sm">
                                            <div
                                                v-if="question.answer.attempts && question.answer.attempts.length > 0"
                                                class="statusTag whiteSpaceNowrap"
                                                :class="{
                                                    tagNewCorrect: optionCorrect(question),
                                                    tagCorrect: question.answer.percent == 100,
                                                    tagPrimary: question.answer.percent >= 50 && question.answer.percent < 100,
                                                    tagWarning: question.answer.percent < 50 && question.answer.percent > 0,
                                                    tagIncorrect: question.answer.percent == 0,
                                                }"
                                            >
                                                <template v-for="(key, idx) in question.answer.attempts" :key="'attempts-' + idx">
                                                    <span class="paddingTop2">
                                                        {{ key }}
                                                        <template v-if="idx != question.answer.attempts.length - 1">&nbsp;</template>
                                                    </span>
                                                </template>
                                            </div>
                                            <div v-else>-</div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="teamAnalysisQuestionInfoModal"
            class="modal default-modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            aria-labelledby="teamAnalysisQuestionInfoModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="teamAnalysisQuestionInfoModal-title" class="modal-title">View Question</h2>
                    </div>
                    <div class="modal-body">
                        <question-display :test-obj="test" :question="previewQuestion" />
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            component_done_loading: false,
            test: {},
            previewQuestion: {},
            data: {
                datasets: [
                    {
                        label: 'Score',
                        data: [],
                        backgroundColor: ['#A3C1F3', '#578FAC'],
                        barPercentage: 0.4,
                    },
                ],
                labels: ['IRAT', 'TRAT'],
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    display: true,
                    position: 'right',
                    align: 'start',
                    fullWidth: false,
                    labels: {
                        display: true,
                        usePointStyle: false,
                        boxWidth: 15,
                        boxHeight: [10, 20],
                        fontSize: 14,
                        fontStyle: 'bold',
                    },
                },
                layout: {
                    padding: {
                        top: 30,
                    },
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                max: 100,
                                maxTicksLimit: 5,
                                stepSize: 25,
                                callback: (val) => val + '.0%',
                            },
                            gridLines: {
                                drawBorder: false,
                                zeroLineColor: '#222',
                            },
                        },
                    ],
                    xAxes: [
                        {
                            ticks: {
                                fontStyle: 'bold',
                                fontColor: '#222',
                            },
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
                tooltips: {
                    custom: function (tooltip) {
                        if (!tooltip) return;
                        tooltip.displayColors = false;
                    },
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return tooltipItem.xLabel + ' : ' + tooltipItem.yLabel + '%';
                        },
                        title: function (tooltipItem, data) {
                            return;
                        },
                    },
                },
                animation: {
                    onComplete: function () {
                        var chartInstance = this.chart,
                            ctx = chartInstance.ctx;

                        ctx.fillStyle = '#222';
                        ctx.font = 'normal 12px "Helvetica Neue", Helvetica, Arial, sans-serif';
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'bottom';

                        this.data.datasets.forEach(function (dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function (bar, index) {
                                var data = dataset.data[index];
                                ctx.fillText(data + '%', bar._model.x, bar._model.y - 5);
                            });
                        });

                        let increasePercent = (this.data.datasets[0].data[1] - this.data.datasets[0].data[0]).toFixed(2);
                        let barValue;

                        const lineHeight = ctx.measureText('M').width;
                        const offset = 25;
                        const dash = [8, 8];

                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'bottom';

                        var meta = chartInstance.controller;

                        if (meta.getDatasetMeta(0).data[0]._model.y == meta.getDatasetMeta(0).data[1]._model.y) {
                            barValue = `${increasePercent}%`;
                            ctx.lineWidth = 1;

                            ctx.fillText(barValue, meta.getDatasetMeta(0).data[0]._model.x * 2 - 50, meta.getDatasetMeta(0).data[1]._model.y);

                            ctx.beginPath();
                            ctx.setLineDash(dash);

                            ctx.moveTo(meta.getDatasetMeta(0).data[0]._model.x, meta.getDatasetMeta(0).data[0]._model.y - offset);
                            ctx.lineTo(meta.getDatasetMeta(0).data[0]._model.x, meta.getDatasetMeta(0).data[1]._model.y - offset);
                            ctx.lineTo(meta.getDatasetMeta(0).data[1]._model.x, meta.getDatasetMeta(0).data[1]._model.y - offset);
                            ctx.stroke();
                        } else if (meta.getDatasetMeta(0).data[0]._model.y > meta.getDatasetMeta(0).data[1]._model.y || this.data.datasets[0].data[0] == 0) {
                            ctx.fillStyle = 'green';
                            if (this.data.datasets[0].data[0] == 0) {
                                barValue = `↑ 100%`;
                            } else {
                                barValue = `↑ ${increasePercent}%`;
                            }
                            ctx.strokeStyle = 'green';
                            ctx.lineWidth = 1;

                            ctx.fillText(barValue, meta.getDatasetMeta(0).data[0]._model.x * 2 - 50, meta.getDatasetMeta(0).data[1]._model.y);

                            ctx.beginPath();
                            ctx.setLineDash(dash);

                            ctx.moveTo(meta.getDatasetMeta(0).data[0]._model.x, meta.getDatasetMeta(0).data[0]._model.y - offset);
                            ctx.lineTo(meta.getDatasetMeta(0).data[0]._model.x, meta.getDatasetMeta(0).data[1]._model.y - offset);
                            ctx.lineTo(meta.getDatasetMeta(0).data[1]._model.x, meta.getDatasetMeta(0).data[1]._model.y - offset);
                            ctx.stroke();
                        } else {
                            ctx.fillStyle = 'red';

                            if (this.data.datasets[0].data[1] == 0) {
                                barValue = `↓ 100%`;
                            } else {
                                barValue = `↓ ${increasePercent}%`;
                            }

                            ctx.strokeStyle = 'red';
                            ctx.lineWidth = 1;

                            ctx.fillText(barValue, meta.getDatasetMeta(0).data[0]._model.x * 2 - 50, meta.getDatasetMeta(0).data[0]._model.y);

                            ctx.beginPath();
                            ctx.setLineDash(dash);

                            ctx.moveTo(meta.getDatasetMeta(0).data[0]._model.x, meta.getDatasetMeta(0).data[0]._model.y - offset);
                            ctx.lineTo(meta.getDatasetMeta(0).data[1]._model.x, meta.getDatasetMeta(0).data[0]._model.y - offset);
                            ctx.lineTo(meta.getDatasetMeta(0).data[1]._model.x, meta.getDatasetMeta(0).data[1]._model.y - offset);
                            ctx.stroke();
                        }
                    },
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
            },
        };
    },
    mounted() {
        var that = this;
        this.fetchTest().then(function (response) {
            that.test = response.data.data;
            for (var i = 0; i < that.test.questions.length; i++) {
                if (!that.test.questions[i].answer) {
                    that.test.questions[i].answer = {
                        answer: '',
                        attempts: [],
                        isCorrect: false,
                        percent: 0,
                        point: '0.00',
                    };
                }
            }
            if (that.test.iratAndTratScores) {
                if (that.test.iratAndTratScores.iratScore == null && that.test.iratAndTratScores.tratScore == null) {
                    that.data.datasets[0].data.push(0, 0);
                } else if (that.test.iratAndTratScores.iratScore != null && that.test.iratAndTratScores.tratScore == null) {
                    that.data.datasets[0].data.push(that.test.iratAndTratScores.iratScore, 0);
                } else if (that.test.iratAndTratScores.iratScore == null && that.test.iratAndTratScores.tratScore != null) {
                    that.data.datasets[0].data.push(0, that.test.iratAndTratScores.tratScore);
                } else {
                    that.data.datasets[0].data.push(that.test.iratAndTratScores.iratScore, that.test.iratAndTratScores.tratScore);
                }
            }

            that.component_done_loading = true;
            that.processEchoListener();
        });
    },
    created() {
        var that = this;
        $('body').addClass('test').removeClass('nav-sm').addClass('nav-none');
        Events.listen('changed-test', function (data) {
            //fake automatic
            that.test = data;
        });
    },
    methods: {
        processEchoListener() {
            var that = this;
            let h1 = (e) => {
                if (e.test.uuid != that.test.uuid) {
                    return false;
                }
                that.test.allowStudentsToViewAnswer = e.test.allowStudentsToViewAnswer;
                that.test.allowStudentsToViewScore = e.test.allowStudentsToViewScore;
                that.test.allowStudentsToPreviewQuestions = e.test.allowStudentsToPreviewQuestions;
                if (!e.test.allowStudentsToViewScore) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has disabled viewing of test results.',
                    });
                    that.$router.push({ name: 'tests.index' });
                }
            };
            let c1 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('TestVisibilityUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestVisibilityUpdated',
                handler: h1,
            });
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question;
            $('#teamAnalysisQuestionInfoModal').modal('show');
        },
        fetchTest() {
            return axios.get('student/tests/' + this.$route.params.id + '/report');
        },
        optionCorrect(question) {
            if (question.type == 'mcqs') {
                for (var i = 0; i < this.test.acceptedNewAnswers.length; i++) {
                    if (this.test.acceptedNewAnswers[i].activityQuestionId == question.id) {
                        return this.test.acceptedNewAnswers[i].acceptedAnswers.some((value) => question.answer.attempts.includes(value));
                    }
                }
            }
        },
    },
    components: {
        'test-topbar': require(`./../partials/topbar.vue`).default,
        'question-display': require(`./../../../questions/partials/question-display.vue`).default,
    },
    beforeUnmount() {
        if ($(window).width() > 991) {
            $('body').removeClass('test').addClass('nav-sm').removeClass('nav-none');
        } else {
            $('body').removeClass('test').removeClass('nav-sm').addClass('nav-none');
        }
    },
};
</script>

<style scoped>
.reportFlex {
    display: flex;
}

.reportFlex .gradeReport {
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
}

.gradeReport .control-label {
    text-align: center;
}

.reportFlex .gradeReport:first-child {
    margin-left: 0;
}

.gradientReport {
    background: linear-gradient(to left, #546ea9 70%, #f4f4f4 100%);
    border-radius: 40px;
    box-sizing: border-box;
    color: #222;
    display: block;
    letter-spacing: 1px;
    margin: 0 auto;
    padding: 4px;
    position: relative;
    text-transform: uppercase;
    z-index: 2;
}

.gradientReport div {
    align-items: center;
    background: #f8f8f8;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: bold;
}

.gradientReport span {
    color: #717171;
    font-size: 14px;
}

@media (max-width: 991px) {
    .reportFlex .gradeReport {
        padding: 10px;
    }

    .reportFlex .gradeReport {
        width: 31.33%;
        margin-left: 3%;
    }

    .gradientReport {
        height: 70px;
        width: 70px;
        font-size: 20px;
    }

    .gradeReport .control-label,
    .gradientReport span {
        font-size: 12px;
    }

    #bar-chart {
        width: 400px;
        height: 400px;
    }
}

@media (min-width: 992px) {
    .reportFlex .gradeReport {
        padding: 10px 25px;
    }

    .reportFlex .gradeReport {
        width: 140px;
        margin-left: 30px;
    }

    .gradientReport {
        height: 80px;
        width: 80px;
        font-size: 24px;
    }

    /* table {
        table-layout: auto;
        border-collapse: collapse;
        text-align: left;
    }

    tbody {
        display: block;
        width: 100%;
        overflow: overlay;
        max-height: 400px;
    }

    tbody tr,
    thead tr {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 2fr;
    } */
}

.maxWidth888px {
    max-width: 888px;
}
</style>
