<template>
    <div v-if="component_done_loading" class="test">
        <test-topbar :test-obj="test"></test-topbar>
        <div class="col-xs-12 right-container">
            <h2>Overview Report</h2>

            <div :class="{ flexSpaceBetween: !isMobileView }">
                <div class="reportFlex" :class="{ marginBottom20: isMobileView }">
                    <div class="gradeReport">
                        <div class="control-label">
                            <h3 class="margin0 fs-18px">Total Score</h3>
                        </div>
                        <div class="gradientReport">
                            <div>
                                {{ test.userOrTeamScore }}<span>/{{ test.possibleTotalScore }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="gradeReport">
                        <div class="control-label">
                            <h3 class="margin0 fs-18px">Overall</h3>
                        </div>
                        <div class="gradientReport">
                            <div>{{ round(test.overall) }}%</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <legend-guide v-if="test.allowStudentsToViewAnswer" />
                </div>
            </div>

            <div v-if="test.allowStudentsToViewAnswer" class="border1pxGrey marginTop30 padding0 width100">
                <div class="report-table-header">
                    <h3 class="margin0 fs-18px">Questions ({{ test.questions.length }})</h3>
                </div>
                <div class="table-responsive">
                    <table class="table borderNone">
                        <thead>
                            <tr>
                                <th class="width350px minWidth350px">Question</th>
                                <th class="width100px minWidth100px">Score</th>
                                <th class="width115px minWidth115px">% Correct</th>
                                <th class="width500px minWidth500px maxWidth500px">
                                    <template v-if="test.isTeamBased"> Team </template>
                                    <template v-else> My </template>
                                    Answer Choices In Sequence
                                </th>
                                <th class="width350px minWidth350px">Narrative Feedback</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(question, idx) in test.questions">
                                <tr>
                                    <td>
                                        <button
                                            class="btn gradient btn-default marginBottom10"
                                            :aria-label="`Question ${idx + 1}`"
                                            @click.prevent="openQuestionInfoModal(question)"
                                        >
                                            Question {{ idx + 1 }}<i class="fa fa-info-circle marginLeft8" aria-hidden="true"></i>
                                        </button>
                                        <kr-math class="marginTop10" :input="question.question" :safe="!question.questionIsHTML"></kr-math>
                                        <div v-if="question.attachments && question.attachments.length != 0" tabindex="0">
                                            <div class="marginTop20 fontBold">
                                                <i class="fa fa-paperclip marginRight8" aria-hidden="true"></i>QUESTION ATTACHMENT(S)
                                            </div>
                                            <div class="textLink">
                                                <div v-for="(file, idx) in question.attachments" :key="'mcq-' + idx + '-' + file.filename" class="flex">
                                                    <i class="fa fa-file-image-o marginRight8" aria-hidden="true"></i>
                                                    <template v-if="test.allowStudentsToViewAttachments">
                                                        <template v-if="isPdf(file)">
                                                            <template v-if="test.settings.allowPdfDownload">
                                                                <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">
                                                                    {{ file.filename }}
                                                                </a>
                                                            </template>
                                                            <template v-else>
                                                                <a class="textLink" :aria-label="file.filename" @click.prevent="openViewPdfModal(file)">{{
                                                                    file.filename
                                                                }}</a>
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{
                                                                file.filename
                                                            }}</a>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <a
                                                            v-tooltip="{
                                                                content: 'Viewing disabled',
                                                            }"
                                                            class="textLink disabled"
                                                            :aria-label="file.filename"
                                                            >{{ file.filename }}
                                                        </a>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {{ round(question.answer.point ? question.answer.point : 0, 2) }}
                                    </td>
                                    <td>
                                        <div
                                            class="statusTag whiteSpaceNowrap"
                                            :class="{
                                                tagNewCorrect: optionCorrect(question),
                                                tagCorrect: question.answer.percent == 100,
                                                tagPrimary: question.answer.percent >= 50 && question.answer.percent < 100,
                                                tagWarning: question.answer.percent < 50 && question.answer.percent > 0,
                                                tagIncorrect: question.answer.percent == 0,
                                            }"
                                        >
                                            {{ round(question.answer.percent) }}%
                                        </div>
                                    </td>
                                    <td>
                                        <template
                                            v-if="
                                                !test.settings.confidenceBasedTesting || ['mcqm', 'openended', 'matching', 'sequence'].includes(question.type)
                                            "
                                        >
                                            <fieldset v-if="question.type == 'sequence'">
                                                <legend>Sequence Order</legend>

                                                <template v-for="(option, option_idx) in question.options">
                                                    <div class="sequencing-div-instructor" :class="{ displayGrid: !isMobileView }">
                                                        <div :class="{ 'grid-order-2': !isMobileView }">
                                                            <p :id="'sequence-instructor-' + (option_idx + 1)">
                                                                {{ question.options[option_idx].content }}
                                                            </p>
                                                        </div>

                                                        <div :class="{ 'grid-order-1': !isMobileView, marginTop20: isMobileView }">
                                                            <label
                                                                :for="'sequence-instructor-' + (option_idx + 1)"
                                                                :class="{
                                                                    correct:
                                                                        question.answer.attempts[question.answer.attempts.length - 1][option_idx] ==
                                                                        question.options[option_idx].key,
                                                                    incorrect:
                                                                        question.answer.attempts[question.answer.attempts.length - 1][option_idx] !=
                                                                        question.options[option_idx].key,
                                                                }"
                                                            >
                                                                <span> Order </span>
                                                                <br v-if="!isMobileView" />
                                                                {{
                                                                    findIndexKey(
                                                                        question.options[option_idx].key,
                                                                        question.answer.attempts[question.answer.attempts.length - 1]
                                                                    )
                                                                }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </template>
                                            </fieldset>

                                            <fieldset v-else-if="question.type == 'matching'">
                                                <legend>
                                                    Matching
                                                    <template v-if="question.options.matchingType == 'one-to-one'"> (one-to-one) </template>
                                                    <template v-else> (one-to-many) </template>
                                                </legend>

                                                <template v-for="(match, matchIdx) in question.options.matches">
                                                    <div class="sequencing-div-instructor">
                                                        <div>
                                                            <label class="control-label capitalize">
                                                                {{ question.options.labels.prompt }} {{ matchIdx + 1 }}
                                                            </label>

                                                            <p>
                                                                {{ match.prompt.content }}
                                                            </p>
                                                        </div>

                                                        <dl class="marginTop20 marginBottom0">
                                                            <dt class="control-label capitalize">
                                                                {{ question.options.labels.answer }} for {{ question.options.labels.prompt }} {{ matchIdx + 1 }}
                                                            </dt>
                                                            <dd
                                                                v-for="(answer, answerIdx) in question.answer.attempts[question.answer.attempts.length - 1][
                                                                    matchIdx
                                                                ].answer"
                                                                class="flexOnly categorize-option"
                                                                :class="{
                                                                    'categorize-correct': findStatus(
                                                                        question.options.matches,
                                                                        question.answer.attempts[question.answer.attempts.length - 1][matchIdx].prompt,
                                                                        answer
                                                                    ),
                                                                    'categorize-incorrect': !findStatus(
                                                                        question.options.matches,
                                                                        question.answer.attempts[question.answer.attempts.length - 1][matchIdx].prompt,
                                                                        answer
                                                                    ),
                                                                }"
                                                            >
                                                                <i
                                                                    class="fa marginRight8 paddingTop2"
                                                                    :class="{
                                                                        'fa-check-circle': findStatus(
                                                                            question.options.matches,
                                                                            question.answer.attempts[question.answer.attempts.length - 1][matchIdx].prompt,
                                                                            answer
                                                                        ),
                                                                        'fa-times-circle': !findStatus(
                                                                            question.options.matches,
                                                                            question.answer.attempts[question.answer.attempts.length - 1][matchIdx].prompt,
                                                                            answer
                                                                        ),
                                                                    }"
                                                                    aria-hidden="true"
                                                                />
                                                                <p :for="'matching-one-to-one-instructor-answer-' + (matchIdx + 1) + '-' + (answerIdx + 1)">
                                                                    {{ findValue(question.options.matches, answer) }}
                                                                </p>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </template>
                                            </fieldset>

                                            <div v-else class="wordBreakWord" v-html="question.answer.answer ? question.answer.answer : '-'"></div>
                                        </template>
                                        <template v-else>
                                            {{ getPointSpreadingAttempts(question) }}
                                        </template>
                                    </td>
                                    <td class="whiteSpaceBreakSpacesText">
                                        {{ question.answer.narrativeFeedback }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div
            id="teamAnalysisQuestionInfoModal"
            class="modal default-modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            aria-labelledby="teamAnalysisQuestionInfoModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="teamAnalysisQuestionInfoModal-title" class="modal-title">View Question</h2>
                    </div>
                    <div class="modal-body">
                        <question-display :test-obj="test" :question="previewQuestion" />
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            component_done_loading: false,
            test: {},
            previewQuestion: {},
        };
    },
    mounted() {
        var that = this;
        this.fetchTest().then(function (response) {
            that.init(response);
            that.processEchoListener();
            that.component_done_loading = true;
        });
    },
    created() {
        var that = this;
        $('body').addClass('test').removeClass('nav-sm').addClass('nav-none');
    },
    methods: {
        processEchoListener() {
            var that = this;
            let h1 = (e) => {
                if (e.test.uuid != that.test.uuid) {
                    return false;
                }
                that.test.allowStudentsToViewAnswer = e.test.allowStudentsToViewAnswer;
                that.test.allowStudentsToViewScore = e.test.allowStudentsToViewScore;
                let allowStudentsToViewAttachments = that.test.allowStudentsToViewAttachments;
                that.test.allowStudentsToViewAttachments = e.test.allowStudentsToViewAttachments;
                that.test.allowStudentsToPreviewQuestions = e.test.allowStudentsToPreviewQuestions;
                if (!e.test.allowStudentsToViewScore) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has disabled viewing of test results.',
                    });
                    that.$router.push({ name: 'tests.index' });
                } else {
                    if (allowStudentsToViewAttachments != that.test.allowStudentsToViewAttachments) {
                        this.fetchTest().then(function (response) {
                            that.init(response);
                        });
                    }
                }
            };
            let c1 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('TestVisibilityUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestVisibilityUpdated',
                handler: h1,
            });
        },
        init(response) {
            var that = this;
            that.test = response.data.data;
            for (var i = 0; i < that.test.questions.length; i++) {
                if (!that.test.questions[i].answer) {
                    that.test.questions[i].answer = {
                        answer: '',
                        attempts: [],
                        isCorrect: false,
                        percent: 0,
                        point: '0.00',
                    };
                }
            }
        },
        getPointSpreadingAttempts(question) {
            let attempts = _.get(question.answer, 'attempts', []);
            if (attempts == null) {
                attempts = [];
            }
            if (attempts.length > 0) {
                let ret = '';
                let attempt = attempts[question.answer.attempts.length - 1];
                for (var k in attempt) {
                    if (attempt.hasOwnProperty(k)) {
                        ret += k + ':' + attempt[k] + ' ';
                    }
                }
                return ret;
            } else {
                return '-';
            }
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question;
            $('#teamAnalysisQuestionInfoModal').modal('show');
        },
        fetchTest() {
            return axios.get('student/tests/' + this.$route.params.id + '/report');
        },
        openViewPdfModal(attachment) {
            var that = this;
            Events.fire('view-pdf-modal', attachment, {
                canDownload: that.test.settings.allowPdfDownload,
            });
        },
        optionCorrect(question) {
            if (question.type == 'mcqs') {
                for (var i = 0; i < this.test.acceptedNewAnswers.length; i++) {
                    if (this.test.acceptedNewAnswers[i].activityQuestionId == question.id) {
                        return this.test.acceptedNewAnswers[i].acceptedAnswers.some((value) => question.answer.attempts.includes(value));
                    }
                }
            }
        },
        findIndexKey(optionsKey, answers) {
            let idx = _.findIndex(answers, function (o) {
                return o == optionsKey;
            });

            return idx + 1;
        },
        findValue(matchesArray, answerKey) {
            for (var i = 0; i < matchesArray.length; i++) {
                for (var j = 0; j < matchesArray[i].answer.length; j++) {
                    if (matchesArray[i].answer[j].key == answerKey) {
                        return matchesArray[i].answer[j].content;
                    }
                }
            }
        },
        findStatus(matchesArray, promptKey, ansKey) {
            let idx = _.findIndex(matchesArray, function (o) {
                return o.prompt.key == promptKey;
            });

            let arr = [];
            matchesArray[idx].answer.forEach((key) => {
                arr.push(key.key);
            });

            return arr.includes(ansKey);
        },
    },
    components: {
        'test-topbar': require(`./../partials/topbar.vue`).default,
        'question-display': require(`./../../../questions/partials/question-display.vue`).default,
    },
    beforeUnmount() {
        if ($(window).width() > 991) {
            $('body').removeClass('test').addClass('nav-sm').removeClass('nav-none');
        } else {
            $('body').removeClass('test').removeClass('nav-sm').addClass('nav-none');
        }
    },
};
</script>

<style scoped>
.reportFlex {
    display: flex;
}

.reportFlex .gradeReport {
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
}

.gradeReport .control-label {
    text-align: center;
}

.reportFlex .gradeReport:first-child {
    margin-left: 0;
}

.gradientReport {
    background: linear-gradient(to left, #546ea9 70%, #f4f4f4 100%);
    border-radius: 40px;
    box-sizing: border-box;
    color: #222;
    display: block;
    letter-spacing: 1px;
    margin: 0 auto;
    padding: 4px;
    position: relative;
    text-transform: uppercase;
    z-index: 2;
}

.gradientReport div {
    align-items: center;
    background: #f8f8f8;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: bold;
}

.gradientReport span {
    color: #717171;
    font-size: 14px;
}

@media (max-width: 991px) {
    .reportFlex .gradeReport {
        padding: 10px;
    }

    .reportFlex .gradeReport {
        width: 31.33%;
        margin-left: 3%;
    }

    .gradientReport {
        height: 70px;
        width: 70px;
        font-size: 20px;
    }

    .gradeReport .control-label,
    .gradientReport span {
        font-size: 12px;
    }
}

@media (min-width: 992px) {
    .reportFlex .gradeReport {
        padding: 10px 25px;
    }

    .reportFlex .gradeReport {
        width: 140px;
        margin-left: 30px;
    }

    .gradientReport {
        height: 80px;
        width: 80px;
        font-size: 24px;
    }
}
</style>
