<template>
    <div v-if="component_done_loading" class="test" :class="{ disabledImagePointerEvents: !test.settings.enableImagePointerEvents }">
        <test-topbar :test-obj="test" :options="{ forceHideTimer: false }" @timer-shown="processTimerShown" />
        <div class="evaluation right-container" :class="{ timer: is_timer_shown }">
            <stopping-div :test-obj="test" @display="processDisplayTestProper" />
            <question-groups v-show="display_test_proper" :test-obj="test" />
        </div>

        <div id="test_confirm_modal" class="modal" data-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content blue-modal-new">
                    <div class="modal-body textAlignCenter">
                        <img src="/img/submit_alert.png" alt="" class="marginBottom20" width="30%" />

                        <p class="marginBottom5">You will not be able to change your answers after submitting. This is <b>final.</b></p>

                        <p class="marginBottom30">Do you want to submit?</p>

                        <div class="flex marginTop30">
                            <button class="btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            <button class="btn btn-success" aria-label="Submit Test" data-dismiss="modal" @click.prevent="submitTest()">Submit Test</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="redirectingModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content blue-modal-new">
                    <div class="modal-body textAlignCenter">
                        <img src="/img/redirecting.png" alt="" class="marginBottom20" width="30%" />
                        <h3 class="text-success">Submission Successful</h3>
                        <div class="flexOnly justifyCenter marginBottom10">
                            <div class="modal-countdown-number marginLeft10" />
                            <p class="loadingAnimation"></p>
                        </div>
                        <p>
                            <router-link v-slot="{ navigate }" :to="{ name: 'tests.index' }" custom>
                                <button class="btn btn-primary" @click="navigate">Return to Dashboard</button>
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div id="teacherEndedModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content blue-modal-new">
                    <div class="modal-body textAlignCenter">
                        <img src="/img/teacher_ended.png" alt="" class="marginBottom20" width="30%" />

                        <p class="marginBottom5">
                            <b>Your instructor has ended the activity.</b>
                        </p>

                        <p class="marginBottom30">Please wait.</p>

                        <div class="flexOnly justifyCenter marginBottom10">
                            <div class="modal-countdown-number marginLeft10" />
                            <p class="loadingAnimation"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="timeUpModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content blue-modal-new">
                    <div class="modal-body textAlignCenter">
                        <img src="/img/time_up.png" alt="" class="marginBottom20" width="25%" />

                        <p class="marginBottom5">
                            <b>Time’s Up!</b>
                        </p>

                        <p class="marginBottom30">Please wait.</p>

                        <div class="flexOnly justifyCenter marginBottom10">
                            <div class="modal-countdown-number marginLeft10" />
                            <p class="loadingAnimation"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        return {
            auth: new KrAuth(),
            component_done_loading: false,
            test: {},
            display_test_proper: false,
            is_timer_shown: false,
        };
    },
    mounted() {
        var that = this;
        if (['tests.evaluation', 'tests.preview.evaluation'].includes(this.$route.name)) {
            this.init();
        } else if (this.$route.name == 'teachers.tests.preview.evaluation') {
            this.fetchActivity().then(function (response) {
                that.test = response.data.data;
                that.test._isPreview = true;
                that.test._isTeacherPreview = true;
                that.test.status = 'ongoing';
                that.test.myProgression = { userTestStatus: 'started' };
                that.test.settings = that.test.settings.evaluation;

                if (!_.isArray(_.get(that.test, 'settings.attachments'))) {
                    if (!that.test.settings) {
                        that.test.settings = {};
                    }
                    that.test.settings.hasTimeLimit = false;
                    that.test.settings.attachments = [];
                    that.test.settings.enableMichaelsenPointDistribution = that.test.type == 'michaelsenEvaluation';
                }
                if (that.test.peerQuestions.length != 0) {
                    for (var i = 0; i < that.test.peerQuestions[0].length; i++) {
                        that.test.peerQuestions[0][i].peers = [
                            {
                                response: null,
                                fullname: 'Student-1',
                                isMe: that.test.evaluationSettings.evaluateThemselves ? true : false,
                            },
                        ];
                        /*Vue.set(that.test.peerQuestions[0][i], 'peers', [
                            {
                                response: null,
                                fullname: 'Student-1',
                                isMe: that.test.evaluationSettings.evaluateThemselves ? true : false,
                            },
                        ]);*/

                        if (that.test.evaluationSettings.evaluateTeammates) {
                            that.test.peerQuestions[0][i].peers.push({
                                response: null,
                                fullname: 'Student-2',
                                isMe: false,
                            });
                            that.test.peerQuestions[0][i].peers.push({
                                response: null,
                                fullname: 'Student-3',
                                isMe: false,
                            });
                        }
                    }
                }
                if (that.test.teamQuestions.length != 0) {
                    for (var i = 0; i < that.test.teamQuestions[0].length; i++) {
                        that.test.teamQuestions[0][i].teams = [{ response: null, name: 'Team-1', isMe: true }];
                        /*Vue.set(that.test.teamQuestions[0][i], 'teams', [
                            { response: null, name: 'Team-1', isMe: true },
                        ]);*/
                    }
                }
                if (that.test.instructorQuestions.length != 0) {
                    for (var i = 0; i < that.test.instructorQuestions[0].length; i++) {
                        that.test.instructorQuestions[0][i].instructors = [
                            {
                                response: null,
                                fullname: 'Instructor-1',
                                isMe: false,
                            },
                            {
                                response: null,
                                fullname: 'Instructor-2',
                                isMe: false,
                            },
                            {
                                response: null,
                                fullname: 'Instructor-3',
                                isMe: false,
                            },
                        ];
                        /*Vue.set(
                            that.test.instructorQuestions[0][i],
                            'instructors',
                            [
                                {
                                    response: null,
                                    fullname: 'Instructor-1',
                                    isMe: false,
                                },
                                {
                                    response: null,
                                    fullname: 'Instructor-2',
                                    isMe: false,
                                },
                                {
                                    response: null,
                                    fullname: 'Instructor-3',
                                    isMe: false,
                                },
                            ]
                        );*/
                    }
                }
                if (that.test.settings.enableMichaelsenPointDistribution) {
                    that.test.michaelsenPoints = [
                        { point: '', fullname: 'Student-2', isMe: false },
                        { point: '', fullname: 'Student-3', isMe: false },
                    ];
                    /*Vue.set(that.test, 'michaelsenPoints', [
                        { point: '', fullname: 'Student-2', isMe: false },
                        { point: '', fullname: 'Student-3', isMe: false },
                    ]);*/
                }
                if (!_.isArray(that.test.michaelsenPoints)) {
                    that.test.michaelsenPoints = [];
                }
                that.component_done_loading = true;
            });
        }
    },
    created() {
        var that = this;
        $('body').addClass('test').removeClass('nav-sm').addClass('nav-none');
        Events.listen('submit-test', function (data) {
            $('#test_confirm_modal').modal('show');
        });
    },
    methods: {
        processEchoListener() {
            var that = this;

            let h1 = (e) => {
                if (e.test.uuid != that.test.testUuid) {
                    return false;
                }
                if (that.test.settings.type == 'synchronous') {
                    that.test.myProgression.startTime = e.test.settings.startTime;
                    that.test.myProgression.endTime = e.test.settings.endTime;
                } else {
                    that.test.myProgression.startTime = e.test.settings.startTime;
                    that.test.myProgression.endTime =
                        moment
                            .utc(that.test.myProgression.endTime, 'YYYY-MM-DDThh:mm:ssZ')
                            .add(e.test.settings.diffInSeconds, 'seconds')
                            .format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                    //asynchronous case here
                }
                let old_status = that.test.status;
                that.test.status = e.test.status;

                if (!['not started', 'scheduled'].includes(that.test.status)) {
                    if (that.test._isPreview) {
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'This test has started. Redirecting you back to test listings',
                        });
                        that.$router.push({ name: 'tests.index' });
                    }
                }
                if (that.test.status == 'completed') {
                    //submited the test
                    // that.$notify({
                    //   group: "form",
                    //   type: "success",
                    //   title: "Success",
                    //   text: "Time's up! Pass your papers!",
                    // });
                    // that.$router.push({ name: "tests.index" });

                    $('#timeUpModal').modal('show');

                    window.setTimeout(function () {
                        $('#timeUpModal').modal('hide');

                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: "Time's up! Pass your papers!",
                        });
                        that.$router.push({ name: 'tests.index' });
                    }, 3000);
                }
                if (that.test.status == 'ended') {
                    $('#teacherEndedModal').modal('show');

                    window.setTimeout(function () {
                        $('#teacherEndedModal').modal('hide');

                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'Teacher has ended the test',
                        });
                        that.$router.push({ name: 'tests.index' });
                    }, 3000);
                }
            };
            let c1 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('TestStatusUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestStatusUpdated',
                handler: h1,
            });

            let h2 = (e) => {
                if (e.testUuids.includes(that.test.testUuid)) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has unpublished this activity.',
                    });
                    that.$router.push({ name: 'tests.index' });
                }
            };
            let c2 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('ActivityUnpublished', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'ActivityUnpublished',
                handler: h2,
            });

            let h3 = (e) => {
                if (e.testUuids.includes(that.test.testUuid)) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has deleted this activity.',
                    });
                    that.$router.push({ name: 'tests.index' });
                }
            };
            let c3 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('ActivityDeleted', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'ActivityDeleted',
                handler: h3,
            });

            let h4 = (e) => {
                that.init();
            };
            let c4 = window.Echo.private(`placementtest.${this.test.uuid}`).listen('StudentResetAnswers', h4);
            this.echoChannels.push({
                channel: c4,
                event: 'StudentResetAnswers',
                handler: h4,
            });

            let h5 = (e) => {
                let old_status = that.test.myProgression.userTestStatus;
                let students = _.get(that.test, 'myProgression.team.students', []);
                that.test.myProgression = e.userPlacementTests.myProgression;
                /*Vue.set(
                    that.test,
                    'myProgression',
                    e.userPlacementTests.myProgression
                );*/
                if (that.test.myProgression.team) {
                    that.test.myProgression.team.students = students;
                }
                if (old_status != that.test.myProgression.userTestStatus && !that.test._isSubmitting) {
                    if (that.test.myProgression.userTestStatus == 'finished') {
                        $('#timeUpModal').modal('show');
                        window.setTimeout(function () {
                            $('#timeUpModal').modal('hide');
                            that.$notify({
                                group: 'form',
                                type: 'success',
                                title: 'Success',
                                text: "Time's up! Pass your papers!",
                            });
                            that.$router.push({ name: 'tests.index' });
                        }, 3000);
                    }
                    if (that.test.myProgression.userTestStatus == 'incomplete') {
                        $('#teacherEndedModal').modal('show');

                        window.setTimeout(function () {
                            $('#teacherEndedModal').modal('hide');
                            that.$notify({
                                group: 'form',
                                type: 'success',
                                title: 'Success',
                                text: 'Teacher has ended the test',
                            });
                            that.$router.push({ name: 'tests.index' });
                        }, 3000);
                    }
                }
            };
            let c5 = window.Echo.private(`placementtest.${this.test.uuid}`).listen('StudentTestStatusUpdated', h5);
            this.echoChannels.push({
                channel: c5,
                event: 'StudentTestStatusUpdated',
                handler: h5,
            });
            let h6 = (e) => {
                this.test.extraTimeValues = e.extraTimeValues;
                this.test.extraTimeValues.extraTimeValues = e.extraTimeValues;
                this.test.expectedEndingTime = e.expectedEndingTime;
                this.test.myProgression.userTestStatus = e.userPlacementTestStatus;
                /*Vue.set(this.test, 'extraTimeValues', e.extraTimeValues);
                Vue.set(
                    this.test.extraTimeValues,
                    'extraTimeValues',
                    e.extraTimeValues
                );
                Vue.set(this.test, 'expectedEndingTime', e.expectedEndingTime);
                Vue.set(
                    this.test.myProgression,
                    'userTestStatus',
                    e.userPlacementTestStatus
                );*/
            };
            let c6 = window.Echo.private(`placementtest.${this.test.uuid}`).listen('StudentTimeExtended', h6);
            this.echoChannels.push({
                channel: c6,
                event: 'StudentTimeExtended',
                handler: h6,
            });
        },
        init() {
            var that = this;
            this.fetchTest().then(function (response) {
                that.test = response.data.data;
                //tag test as preview
                if (that.$route.name == 'tests.preview.evaluation') {
                    that.test._isPreview = true;
                } else {
                    that.processEchoListener();
                }
                /*if(!that.isTestStarted&&!that.isTestCompleted){
						axios.put('student/tests/'+this.test.uuid+'/start').then(function(){
	                    	//processTest();
	                	})
					}*/
                if (!_.isArray(that.test.peerQuestions)) {
                    that.test.peerQuestions = [];
                }
                if (!_.isArray(that.test.teamQuestions)) {
                    that.test.teamQuestions = [];
                }
                if (!_.isArray(that.test.instructorQuestions)) {
                    that.test.instructorQuestions = [];
                }
                if (!_.isArray(that.test.michaelsenPoints)) {
                    that.test.michaelsenPoints = [];
                }

                that.component_done_loading = true;
            });
        },
        submitTest() {
            var that = this;
            axios
                .put('student/tests/' + that.test.uuid + '/submit')
                .then((response) => {
                    $('#redirectingModal').modal('show');
                    that.test._isSubmitting = true;
                    window.setTimeout(function () {
                        $('#redirectingModal').modal('hide');
                        that.$router.push({ name: 'tests.index' });
                    }, 3000);
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Please complete the evaluation before submitting',
                    });
                });
        },
        fetchTest() {
            return axios.get('student/tests/' + this.$route.params.id);
        },
        fetchActivity() {
            return axios.get('activities/' + this.$route.params.id);
        },
        processTimerShown(isShown) {
            this.is_timer_shown = isShown;
        },
        processDisplayTestProper(stopDisplay) {
            this.display_test_proper = !stopDisplay;
        },
    },
    components: {
        'test-topbar': require(`../rat/partials/topbar.vue`).default,
        'stopping-div': require(`../rat/partials/stopping-div.vue`).default,
        'question-groups': require(`./partials/questions/question-groups.vue`).default,
    },
    computed: {
        isTestStarted() {
            return _.get(this.test, 'myProgression.userTestStatus') == 'started';
        },
        //check if test is completed
        isTestCompleted() {
            if (this.auth.user().role != 'Student') {
                return null;
            }
            if (
                ['completed', 'ended'].includes(this.test.status) ||
                ['finished', 'incomplete'].includes(_.get(this.test, 'myProgression.userTestStatus')) ||
                ['submitted'].includes(_.get(this.test, 'myProgression.teamStatus'))
            ) {
                return true;
            }
            return false;
        },
    },
    beforeUnmount() {
        if ($(window).width() > 991) {
            $('body').removeClass('test').addClass('nav-sm').removeClass('nav-none');
        } else {
            $('body').removeClass('test').removeClass('nav-sm').addClass('nav-none');
        }
        Events.off('submit-test');
    },
};
</script>
<style scoped lang="scss">
#test_confirm_modal .modal-body .message {
    text-align: center;
}
#test_confirm_modal .modal-body .message .fa {
    font-size: 40px;
    color: #e69618;
}
.individual {
    padding: 91px 25px 95px !important;
}

@media (min-width: 992px) {
    .timer.individual {
        padding: 130px 25px 95px !important;
    }

    .test .evaluation.right-container {
        padding: 0 25px 78px !important;
    }
}

@media (max-width: 991px) {
    .timer.individual {
        padding: 103px 25px 95px !important;
    }

    .test .evaluation.right-container {
        padding: 0 25px 78px !important;
    }
}
</style>
